import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    displayOn : false,
    message: "adasdad",
    IsSuccessful: true 
}

const snackbar = createReducer(initialState, ((builder)=>{
    builder
        .addCase("snackbarError", (state, action) => {
            state.displayOn  = true
            state.message = action.message 
            state.IsSuccessful = false 
            return state 
        })
        .addCase("snackbarSuccess", (state , action) => {
            state.displayOn = true 
            state.message = action.message 
            state.IsSuccessful = true 
            return state 
        })
        .addCase("snackbarClose", (state) => {
            state.displayOn = false 
            state.message = ""
            state.IsSuccessful = false 
            return state 
        })
}))

export default snackbar