import React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import "./UploadVoiceModal.css";
import axiosInstance from "../Axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
};

export const UploadVoiceModal = ({
  ppt_id,
  slide_id,
  setUpdate,
  setSlide_id,
  setSelectedSlide,
}) => {
  const [audioFile, setAudioFile] = useState(null);

  const [audioUploadLoading, setAudioUploadLoading] = useState(false);
  const [openUploadAudioModal, setOpenUploadAudioModal] = useState(false);
  const handleOpenUploadAudioModal = () => setOpenUploadAudioModal(true);
  const handleCloseUploadAudioModal = () => {
    setAudioFile(null);
    setOpenUploadAudioModal(false);
  };

  const handleAudioFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAudioFile(file);
    }
  };

  const handleUploadAudio = async () => {
    let formData = new FormData();
    formData.append("ppt_id", ppt_id);
    formData.append("slide_id", slide_id);
    formData.append("audio", audioFile);

    try {
      setAudioUploadLoading(true);
      const res = await axiosInstance.post(
        "/api/ppt/add_audio",
        formData,
        "multipart/form-data"
      );
      if (res.status === 200) {
        alert("Audio Uploaded Successfully");
      }
      // console.log(res.data.audio_url);
      setSlide_id(slide_id);
      setUpdate((prev) => !prev);
    } catch (err) {
      alert(err.message);
    } finally {
      setAudioUploadLoading(false);
      handleCloseUploadAudioModal();
    }
  };

  return (
    <>
      <button onClick={handleOpenUploadAudioModal} className="audio-option-btn">
        Upload Voice
      </button>
      <Modal
        open={openUploadAudioModal}
        onClose={handleCloseUploadAudioModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center", marginBottom: "15px" }}
            sx={{ fontSize: "16px" }}
          >
            Upload Voice
          </Typography>
          <div>
            <input
              type="file"
              accept="audio/*"
              onChange={handleAudioFileChange}
            />
            {audioFile && (
              <div style={{ marginTop: "20px" }}>
                <ReactAudioPlayer
                  src={URL.createObjectURL(audioFile)}
                  controls
                />
              </div>
            )}
          </div>
          {audioUploadLoading ? (
            <button
              disabled
              className="ppt-preview-upload-audio-btn"
              style={{
                marginTop: "10px",
                background: "gray",
                cursor: "not-allowed",
                color: "inherit",
              }}
            >
              Uploading...
            </button>
          ) : audioFile ? (
            <button
              className="ppt-preview-upload-audio-btn"
              style={{ marginTop: "10px", color: "white" }}
              onClick={handleUploadAudio}
            >
              Upload Audio
            </button>
          ) : (
            <button
              disabled
              className="ppt-preview-upload-audio-btn"
              style={{
                marginTop: "10px",
                background: "gray",
                cursor: "not-allowed",
                color: "inherit",
              }}
            >
              Upload Audio
            </button>
          )}
        </Box>
      </Modal>
    </>
  );
};
