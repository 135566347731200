import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import axiosInstance from "../../Axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

export const CourseOptionsButton = ({
  item,
  catalogueData,
  langDict,
  catalogue,
  courseData,
  setCourseData,
  setReload,
}) => {
  // delete course modal
  const [openDeleteCourseModal, setDeleteCourseModal] = useState(false);
  const handleOpenDeleteCourseModal = () => setDeleteCourseModal(true);
  const handleCloseDeleteCourseModal = () => setDeleteCourseModal(false);

  const [resMessageEditCourse, setResMessageEditCourse] = useState("");
  const [showResMessageEditCourse, setShowResMessageEditCourse] =
    useState(false);

  const [openEditCourseModal, setEditCourseModal] = useState(false);
  const handleOpenEditCourseModal = () => setEditCourseModal(true);
  const handleCloseEditCourseModal = () => setEditCourseModal(false);

  const [course, setCourse] = useState({
    course_id: null,
    course_name: "",
    course_catalogue: null,
  });

  const [openEditCourse, setOpenEditCourse] = useState(null);
  const handleOpenEdit = Boolean(openEditCourse);

  const handleClickEditCourse = (event) => {
    setOpenEditCourse(event.currentTarget);
  };
  const handleCloseEditCourse = () => {
    setOpenEditCourse(null);
  };

  const courseEdit = (e) => {
    e.stopPropagation();
    handleClickEditCourse(e);
  };

  const handleEditCourse = (course_id, course_name) => {
    handleOpenEditCourseModal();
  };

  const handleDeleteCourse = async (course_id) => {
    const newCourseData = courseData.filter(
      (item) => item.course_id !== course_id
    );
    setCourseData(newCourseData);
    let formData = new FormData();
    formData.append("course_id", course_id);
    try {
      const res = await axiosInstance.post(
        "api/course/delete_course",
        formData
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  const course_nameHandler = (e) => {
    setShowResMessageEditCourse(false);
    setCourse((prevState) => {
      return {
        ...prevState,
        course_name: e.target.value,
      };
    });
  };

  const handleChangeCourseCatalogue = (e) => {
    setShowResMessageEditCourse(false);
    setCourse((prevState) => {
      return {
        ...prevState,
        course_catalogue: e.target.value,
      };
    });
  };

  const handleSubmitEditCourse = async (e) => {
    e.preventDefault();
    if (
      course.course_name === "" ||
      course.course_catalogue === null ||
      course.course_id === null ||
      course.course_name.trim() === ""
    ) {
      setResMessageEditCourse("Please fill all the fields");
      setShowResMessageEditCourse(true);
      return;
    }

    let formData = new FormData();
    formData.append("course_id", course.course_id);
    formData.append("course_name", course.course_name);
    formData.append("catalogue_id", course.course_catalogue);

    try {
      const res = await axiosInstance.post(
        "api/course/update_course",
        formData
      );
      console.log("Edit Course Res: ", res);
      setResMessageEditCourse("Course Edited Successfully");
      setShowResMessageEditCourse(true);

      setTimeout(() => {
        handleCloseEditCourseModal();
        setResMessageEditCourse("");
        setShowResMessageEditCourse(false);
        setCourse({
          course_id: null,
          course_name: "",
          course_catalogue: null,
        });
      }, 2000);

      setReload((prevState) => !prevState);
    } catch (err) {
      setResMessageEditCourse("Something went wrong");
      setShowResMessageEditCourse(true);
      console.log(err.message);
    }
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={openEditCourse ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={handleOpenEdit ? "true" : undefined}
        onClick={(e) => {
          setCourse({
            course_id: item["course_id"],
            course_name: item["course_name"],
            course_catalogue: catalogue,
          });
          courseEdit(e);
        }}
        className="comment-options"
      >
        <MoreVertIcon />
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={openEditCourse}
        open={handleOpenEdit}
        onClose={(e) => {
          e.stopPropagation();
          handleCloseEditCourse();
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleEditCourse(course.course_id, course.course_name);
            handleCloseEditCourse();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDeleteCourseModal();
            handleCloseEditCourse();
          }}
        >
          Delete
        </MenuItem>
        {/* <MenuItem onClick={(e)=> {e.stopPropagation(); handleDeleteCourse(course.course_id); handleCloseEditCourse();}}>Delete</MenuItem> */}
      </Menu>

      {/* Delete user modal */}
      <Modal
        open={openDeleteCourseModal}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleCloseDeleteCourseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ fontSize: "16px" }}
            component="h2"
          >
            Are you sure you want to delete this Course?
          </Typography>
          <div
            className="delete-modal-btn-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="delete-ppt-modal-btn-delete"
              sx={{ fontWeight: "bold" }}
              onClick={() => {
                handleDeleteCourse(course.course_id);
                handleCloseDeleteCourseModal();
              }}
              color="error"
            >
              Delete
            </Button>
            <Button
              className="delete-ppt-modal-btn-cancel"
              sx={{ fontWeight: "bold" }}
              onClick={handleCloseDeleteCourseModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Edit user modal */}
      <Modal
        open={openEditCourseModal}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleCloseEditCourseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LibraryBooksOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Edit Course
                </Typography>

                {resMessageEditCourse === "Course Edited Successfully" ? (
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: showResMessageEditCourse ? "block" : "none",
                      color: "green",
                    }}
                  >
                    {resMessageEditCourse}
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      display: showResMessageEditCourse ? "block" : "none",
                      color: "red",
                    }}
                  >
                    {resMessageEditCourse}
                  </Typography>
                )}

                <Box
                  component="form"
                  onSubmit={handleSubmitEditCourse}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="course_name"
                    label="Course Name"
                    name="course_name"
                    autoFocus
                    onChange={course_nameHandler}
                    value={course.course_name}
                  />

                  {/* <CourseLangDropdown languageHandler={languageHandler}/> */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Catalogue
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course.course_catalogue}
                      label="Catalogue"
                      onChange={handleChangeCourseCatalogue}
                    >
                      {catalogueData.map((i) => (
                        <MenuItem key={i.catalogue_id} value={i.catalogue_id}>
                          {i.catalogue_name} {langDict[i.language]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Edit Course
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
};
