import { configureStore } from "@reduxjs/toolkit";
import videoSearchReducer from "./Reducers/videoSearchReducer";
import primeModalReducer from "./Reducers/PrimeModal";
import dragNdropFileReducer from "./Reducers/DragNDropFileReducer";
import snackbar from "./Reducers/Snackbar";

const store = configureStore( {
reducer: {
    videoSearchReducer: videoSearchReducer,
    dragNdropFileReducer: dragNdropFileReducer,
    primeModalReducer: primeModalReducer,
    snackbarModal : snackbar
}
})
export default store;