import React from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import "./ReferencePPT.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import ReactPlayer from "react-player";
import axiosInstance from "../../Axios";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "10px 20px 30px 40px",
  overflowY: "auto",
};

export const ReferencePPT = ({ slide_ref }) => {
  const [openRefPPTModal, setOpenRefPPTModal] = useState(false);
  const handleOpenRefPPTModal = () => setOpenRefPPTModal(true);
  const handleCloseRefPPTModal = () => setOpenRefPPTModal(false);
  const [slide, setSlide] = useState({});

  const getRefSlide = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/ppt/get_ppt_slide?slide_id=${slide_ref}`
      );
      setSlide(res.data.data[0]);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getRefSlide();
  }, [slide_ref]);

  return (
    <>
      <button onClick={handleOpenRefPPTModal} className="ref-ppt-btn">
        <TextSnippetIcon className="ref-ppt-icon" />

        <p className="play-btn-text">Reference Slide</p>
      </button>

      <Modal
        open={openRefPPTModal}
        onClose={handleCloseRefPPTModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="slide-preview-container">
            {/* Slide Preview */}

            <>
              {slide ? (
                <div style={{ position: "relative" }} className="" id="">
                  {slide.slide_type === "IMAGE" ? (
                    <img
                      src={slide.slide_image_uri}
                      alt="preview-slide"
                      className="preview-img"
                      id=""
                    />
                  ) : (
                    <>
                      <ReactPlayer
                        url={slide.slide_image_uri}
                        className="preview-img"
                        playing={true}
                        loop={true}
                        width="500px"
                        height="200px"
                        muted={true}
                        style={{ position: "relative", zIndex: -1 }}
                      ></ReactPlayer>
                    </>
                  )}

                  <div className="ref-slide-notes">
                    {slide.slide_notes === "" ? (
                      <p>No slide notes available</p>
                    ) : (
                      <p>{slide.slide_notes}</p>
                    )}
                  </div>
                </div>
              ) : (
                <Typography
                  id="modal-modal-title"
                  sx={{ fontSize: "16px" }}
                  component="h2"
                >
                  No refence available
                </Typography>
              )}
            </>
          </div>
        </Box>
      </Modal>
    </>
  );
};
