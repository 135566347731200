import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import axiosInstance from "../Axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const DeleteSlideModal = ({
  slide,
  ppt_id,
  PPTSlides,
  setSlide_id,
  setUpdate,
}) => {
  // slide to be deleted
  const [slideToBeDeleted, setSlideToBeDeleted] = useState();

  // remove slide modal
  const [openRemoveSlideModal, setOpenRemoveSlideModal] = useState(false);
  const handleOpenRemoveSlideModal = () => setOpenRemoveSlideModal(true);
  const handleCloseRemoveSlideModal = () => setOpenRemoveSlideModal(false);

  const handleRemoveSlide = async (slide) => {
    try {
      let formData = new FormData();
      formData.append("ppt_id", ppt_id);
      formData.append("slide_id", slide.slide_id);
      const res = await axiosInstance.post("/api/ppt/delete_slide", formData);
      const slideIndex = PPTSlides.findIndex(
        (s) => s.slide_id === slide.slide_id
      );
      const newSlide = PPTSlides[slideIndex - 1];
      setUpdate((prev) => !prev);
      setSlide_id(newSlide.slide_id);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <div
        className="delete-slide-container"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "white",
          padding: "0",
          color: "red",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenRemoveSlideModal(); // This prevents the click event from triggering the parent div's click event
          setSlideToBeDeleted(slide); // Call your remove slide function here
        }}
      >
        <CloseIcon />
      </div>
      <Modal
        open={openRemoveSlideModal}
        onClose={handleCloseRemoveSlideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ fontSize: "16px" }}
            component="h2"
          >
            Are you sure you want to delete this Slide?
          </Typography>
          <div
            className="delete-modal-btn-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="delete-ppt-modal-btn-delete"
              sx={{ fontWeight: "bold" }}
              onClick={() => {
                handleRemoveSlide(slideToBeDeleted);
                handleCloseRemoveSlideModal();
              }}
              color="error"
            >
              Delete
            </Button>
            <Button
              className="delete-ppt-modal-btn-cancel"
              sx={{ fontWeight: "bold" }}
              onClick={handleCloseRemoveSlideModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
