import React, { useEffect, useState } from 'react'
import "./CreateCatalogueModal.css"
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import axiosInstance from '../Axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"10px"
};

const lang = {
    "English": "en",
    "Hindi": "hi",
    "Marathi": "mr",
    "Gujarati": "gu",
    "Bengali": "bn",
    "Tamil": "ta",
    "Telugu": "te",
    "Kannada": "kn",
    "Odia": "or",
};

export const CreateCatalogueModal = ({setReload}) => {

    const [catalogue, setCatalogue] = useState({
        catalogue_name: "",
        language: ""
    })

    const [allLanguges, setAllLanguages] = useState([]);
    const [resMessage, setResMessage] = useState("");
    const [showResMessage, setShowResMessage] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getAllLanguages = async () => {

        try {
            const res = await axiosInstance.get("/api/language/get_all_languages");
            setAllLanguages(res.data.data);
        } catch (err) {
            console.error(err.message);
        }

    }

    const catalogue_nameHandler = (event) => {
        setShowResMessage(false);
        setCatalogue((prevState)=>{
            return {
                ...prevState,
                catalogue_name: event.target.value
            }
        })
    }

    const handleLanguageChange = (event) => {
        setShowResMessage(false);
        setCatalogue((prevState)=>{
            return {
                ...prevState,
                language: event.target.value
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(catalogue.catalogue_name === "" || catalogue.language === "" || catalogue.language.trim() === "" || catalogue.catalogue_name.trim() === "") {
            setResMessage("Please fill all the fields");
            setShowResMessage(true);
            return;
        }
        await setCatalogueData(catalogue);
        setReload(true);
        setCatalogue({
            catalogue_name: "",
            language: ""
        });

        setTimeout(() => {
            handleClose();
            setShowResMessage(false);
        }, 2000);
    }

    useEffect(()=> {
        getAllLanguages();
    }, [])

    const setCatalogueData = async (catalogue) => {

        try {
            
            let data = new FormData();
            data.append("catalogue_name", catalogue.catalogue_name);
            data.append("language", catalogue.language);

            const res = await axiosInstance.post("/api/catalogue/create_catalogue", data);
            setResMessage("Catalogue Added Successfully");
            setShowResMessage(true);

        } catch (err) {
            setResMessage(err.response.data.message);
            setShowResMessage(true);
            console.error(err.message);
        }

    }

  return (
    <div>
        <button 
            onClick={handleOpen}
            style={{
                display: "flex",
                padding: "13px 28px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                border: "none",
                borderRadius: "5px",
                color: "white",
                background: "var(--primary-01, #1167E5)",
                height: "55px",
            }}
        >
            Add Catalogue
        </button>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography component="h1" variant="h5">
                    Add Catalogue
                </Typography>

                {
                    resMessage === "Catalogue Added Successfully" ? (
                        <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'green' }}>
                            {resMessage}
                        </Typography>
                    ) : (
                        <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'red' }}>
                            {resMessage}
                        </Typography>
                    )
                }


                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="catalogue_name"
                        label="Catalogue Name"
                        name="catalogue_name"
                        autoFocus
                        onChange={catalogue_nameHandler}
                        value={catalogue.catalogue_name}
                    />
                    
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={catalogue.language ? catalogue.language : ""}
                        label="Catalogue"
                        onChange={handleLanguageChange}
                        >
                            {allLanguges.map((item)=>(
                                <MenuItem key={item.language_id} value={item.language_code}>{item.language}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Add Catalogue
                    </Button>
                </Box>
            </Box>
        </Modal>
    </div>
  )
}
