import React, { useEffect, useState } from "react";
import { VideoSeekerAnima } from "../VideoSeekerAnima/VideoSeekerAnima";
import axiosInstance from "../Axios";
import { VideoSkeleton } from "../VideoSkeleton/VideoSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const VideoPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [pptData, setPptData] = useState({});

  const [pptSlides, setPptSlides] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState(null);

  const [render, setRender] = useState(false);

  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const [allEngines, setAllEngines] = useState([]);
  const [openSpeakerModal, setOpenSpeakerModal] = React.useState(false);
  const handleOpenSpeakerModal = () => setOpenSpeakerModal(true);
  const handleCloseSpeakerModal = () => setOpenSpeakerModal(false);

  const [pptTitle, setPptTitle] = useState("");
  const [slideCounter, setSlideCounter] = useState(0);

  const [disableGenAudio, setDisableGenAudio] = useState(false);
  const [disableUpdateNotes, setDisableUpdateNotes] = useState(false);

  const [slideNote, setSlideNote] = useState({
    ppt_id: null,
    slide_id: null,
    notes: "",
  });

  const [loading, setLoading] = useState(true);

  // backdrop

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const getPptSlides = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/ppt/get_details_by_id?ppt_id=${id}`
      );
      setPptData(res.data.data.ppt_data);
      setPptSlides(res.data.data.slide_details);
      setPptTitle(res.data.data.ppt_data.ppt_name);
      setSelectedSpeaker(res.data.data.ppt_data.speaker);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const textAreaHandler = (e) => {
    const { value } = e.target;
    setSelectedSlide({ ...selectedSlide, slide_notes: value });
    setSlideNote((prevState) => {
      return {
        ppt_id: id,
        slide_id: selectedSlide.slide_id,
        notes: value,
      };
    });
  };

  const getAllEngines = async () => {
    try {
      const res = await axiosInstance.get("/api/miscellaneous/get_all_engines");
      setAllEngines(res.data.engines);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSpeakerChange = (event) => {
    setSelectedSpeaker(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    getPptSlides();
    getAllEngines();
  }, []);

  useEffect(() => {
    if (pptSlides.length > 0) {
      setSelectedSlide(pptSlides[0]);
      setSlideCounter(1);
    }
  }, [pptSlides]);

  const handleSlideClick = (slide) => {
    setSelectedSlide(slide);
    const slideIndex = pptSlides.findIndex((s) => s === slide);
    setSlideCounter(slideIndex + 1);
  };

  const handleSlideChange = (direction) => {
    const currentIndex = pptSlides.findIndex(
      (slide) => slide === selectedSlide
    );
    let newIndex;

    if (direction === "left") {
      newIndex = currentIndex > 0 ? currentIndex - 1 : pptSlides.length - 1;
    } else if (direction === "right") {
      newIndex = currentIndex < pptSlides.length - 1 ? currentIndex + 1 : 0;
    }

    setSelectedSlide(pptSlides[newIndex]);
    setSlideCounter(newIndex + 1);
  };

  const handleNotesUpdate = async () => {
    try {
      const res = await axiosInstance.post(
        "/api/ppt/update_notes",
        {
          ppt_id: id,
          slide_id: selectedSlide.slide_id,
          notes: selectedSlide.slide_notes,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setDisableUpdateNotes(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const genAudio = async () => {
    try {
      const res = await axiosInstance.post(
        "/api/tts",
        {
          text: selectedSlide.slide_notes,
          ppt_id: id,
          slide_id: selectedSlide.slide_id,
          stability: null,
          lang_code: pptData.ppt_lang,
          voice: selectedSpeaker,
          engine: pptData.engine,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setDisableGenAudio(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const renderVideo = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/queue/generate_video?ppt_id=${id}`
      );
      handleOpenBackdrop();
      setRender(true);
      console.log("render res", res);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getPptSlides();

    if (disableGenAudio) {
      setDisableGenAudio(false);
    }

    if (disableUpdateNotes) {
      setDisableUpdateNotes(false);
    }
  }, [disableGenAudio, disableUpdateNotes]);

  return (
    <>
      {loading ? (
        <VideoSkeleton />
      ) : (
        <div className="main-container">
          <div className="left-panel-container">
            <div className="left-panel-navigation">
              <div
                onClick={() => navigate("/home")}
                style={{ cursor: "pointer" }}
                className="icon-container"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_159_481"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_159_481)">
                    <path
                      d="M9.07499 21.0734L0.749988 12.7484C0.633321 12.6318 0.549988 12.5109 0.499988 12.3859C0.449988 12.2609 0.424988 12.1318 0.424988 11.9984C0.424988 11.8651 0.449988 11.7359 0.499988 11.6109C0.549988 11.4859 0.633321 11.3651 0.749988 11.2484L9.09999 2.89844C9.34999 2.64844 9.65415 2.52344 10.0125 2.52344C10.3708 2.52344 10.675 2.65677 10.925 2.92344C11.1917 3.17344 11.3167 3.48177 11.3 3.84844C11.2833 4.2151 11.15 4.52344 10.9 4.77344L3.67499 11.9984L10.925 19.2484C11.1583 19.4818 11.275 19.7859 11.275 20.1609C11.275 20.5359 11.1583 20.8401 10.925 21.0734C10.675 21.3234 10.3667 21.4484 9.99999 21.4484C9.63332 21.4484 9.32499 21.3234 9.07499 21.0734Z"
                      fill="#212121"
                    />
                  </g>
                </svg>
              </div>

              <div className="left-panel-text-container">
                <p className="navigation-text">{pptTitle}</p>
              </div>
            </div>

            <div className="slide-preview-container-wrapper">
              <div className="slide-preview-container">
                {pptSlides.map((slide, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={`slide-preview ${
                      slide === selectedSlide ? "slide-preview-selected" : ""
                    }`}
                    onClick={() => handleSlideClick(slide)}
                    id={slide === selectedSlide ? "selected" : ""}
                  >
                    <img
                      src={slide.slide_image_uri}
                      alt="preview-slide"
                      className="preview-img"
                      id=""
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="right-panel-container">
            <div className="right-panel-control-button">
              <div className="control-buttons-container">
                {render ? (
                  <button
                    disabled
                    style={{ backgroundColor: "gray" }}
                    className="edit-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <mask
                        id="mask0_156_401"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="21"
                      >
                        <rect
                          y="0.224609"
                          width="20"
                          height="20"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_156_401)">
                        <path
                          d="M16.0833 7.66146L12.5417 4.16146L13.7083 2.99479C14.0278 2.67535 14.4201 2.51562 14.8854 2.51562C15.3507 2.51562 15.7431 2.67535 16.0625 2.99479L17.2292 4.16146C17.5486 4.4809 17.7153 4.86632 17.7292 5.31771C17.7431 5.7691 17.5903 6.15451 17.2708 6.47396L16.0833 7.66146ZM14.875 8.89062L6.04167 17.724H2.5V14.1823L11.3333 5.34896L14.875 8.89062Z"
                          fill="white"
                        />
                      </g>
                    </svg>

                    <p className="edit-btn-text">Edit Slide</p>
                  </button>
                ) : (
                  <button className="edit-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <mask
                        id="mask0_156_401"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="21"
                      >
                        <rect
                          y="0.224609"
                          width="20"
                          height="20"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_156_401)">
                        <path
                          d="M16.0833 7.66146L12.5417 4.16146L13.7083 2.99479C14.0278 2.67535 14.4201 2.51562 14.8854 2.51562C15.3507 2.51562 15.7431 2.67535 16.0625 2.99479L17.2292 4.16146C17.5486 4.4809 17.7153 4.86632 17.7292 5.31771C17.7431 5.7691 17.5903 6.15451 17.2708 6.47396L16.0833 7.66146ZM14.875 8.89062L6.04167 17.724H2.5V14.1823L11.3333 5.34896L14.875 8.89062Z"
                          fill="white"
                        />
                      </g>
                    </svg>

                    <p className="edit-btn-text">Edit Slide</p>
                  </button>
                )}

                <div className="right-btn">
                  {render ? (
                    <button
                      disabled
                      style={{ backgroundColor: "gray" }}
                      onClick={renderVideo}
                      className="render-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <mask
                          id="mask0_159_507"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="21"
                          height="20"
                        >
                          <rect
                            x="0.290283"
                            width="20"
                            height="20"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_159_507)">
                          <path
                            d="M10.2903 13.3333C10.0542 13.3333 9.85624 13.2535 9.69651 13.0938C9.53679 12.934 9.45693 12.7361 9.45693 12.5V4.02083L8.70693 4.77083C8.55415 4.92361 8.36318 5 8.13401 5C7.90485 5 7.70693 4.91667 7.54026 4.75C7.38749 4.58333 7.3111 4.38542 7.3111 4.15625C7.3111 3.92708 7.38749 3.73611 7.54026 3.58333L9.70693 1.41667C9.77637 1.34722 9.86318 1.29167 9.96735 1.25C10.0715 1.20833 10.1792 1.1875 10.2903 1.1875C10.4014 1.1875 10.509 1.20833 10.6132 1.25C10.7173 1.29167 10.8042 1.34722 10.8736 1.41667L13.0403 3.58333C13.2069 3.75 13.2903 3.95139 13.2903 4.1875C13.2903 4.42361 13.2069 4.61806 13.0403 4.77083C12.8736 4.92361 12.6757 5 12.4465 5C12.2173 5 12.0264 4.92361 11.8736 4.77083L11.1236 4.02083V12.5C11.1236 12.7361 11.0437 12.934 10.884 13.0938C10.7243 13.2535 10.5264 13.3333 10.2903 13.3333ZM5.29026 19.1667C4.83193 19.1667 4.43957 19.0035 4.11318 18.6771C3.78679 18.3507 3.6236 17.9583 3.6236 17.5V8.33333C3.6236 7.875 3.78679 7.48264 4.11318 7.15625C4.43957 6.82986 4.83193 6.66667 5.29026 6.66667H6.95693C7.19304 6.66667 7.39096 6.74653 7.55068 6.90625C7.7104 7.06597 7.79026 7.26389 7.79026 7.5C7.79026 7.73611 7.7104 7.93403 7.55068 8.09375C7.39096 8.25347 7.19304 8.33333 6.95693 8.33333H5.29026V17.5H15.2903V8.33333H13.6236C13.3875 8.33333 13.1896 8.25347 13.0298 8.09375C12.8701 7.93403 12.7903 7.73611 12.7903 7.5C12.7903 7.26389 12.8701 7.06597 13.0298 6.90625C13.1896 6.74653 13.3875 6.66667 13.6236 6.66667H15.2903C15.7486 6.66667 16.141 6.82986 16.4673 7.15625C16.7937 7.48264 16.9569 7.875 16.9569 8.33333V17.5C16.9569 17.9583 16.7937 18.3507 16.4673 18.6771C16.141 19.0035 15.7486 19.1667 15.2903 19.1667H5.29026Z"
                            fill="white"
                          />
                        </g>
                      </svg>

                      <p className="render-btn-text">Render</p>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <mask
                          id="mask0_159_491"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="21"
                          height="20"
                        >
                          <rect
                            x="0.290283"
                            width="20"
                            height="20"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_159_491)">
                          <path
                            d="M9.70695 11.9173L7.54028 9.75065C7.27639 9.48676 7.21737 9.18468 7.3632 8.8444C7.50903 8.50412 7.76945 8.33398 8.14445 8.33398H12.4361C12.8111 8.33398 13.0715 8.50412 13.2174 8.8444C13.3632 9.18468 13.3042 9.48676 13.0403 9.75065L10.8736 11.9173C10.7903 12.0007 10.7 12.0632 10.6028 12.1048C10.5056 12.1465 10.4014 12.1673 10.2903 12.1673C10.1792 12.1673 10.075 12.1465 9.97778 12.1048C9.88056 12.0632 9.79028 12.0007 9.70695 11.9173Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                  ) : (
                    <button onClick={renderVideo} className="render-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <mask
                          id="mask0_159_507"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="21"
                          height="20"
                        >
                          <rect
                            x="0.290283"
                            width="20"
                            height="20"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_159_507)">
                          <path
                            d="M10.2903 13.3333C10.0542 13.3333 9.85624 13.2535 9.69651 13.0938C9.53679 12.934 9.45693 12.7361 9.45693 12.5V4.02083L8.70693 4.77083C8.55415 4.92361 8.36318 5 8.13401 5C7.90485 5 7.70693 4.91667 7.54026 4.75C7.38749 4.58333 7.3111 4.38542 7.3111 4.15625C7.3111 3.92708 7.38749 3.73611 7.54026 3.58333L9.70693 1.41667C9.77637 1.34722 9.86318 1.29167 9.96735 1.25C10.0715 1.20833 10.1792 1.1875 10.2903 1.1875C10.4014 1.1875 10.509 1.20833 10.6132 1.25C10.7173 1.29167 10.8042 1.34722 10.8736 1.41667L13.0403 3.58333C13.2069 3.75 13.2903 3.95139 13.2903 4.1875C13.2903 4.42361 13.2069 4.61806 13.0403 4.77083C12.8736 4.92361 12.6757 5 12.4465 5C12.2173 5 12.0264 4.92361 11.8736 4.77083L11.1236 4.02083V12.5C11.1236 12.7361 11.0437 12.934 10.884 13.0938C10.7243 13.2535 10.5264 13.3333 10.2903 13.3333ZM5.29026 19.1667C4.83193 19.1667 4.43957 19.0035 4.11318 18.6771C3.78679 18.3507 3.6236 17.9583 3.6236 17.5V8.33333C3.6236 7.875 3.78679 7.48264 4.11318 7.15625C4.43957 6.82986 4.83193 6.66667 5.29026 6.66667H6.95693C7.19304 6.66667 7.39096 6.74653 7.55068 6.90625C7.7104 7.06597 7.79026 7.26389 7.79026 7.5C7.79026 7.73611 7.7104 7.93403 7.55068 8.09375C7.39096 8.25347 7.19304 8.33333 6.95693 8.33333H5.29026V17.5H15.2903V8.33333H13.6236C13.3875 8.33333 13.1896 8.25347 13.0298 8.09375C12.8701 7.93403 12.7903 7.73611 12.7903 7.5C12.7903 7.26389 12.8701 7.06597 13.0298 6.90625C13.1896 6.74653 13.3875 6.66667 13.6236 6.66667H15.2903C15.7486 6.66667 16.141 6.82986 16.4673 7.15625C16.7937 7.48264 16.9569 7.875 16.9569 8.33333V17.5C16.9569 17.9583 16.7937 18.3507 16.4673 18.6771C16.141 19.0035 15.7486 19.1667 15.2903 19.1667H5.29026Z"
                            fill="white"
                          />
                        </g>
                      </svg>

                      <p className="render-btn-text">Render</p>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <mask
                          id="mask0_159_491"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="21"
                          height="20"
                        >
                          <rect
                            x="0.290283"
                            width="20"
                            height="20"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_159_491)">
                          <path
                            d="M9.70695 11.9173L7.54028 9.75065C7.27639 9.48676 7.21737 9.18468 7.3632 8.8444C7.50903 8.50412 7.76945 8.33398 8.14445 8.33398H12.4361C12.8111 8.33398 13.0715 8.50412 13.2174 8.8444C13.3632 9.18468 13.3042 9.48676 13.0403 9.75065L10.8736 11.9173C10.7903 12.0007 10.7 12.0632 10.6028 12.1048C10.5056 12.1465 10.4014 12.1673 10.2903 12.1673C10.1792 12.1673 10.075 12.1465 9.97778 12.1048C9.88056 12.0632 9.79028 12.0007 9.70695 11.9173Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="video-preview-container">
              <div className="video-preview">
                {selectedSlide && (
                  <img
                    src={selectedSlide.slide_image_uri}
                    alt="video-preview"
                    className="video"
                  />
                )}
              </div>

              {selectedSlide && (
                <VideoSeekerAnima song={selectedSlide.slide_audio_uri} />
              )}
            </div>

            <div className="video-note-container">
              <div className="video-note">
                <div className="video-note-text">
                  {selectedSlide && (
                    <textarea
                      rows="7"
                      cols="92"
                      className="note-textarea"
                      placeholder="Slide Notes..."
                      value={selectedSlide.slide_notes}
                      onChange={textAreaHandler}
                    ></textarea>
                  )}
                </div>
              </div>
            </div>

            <div className="note-arrow-container">
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
                className="btn-container"
              >
                {render || disableUpdateNotes ? (
                  <button
                    disabled
                    style={{ backgroundColor: "gray" }}
                    onClick={handleNotesUpdate}
                    className="note-update-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <mask
                        id="mask0_156_401"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="21"
                      >
                        <rect
                          y="0.224609"
                          width="20"
                          height="20"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_156_401)">
                        <path
                          d="M16.0833 7.66146L12.5417 4.16146L13.7083 2.99479C14.0278 2.67535 14.4201 2.51562 14.8854 2.51562C15.3507 2.51562 15.7431 2.67535 16.0625 2.99479L17.2292 4.16146C17.5486 4.4809 17.7153 4.86632 17.7292 5.31771C17.7431 5.7691 17.5903 6.15451 17.2708 6.47396L16.0833 7.66146ZM14.875 8.89062L6.04167 17.724H2.5V14.1823L11.3333 5.34896L14.875 8.89062Z"
                          fill="white"
                        />
                      </g>
                    </svg>

                    <p className="note-btn-text">Update Note</p>
                  </button>
                ) : (
                  <button
                    onClick={handleNotesUpdate}
                    className="note-update-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <mask
                        id="mask0_156_401"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="21"
                      >
                        <rect
                          y="0.224609"
                          width="20"
                          height="20"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_156_401)">
                        <path
                          d="M16.0833 7.66146L12.5417 4.16146L13.7083 2.99479C14.0278 2.67535 14.4201 2.51562 14.8854 2.51562C15.3507 2.51562 15.7431 2.67535 16.0625 2.99479L17.2292 4.16146C17.5486 4.4809 17.7153 4.86632 17.7292 5.31771C17.7431 5.7691 17.5903 6.15451 17.2708 6.47396L16.0833 7.66146ZM14.875 8.89062L6.04167 17.724H2.5V14.1823L11.3333 5.34896L14.875 8.89062Z"
                          fill="white"
                        />
                      </g>
                    </svg>

                    <p className="note-btn-text">Update Note</p>
                  </button>
                )}

                {render || disableGenAudio ? (
                  <button
                    disabled
                    style={{ backgroundColor: "gray" }}
                    onClick={genAudio}
                    className="play-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1.87762C0 0.451621 1.529 -0.452379 2.779 0.234621L14.319 6.58262C15.614 7.29462 15.614 9.15562 14.319 9.86762L2.78 16.2156C1.53 16.9026 0.000999928 15.9986 0.000999928 14.5726L0 1.87762Z"
                        fill="#1167E5"
                      />
                    </svg>

                    <p className="play-btn-text">Generate Audio</p>
                  </button>
                ) : (
                  <button onClick={genAudio} className="play-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1.87762C0 0.451621 1.529 -0.452379 2.779 0.234621L14.319 6.58262C15.614 7.29462 15.614 9.15562 14.319 9.86762L2.78 16.2156C1.53 16.9026 0.000999928 15.9986 0.000999928 14.5726L0 1.87762Z"
                        fill="#1167E5"
                      />
                    </svg>

                    <p className="play-btn-text">Generate Audio</p>
                  </button>
                )}

                <div className="speaker-modal">
                  {render ? (
                    <button
                      disabled
                      style={{ backgroundColor: "gray" }}
                      onClick={handleOpenSpeakerModal}
                      className="speaker-select-button"
                    >
                      <RecordVoiceOverIcon /> {selectedSpeaker}
                    </button>
                  ) : (
                    <button
                      onClick={handleOpenSpeakerModal}
                      className="speaker-select-button"
                    >
                      <RecordVoiceOverIcon /> {selectedSpeaker}
                    </button>
                  )}

                  <Modal
                    open={openSpeakerModal}
                    onClose={handleCloseSpeakerModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        sx={{ textAlign: "center", marginBottom: "15px" }}
                        sx={{ fontSize: "16px" }}
                      >
                        Select Speaker
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                        className="speaker-options"
                      >
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Speaker
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedSpeaker}
                              label="Speaker"
                              onChange={handleSpeakerChange}
                            >
                              {allEngines[pptData.engine] &&
                                allEngines[pptData.engine].map(
                                  (speaker, index) => (
                                    <MenuItem key={index} value={speaker}>
                                      {speaker}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>

              <div className="arrow-icon">
                <div className="slide-counter-container">
                  <p className="current-count">{slideCounter}</p>
                  <p className="total-count">/</p>
                  <p className="total-count">{pptSlides.length}</p>
                </div>

                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSlideChange("left")}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="#7A7A7A"
                >
                  <path
                    d="M6.7 0.724218C6.9 0.924217 6.99583 1.16588 6.9875 1.44922C6.97917 1.73255 6.875 1.97422 6.675 2.17422L3.85 4.99922L15 4.99922C15.2833 4.99922 15.5208 5.09505 15.7125 5.28672C15.9042 5.47839 16 5.71589 16 5.99922C16 6.28255 15.9042 6.52005 15.7125 6.71172C15.5208 6.90339 15.2833 6.99922 15 6.99922L3.85 6.99922L6.7 9.84922C6.9 10.0492 7 10.2867 7 10.5617C7 10.8367 6.9 11.0742 6.7 11.2742C6.5 11.4742 6.2625 11.5742 5.9875 11.5742C5.7125 11.5742 5.475 11.4742 5.275 11.2742L0.7 6.69922C0.6 6.59922 0.529168 6.49088 0.487501 6.37422C0.445834 6.25755 0.425001 6.13255 0.425001 5.99922C0.425001 5.86588 0.445834 5.74088 0.487501 5.62422C0.529168 5.50755 0.6 5.39922 0.7 5.29922L5.3 0.699218C5.48333 0.515884 5.7125 0.424217 5.9875 0.424217C6.2625 0.424217 6.5 0.524218 6.7 0.724218Z"
                    fill="#7A7A7A"
                  />
                </svg>

                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSlideChange("right")}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_156_408"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_156_408)">
                    <path
                      d="M13.3 17.2758C13.1 17.0758 13.0042 16.8341 13.0125 16.5508C13.0208 16.2674 13.125 16.0258 13.325 15.8258L16.15 13.0008H5C4.71667 13.0008 4.47917 12.9049 4.2875 12.7133C4.09583 12.5216 4 12.2841 4 12.0008C4 11.7174 4.09583 11.4799 4.2875 11.2883C4.47917 11.0966 4.71667 11.0008 5 11.0008H16.15L13.3 8.15078C13.1 7.95078 13 7.71328 13 7.43828C13 7.16328 13.1 6.92578 13.3 6.72578C13.5 6.52578 13.7375 6.42578 14.0125 6.42578C14.2875 6.42578 14.525 6.52578 14.725 6.72578L19.3 11.3008C19.4 11.4008 19.4708 11.5091 19.5125 11.6258C19.5542 11.7424 19.575 11.8674 19.575 12.0008C19.575 12.1341 19.5542 12.2591 19.5125 12.3758C19.4708 12.4924 19.4 12.6008 19.3 12.7008L14.7 17.3008C14.5167 17.4841 14.2875 17.5758 14.0125 17.5758C13.7375 17.5758 13.5 17.4758 13.3 17.2758Z"
                      fill="#1167E5"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
        <h1>Render in progress...</h1>
        <h3>{"This process will take time, have some kitkat :)"}</h3>
      </Backdrop>
    </>
  );
};
