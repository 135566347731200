import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Avatar,
  Container,
  CssBaseline,
  Fab,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import axiosInstance from "../Axios";
import "./UserTable.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1167E5",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

export default function UserTable({ users, handleReload }) {
  // user state variable
  const [userVar, setUserVar] = useState({
    user_id: null,
    first_name: "",
    last_name: "",
    username: "",
    user_type_id: null,
  });

  // password state variable
  const [pwd, setPwd] = useState({
    password: "",
    confirm_password: "",
  });

  // delete user modal
  const [openDeleteUserModal, setDeleteUserModal] = useState(false);
  const handleOpenDeleteUserModal = () => setDeleteUserModal(true);
  const handleCloseDeleteUserModal = () => setDeleteUserModal(false);

  // edit res message
  const [resMessageEdit, setResMessageEdit] = useState("");
  const [showResMessageEdit, setShowResMessageEdit] = useState(false);

  // password res message
  const [resMessagePassword, setResMessagePassword] = useState("");
  const [showResMessagePassword, setShowResMessagePassword] = useState(false);

  // Password modal
  const [openPassword, setOpenPassword] = useState(false);
  const handleOpenPassword = () => setOpenPassword(true);
  const handleClosePassword = () => {
    setPwd(() => {
      return {
        password: "",
        confirm_password: "",
      };
    });
    setOpenPassword(false);
  };

  // Edit modal
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setUserVar(() => {
      return {
        user_id: null,
        first_name: "",
        last_name: "",
        username: "",
        user_type_id: null,
      };
    });
    setOpenEdit(false);
  };

  // Options Menu

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setShowResMessageEdit(false);
    setAnchorEl(null);
  };

  const firstNameHandler = (e) => {
    setShowResMessageEdit(false);
    setUserVar((prevState) => {
      return {
        ...prevState,
        first_name: e.target.value,
      };
    });
  };

  const lastNameHandler = (e) => {
    setShowResMessageEdit(false);
    setUserVar((prevState) => {
      return {
        ...prevState,
        last_name: e.target.value,
      };
    });
  };

  const usernameHandler = (e) => {
    setShowResMessageEdit(false);
    setUserVar((prevState) => {
      return {
        ...prevState,
        username: e.target.value,
      };
    });
  };

  const handleUserTypeChange = (event) => {
    setShowResMessageEdit(false);
    setUserVar((prevState) => {
      return {
        ...prevState,
        user_type_id: event.target.value,
      };
    });
  };

  const passwordHandler = (e) => {
    setShowResMessagePassword(false);
    setPwd((prevState) => {
      return {
        ...prevState,
        password: e.target.value,
      };
    });
  };

  const confirmPasswordHandler = (e) => {
    setShowResMessagePassword(false);
    setPwd((prevState) => {
      return {
        ...prevState,
        confirm_password: e.target.value,
      };
    });
  };

  const resetPwd = () => {
    handleOpenPassword();
  };

  const editUser = () => {
    handleOpenEdit();
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (
      pwd.password === "" ||
      pwd.confirm_password === "" ||
      pwd.password.trim() === "" ||
      pwd.confirm_password.trim() === ""
    ) {
      setResMessagePassword("Please fill out all fields");
      setShowResMessagePassword(true);
      return;
    }
    if (pwd.password !== pwd.confirm_password) {
      setResMessagePassword("Passwords do not match");
      setShowResMessagePassword(true);
      return;
    }

    try {
      let formData = new FormData();
      formData.append("user_id", userVar.user_id);
      formData.append("password", pwd.password);
      const res = await axiosInstance.post(
        "/api/admin/reset_user_password",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setResMessagePassword(res.data.message);
      setShowResMessagePassword(true);
      console.log("reset password response", res);

      setPwd(() => {
        return {
          password: "",
          confirm_password: "",
        };
      });

      setTimeout(() => {
        handleClosePassword();
      }, 2000);
    } catch (err) {
      setResMessagePassword("Error Resetting Password");
      setShowResMessagePassword(true);
      console.error(err.message);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    if (
      userVar.first_name === "" ||
      userVar.last_name === "" ||
      userVar.username === "" ||
      userVar.user_type_id === null ||
      userVar.first_name.trim() === "" ||
      userVar.last_name.trim() === "" ||
      userVar.username.trim() === ""
    ) {
      setResMessageEdit("Please fill out all fields");
      setShowResMessageEdit(true);
      return;
    }

    try {
      let formData = new FormData();
      formData.append("user_id", userVar.user_id);
      formData.append("first_name", userVar.first_name);
      formData.append("last_name", userVar.last_name);
      formData.append("username", userVar.username);
      formData.append("user_type_id", userVar.user_type_id);
      const res = await axiosInstance.post("/api/admin/edit_user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setResMessageEdit(res.data.message);
      setShowResMessageEdit(true);
      handleReload();

      setUserVar(() => {
        return {
          user_id: null,
          first_name: "",
          last_name: "",
          username: "",
          user_type_id: null,
        };
      });

      setTimeout(() => {
        handleCloseEdit();
      }, 2000);
    } catch (err) {
      setResMessageEdit("Error Editing User");
      setShowResMessageEdit(true);
      console.error(err.message);
    }
  };

  const userDelete = () => {
    handleOpenDeleteUserModal();
  };

  const deleteUser = async (user_id) => {
    try {
      let formData = new FormData();
      formData.append("user_id", user_id);
      const res = await axiosInstance.post("/api/admin/delete_user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      handleReload();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>User ID</StyledTableCell>
            <StyledTableCell align="right">First Name</StyledTableCell>
            <StyledTableCell align="right">Last Name</StyledTableCell>
            <StyledTableCell align="right">Username</StyledTableCell>
            <StyledTableCell align="right">User Type</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <StyledTableRow key={index} className="user-styled-row">
              <StyledTableCell component="th" scope="row">
                {user.user_id}
              </StyledTableCell>
              <StyledTableCell align="right">{user.first_name}</StyledTableCell>
              <StyledTableCell align="right">{user.last_name}</StyledTableCell>
              <StyledTableCell align="right">{user.username}</StyledTableCell>
              <StyledTableCell align="right">
                {user.user_type_id == 1
                  ? "Admin"
                  : user.user_type_id == 2
                  ? "Editor"
                  : "Reviewer"}
              </StyledTableCell>
              <StyledTableCell align="right">
                <div className="user-options-btn-container">
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => {
                      handleClickOptions(e);
                      setUserVar((prevState) => {
                        return {
                          ...prevState,
                          user_id: user.user_id,
                          first_name: user.first_name,
                          last_name: user.last_name,
                          username: user.username,
                          user_type_id: user.user_type_id,
                        };
                      });
                    }}
                    className="comment-options"
                  >
                    <MoreVertIcon />
                  </Button>

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseOptions}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        editUser();
                        handleCloseOptions();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        userDelete();
                        handleCloseOptions();
                      }}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        resetPwd();
                        handleCloseOptions();
                      }}
                    >
                      Reset Password
                    </MenuItem>
                  </Menu>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        {/* edit user modal */}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <PersonIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Edit User
                  </Typography>
                  {resMessageEdit === "User updated successfully" ? (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        display: showResMessageEdit ? "block" : "none",
                        color: "green",
                      }}
                    >
                      {resMessageEdit}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        display: showResMessageEdit ? "block" : "none",
                        color: "red",
                      }}
                    >
                      {resMessageEdit}
                    </Typography>
                  )}

                  <Box
                    component="form"
                    onSubmit={handleSubmitEdit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="first-name"
                      label="First Name"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      onChange={firstNameHandler}
                      value={userVar.first_name}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="last-name"
                      label="Last Name"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      onChange={lastNameHandler}
                      value={userVar.last_name}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      onChange={usernameHandler}
                      value={userVar.username}
                    />
                    <div
                      className="user-split-btn-container"
                      style={{ textAlign: "center", marginTop: "8px" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          User Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userVar.user_type_id}
                          label="User Type"
                          onChange={handleUserTypeChange}
                          sx={{ textAlign: "left" }}
                        >
                          <MenuItem value={1}>Admin</MenuItem>
                          <MenuItem value={2}>Editor</MenuItem>
                          <MenuItem value={3}>Reviewer</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Edit User
                    </Button>
                  </Box>
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
        </Modal>

        {/* Delete User Modal */}

        <Modal
          open={openDeleteUserModal}
          onClose={handleCloseDeleteUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              sx={{ fontSize: "16px" }}
              component="h2"
            >
              Are you sure you want to delete this User?
            </Typography>
            <div
              className="delete-modal-btn-container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                className="delete-ppt-modal-btn-delete"
                sx={{ fontWeight: "bold" }}
                onClick={() => {
                  deleteUser(userVar.user_id);
                  handleCloseDeleteUserModal();
                }}
                color="error"
              >
                Delete
              </Button>
              <Button
                className="delete-ppt-modal-btn-cancel"
                sx={{ fontWeight: "bold" }}
                onClick={handleCloseDeleteUserModal}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Reset Password Modal */}

        <Modal
          open={openPassword}
          onClose={handleClosePassword}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <PersonIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Reset Password
                  </Typography>
                  {resMessagePassword === "Password reset successfully" ? (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        display: showResMessagePassword ? "block" : "none",
                        color: "green",
                      }}
                    >
                      {resMessagePassword}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        display: showResMessagePassword ? "block" : "none",
                        color: "red",
                      }}
                    >
                      {resMessagePassword}
                    </Typography>
                  )}

                  <Box
                    component="form"
                    onSubmit={handleSubmitPassword}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="New Password"
                      type="password"
                      name="password"
                      autoFocus
                      onChange={passwordHandler}
                      value={pwd.password}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="confirm-password"
                      label="Confirm Password"
                      type="password"
                      name="password"
                      autoFocus
                      onChange={confirmPasswordHandler}
                      value={pwd.confirm_password}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Reset Password
                    </Button>
                  </Box>
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
        </Modal>
      </Table>
    </TableContainer>
  );
}
