import React from "react";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { useState } from "react";
import axiosInstance from "../../Axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

export const ChapterOptions = ({
  chapter_id,
  chapter_name,
  chapterData,
  setChapterData,
  course_id,
  setChapterReload,
}) => {
  // Delete Chapter Modal
  const [openDeleteChapterModal, setOpenDeleteChapterModal] = useState(false);
  const handleOpenDeleteChapterModal = () => setOpenDeleteChapterModal(true);
  const handleCloseDeleteChapterModal = () => setOpenDeleteChapterModal(false);

  // Edit Chapter Modal
  const [openEditChapterModal, setOpenEditChapterModal] = useState(false);
  const handleOpenEditChapterModal = () => setOpenEditChapterModal(true);
  const handleCloseEditChapterModal = () => setOpenEditChapterModal(false);

  const [resMessageEditChapter, setResMessageEditChapter] = useState("");
  const [showResMessageEditChapter, setShowResMessageEditChapter] =
    useState(false);

  const [chapter, setChapter] = useState({
    chapter_id: null,
    chapter_name: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openChapterOptions = Boolean(anchorEl);
  const handleChapterOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChapterOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleChapterDelete = (id) => {
    const newChapterData = chapterData.filter((item) => item.chapter_id !== id);
    setChapterData(newChapterData);

    try {
      let formData = new FormData();
      formData.append("chapter_id", id);
      const res = axiosInstance.post("api/chapter/delete_chapter", formData);
      console.log("Chapter Deleted Successfully!", res);
    } catch (err) {
      console.error(err.message);
    }
  };

  const chapterNameHandler = (event) => {
    setShowResMessageEditChapter(false);
    setChapter((prevState) => {
      return {
        ...prevState,
        chapter_name: event.target.value,
      };
    });
  };

  const handleEditChapterSubmit = async (event) => {
    event.preventDefault();
    if (chapter.chapter_name === "" || chapter.chapter_name.trim() === "") {
      setResMessageEditChapter("Chapter Name cannot be empty!");
      setShowResMessageEditChapter(true);
      return;
    }

    try {
      let formData = new FormData();
      formData.append("chapter_id", chapter.chapter_id);
      formData.append("chapter_name", chapter.chapter_name);
      formData.append("course_id", course_id);

      const res = await axiosInstance.post(
        "api/chapter/update_chapter",
        formData
      );
      setResMessageEditChapter("Chapter Updated Successfully!");
      setShowResMessageEditChapter(true);
      setChapterReload(true);

      setTimeout(() => {
        handleCloseEditChapterModal();
        setShowResMessageEditChapter(false);
        setChapter({
          chapter_id: null,
          chapter_name: "",
        });
      }, 2000);
    } catch (err) {
      setResMessageEditChapter("Something went wrong!");
      setShowResMessageEditChapter(true);
      console.error(err.message);
    }
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={openChapterOptions ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openChapterOptions ? "true" : undefined}
        size="extra-small"
        className="comment-options"
        onClick={(e) => {
          setChapter((prevState) => {
            return {
              ...prevState,
              chapter_id: chapter_id,
              chapter_name: chapter_name,
            };
          });
          handleChapterOptionsClick(e);
        }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openChapterOptions}
        onClose={handleChapterOptionsClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenEditChapterModal();
            handleChapterOptionsClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteChapterModal();
            handleChapterOptionsClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {/* Delete Chapter Modal */}
      <Modal
        open={openDeleteChapterModal}
        onClose={handleCloseDeleteChapterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ fontSize: "16px" }}
            component="h2"
          >
            Are you sure you want to delete this Chapter?
          </Typography>
          <div
            className="delete-modal-btn-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="delete-ppt-modal-btn-delete"
              sx={{ fontWeight: "bold" }}
              onClick={() => {
                handleChapterDelete(chapter.chapter_id);
                handleCloseDeleteChapterModal();
              }}
              color="error"
            >
              Delete
            </Button>
            <Button
              className="delete-ppt-modal-btn-cancel"
              sx={{ fontWeight: "bold" }}
              onClick={handleCloseDeleteChapterModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Edit Chapter Modal */}
      <Modal
        open={openEditChapterModal}
        onClose={handleCloseEditChapterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LibraryBooksOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Edit Chapter
                </Typography>
                {showResMessageEditChapter ? (
                  resMessageEditChapter === "Chapter Updated Successfully!" ? (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: "green",
                        display: showResMessageEditChapter ? "block" : "none",
                      }}
                    >
                      {resMessageEditChapter}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: "red",
                        display: showResMessageEditChapter ? "block" : "none",
                      }}
                    >
                      {resMessageEditChapter}
                    </Typography>
                  )
                ) : null}

                <Box
                  component="form"
                  onSubmit={handleEditChapterSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="chapter_name"
                    label="Chapter Name"
                    type="text"
                    id="chapter_name"
                    autoComplete="text"
                    value={chapter.chapter_name}
                    onChange={chapterNameHandler}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Edit Chapter
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
};
