import React, { useContext, useState } from "react";
import AvetiLogo from "../../Media/AvetiLogoWhite.svg";
import LogOutIcon from "../../Media/LogOutIcon.svg";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../Components/Axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import "./MainHomeLeftPanel.css";

const MainHomeLeftPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(0);

  const [user, setUser] = useState({
    username: Cookies.get("username"),
    user_type_id: Cookies.get("user_type_id"),
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (index) => {
    console.log("Clicked item index:", index);
    setSelected(index);
  };

  // APIs
  // Log Out API
  const logOutAPI = (e) => {
    // e.preventDefault();
    axiosInstance
      .get("/api/auth/logout")
      .then((res) => {
        if (res.data.status) {
          Cookies.remove("Token");
          Cookies.remove("username");
          Cookies.remove("user_type_id");
          dispatch({ type: "snackbarClose" });
          navigate("/");
          window.location.reload();
        } else {
          dispatch({
            type: "snackbarSuccess",
            message: "Some Problem occured",
            IsSuccessful: false,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        // if(error.response.data.message !== "Missing auth token" || error.response.data.message !== "Invalid auth token"){
        dispatch({
          type: "snackbarError",
          message: "Something is broken",
          IsSuccessful: false,
        });
        // }
      });
  };

  return (
    <div className="main-home-left-panel">
      <div className="main-home-avetiBranding">
        <img
          id="main-home-avetilogo"
          src={AvetiLogo}
          alt="Aveti Logo"
          width="80px"
          height="auto"
        />
      </div>

      <div className="main-home-aveti-nav">
        <ul className="main-aveti-left-nav-ul">
          <li className={`main-aveti-left-nav-li`}>
            <NavLink to="/home" className="main-aveti-left-nav-li">
              <HomeIcon />
              <p>Home</p>
            </NavLink>
          </li>
          {user.user_type_id == 1 ? (
            <>
              <li className={`main-aveti-left-nav-li`}>
                <NavLink to="/courses" className="main-aveti-left-nav-li">
                  <LibraryBooksOutlinedIcon />
                  <p>Courses</p>
                </NavLink>
              </li>
              <li className={`main-aveti-left-nav-li`}>
                <NavLink to="/users" className="main-aveti-left-nav-li">
                  <GroupIcon />
                  <p>Users</p>
                </NavLink>
              </li>
            </>
          ) : null}
          <div className="main-aveti-left-nav-li" onClick={handleUserMenuClick}>
            <Avatar
              sx={{
                bgcolor: deepOrange[500],
                width: 30,
                height: 30,
                marginLeft: "6px",
              }}
              alt="Remy Sharp"
              src="/broken-image.jpg"
            >
              {user.username[0].toUpperCase()}
            </Avatar>
            <p>@{user.username}</p>
          </div>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleUserMenuClose}
            sx={{ marginLeft: "50px", marginTop: "-5px" }}
          >
            {user.user_type_id == 1 ? (
              <MenuItem
                sx={{ height: "40px" }}
                onClick={() => {
                  navigate("/admin/trashbin");
                  handleUserMenuClose();
                }}
              >
                <div
                  className="trashbin-wrapper"
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                  onClick={(e) => console.log("trashbin")}
                >
                  <DeleteIcon />
                  <p>Trash Bin</p>
                </div>
              </MenuItem>
            ) : null}

            <MenuItem sx={{ height: "40px" }} onClick={handleUserMenuClose}>
              <div
                className="logout-wrapper"
                style={{ display: "flex" }}
                onClick={(e) => logOutAPI(e)}
              >
                <img src={LogOutIcon} alt="Log Out" />
                <p>Log Out</p>
              </div>
            </MenuItem>
          </Menu>
        </ul>
      </div>
    </div>
  );
};

export default MainHomeLeftPanel;
