import React, { useEffect } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import "./AddSlide.css"
import axiosInstance from '../../Axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: "90vh",
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    overflowY: "auto",
    p: 4,
};

const defaultTheme = createTheme();

const allowedFileTypes = ['image/jpeg', 'image/png', 'video/mp4'];

export const AddSlide = ({index, ppt_id, PPTSlides, selectedSlide, setSelectedSlide, setUpdate}) => {

    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [slideType, setSlideType] = useState(null); // image or video

    const onDrop = useCallback((acceptedFiles)=> {
        setResMessageAddSlide("");
        const file = acceptedFiles[0];
        if(file && allowedFileTypes.includes(file.type)) {
            setUploadedFile(file);
        }else {
            alert("Please select a valid file type (JPEG, PNG, MP4)");
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: allowedFileTypes.join(','),
        multiple: false
    });

    const removeFile = () => {
        setUploadedFile(null);
    };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     let dt = e.dataTransfer;
    //     let files = dt.files;
    //     handleFiles(files);
    // }

    // slide object
    const [newSlide, setNewSlide] = useState({
        "ppt_id": ppt_id,
        "slide_id": index+1,
        "slide_image_uri": "",
        "slide_notes": "",
        "slide_number": index+1,
    })

    // Add Slide Modal
    const [openAddSlideModal, setOpenAddSlideModal] = useState(false);
    const handleOpenAddSlideModal = () => setOpenAddSlideModal(true);
    const handleCloseAddSlideModal = () => {
        setNewSlide((prevState)=> {
            return {
                ...prevState,
                slide_notes: "",
            }
        })
        setUploadedFile(null);
        setOpenAddSlideModal(false)
        setResMessageAddSlide("");
    };

    // Add Slide Res Message
    const [resMessageAddSlide, setResMessageAddSlide] = useState("");
    const [showResMessageAddSlide, setShowResMessageAddSlide] = useState(false);

    const slideNoteChangeHandler = (e) => {
        setResMessageAddSlide("");
        setNewSlide((prevState) => ({
            ...prevState,
            slide_notes: e.target.value
        })
    )}

    const handleAddSlideSubmit = async (e) => {
        e.preventDefault();
        if(uploadedFile === null) {
            alert("Please select an image or video file");
            return;
        }

        try {
            
            setLoading(true);

            let formData = new FormData();
            formData.append('slide_image', uploadedFile);
            formData.append('ppt_id', newSlide.ppt_id);
            formData.append('slide_id', newSlide.slide_id);
            formData.append('slide_notes', newSlide.slide_notes);
            formData.append("slide_type", slideType);

            const res = await axiosInstance.post("/api/ppt/insert_slide", formData);
            if(res.status === 200) {
                setResMessageAddSlide("Slide Added Successfully!");
                setShowResMessageAddSlide(true);
                setUpdate(prevState => !prevState);
                setTimeout(() => {
                    setShowResMessageAddSlide(false);
                    handleCloseAddSlideModal();
                }, 2000);
            }

        } catch (err) {
            setResMessageAddSlide("Error Adding Slide!");
            setShowResMessageAddSlide(true);
            console.error(err.message);
        } finally {
            setNewSlide((prevState)=> {
                return {
                    ...prevState,
                    slide_notes: "",
                }
            })
            setLoading(false);
        }

    }

    useEffect(() => {
        if(uploadedFile) {
            if(uploadedFile.type.startsWith('image')) {
                setSlideType("IMAGE");
            }else {
                setSlideType("VIDEO");
            }
        }
    }, [uploadedFile])

  return (
    <div>
        <div className='add-slide-btn-container' style={{cursor: "pointer"}} onClick={handleOpenAddSlideModal}>
            <AddCircleOutlineIcon sx={{cursor: "pointer", marginBottom: "10px"}}/>
        </div>
        <Modal
            open={openAddSlideModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                <ThemeProvider theme={defaultTheme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div className="add-slide-avatar-container">
                                <Typography component="h1" variant="h5">
                                    Add Slide
                                </Typography>
                                {!loading && <CloseIcon onClick={handleCloseAddSlideModal} sx={{cursor: "pointer"}}/>}
                                
                            </div>
                            {
                                showResMessageAddSlide ? (
                                    resMessageAddSlide === "Slide Added Successfully!" ? (
                                        <Typography component="span" variant="body2" sx={{color: "green", display: showResMessageAddSlide ? "block" : "none"}}>
                                            {resMessageAddSlide}
                                        </Typography>
                                    ) : (
                                        <Typography component="span" variant="body2" sx={{color: "red", display: showResMessageAddSlide ? "block" : "none"}}>
                                            {resMessageAddSlide}
                                        </Typography>
                                    )
                                ) : (
                                    null
                                )
                            }
                            
                            <Box component="form" onSubmit={handleAddSlideSubmit} noValidate sx={{ mt: 1 }}>
                                {/* <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="slide_image"
                                    label="Slide Image URL"
                                    type="text"
                                    id="slide_image"
                                    autoComplete="text"
                                    value={newSlide.slide_image}
                                    onChange={slideImageChangeHandler}
                                /> */}

                                <div className="file-upload-container">
                                    <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    <p>Drag & drop a single image (JPEG or PNG) or MP4 video here, or click to select a file</p>
                                    </div>
                                    {uploadedFile && (
                                    <div className="file-preview">
                                        {uploadedFile.type.startsWith('image') ? (
                                        <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" className="preview-image" />
                                        ) : (
                                        <video controls className="preview-video">
                                            <source src={URL.createObjectURL(uploadedFile)} type="video/mp4" />
                                        </video>
                                        )}
                                        {
                                            loading ? (
                                                <button className="remove-button" disabled={true} style={{backgroundColor:"gray", cursor:"not-allowed"}} onClick={removeFile}>
                                                    Remove
                                                </button>
                                            ) : (

                                            <button className="remove-button" onClick={removeFile}>
                                                Remove
                                            </button>
                                            )
                                        }
                                    </div>
                                    )}
                                </div>

                                <textarea
                                    rows="7"
                                    cols="55"
                                    className="note-textarea"
                                    placeholder="Slide Notes..."
                                    value={newSlide.slide_notes}
                                    onChange={slideNoteChangeHandler}
                                />

                                {
                                    loading ? (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2, backgroundColor: "gray", cursor: "not-allowed" }}
                                            disabled={true}
                                        >
                                            Adding Slide...
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Add Slide
                                        </Button>
                                    )
                                }

                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Box>
        </Modal>
    </div>
  )
}
