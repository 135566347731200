import React, { useEffect } from 'react'
import "./Modal.css"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const Modal = () => {

  const dispatch = useDispatch();

const modalDisplay =  useSelector((state) => state.primeModalReducer.modalDisplayOn);
const modalMessage=  useSelector((state) => state.primeModalReducer.message.message);


const closeModal = async()=> {
  if(modalDisplay){
    dispatch({type: "hidePrimeModal"});
  }
}




  return (

    modalDisplay?<>
    <div className="primemodal">
        <div className="primemodal-content">
        
        <h4 className="primemodal-note"></h4>
        <span className="primemodal-span"></span>
        <h2 className="primemodal-message"> {modalMessage} </h2>
        <button className="primemodal-close"  onClick={(e)=> closeModal()} >Close</button>
        </div>
    </div>
    </>:<>
    </>

  )
}

export default Modal