import React from 'react'
import axiosInstance from '../../Axios'
import "../../Global.css"

const ManageUsers = () => {
  return (
    <div>ManageUsers</div>
  )
}

export default ManageUsers