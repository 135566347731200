import React from 'react'
import "./MyVideos.css"
// import { AxiosInstance } from 'axios'
import "../../Components/Global.css"
import DeleteIcon from "../../Media/DeleteVideo.svg"
import TranslateIcon from "../../Media/TranslateVideo.svg"
import ArchiveIcon from "../../Media/ArchiveVideo.svg"
import PlayIcon from "../../Media/PlayVideo.svg"
import { useState } from 'react'
import CloseIcon from "../../Media/cancel.svg"
import DeleteDanger from "../../Media/RedCancel.svg"
import DropDownIcon from "../../Media/arrow_drop_down.svg"

const MyVideos = () => {

    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [showTranslateVideo, setshowTranslateVideo] = useState(false)
    const [TTS, setTTS] = useState("")
    const [showTTS, setshowTTS] = useState(false)


    const TTSLanguages = ["Odia", "Hindi", "English"]

    // Funcs to handle Modals 
    /**
     * 
     * @type {boolean} value 
     */
    const displayDeleteModal = (value) => {
        setshowDeleteModal(value);
    }
    const displayTranslateModal = (value) => {
        setshowTranslateVideo(value);
    }

    const languageChoosenForTranslation = (language) => {
        setshowTTS(false);
        setTTS(language);
    }




    return (
        <>
            <h4 className='myvideos-header' >My Videos</h4>
            <div className="myvideos-main-container">

                <div className="myvideos-video">
                    <div className="myVideo-video-cover">
                        here should be the cover
                    </div>
                    <div className="myVideo-video-name">
                        sample video
                    </div>
                    <div className="myVideo-video-duration">
                        14 min
                    </div>
                    <div className="myVideo-video-date">
                        14/05/23
                    </div>

                    <div className="myVideo-video-Actions">
                        <img src={DeleteIcon} alt="Delete Video" onClick={(e) => { displayDeleteModal(true) }} />
                        <img src={TranslateIcon} alt="Translate Video" onClick={(e) => { displayTranslateModal(true) }} />
                        <img src={ArchiveIcon} alt="Archive Video" />
                        <img src={PlayIcon} alt="Play Video" />
                    </div>
                </div>

            </div>

            {
                showDeleteModal ? <>

                    <div className="myvideos-action-modal">
                        <div className="myvideos-action-modal-content">
                            <p className="myvideos-action-modal-content-close">
                                <img src={CloseIcon} alt="Close Button" width={"20px"} onClick={(e) => { displayDeleteModal(false) }} />
                            </p>
                            <div className="myvideos-modal-action-gist">

                                <div id="myvideos-modal-delete-danger">
                                    <img src={DeleteDanger} alt="Dangerous Action Icon" />
                                </div>

                                Do you want to delete it?
                            </div>

                            <div className="myvideos-modal-action-buttons">
                                <button className="myvideos-modal-button" onClick={(e) => { displayDeleteModal(false) }} >
                                    No
                                </button>
                                <button className="myvideos-modal-button  myvideos-positive-button ">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>

                </> : <></>
            }

            {
                showTranslateVideo ? <>
                    <div className="myvideos-action-modal">
                        <div className="myvideos-action-modal-content">
                            <p className="myvideos-action-modal-content-close">
                                <img src={CloseIcon} alt="Close Button" width={"20px"} onClick={(e) => { displayTranslateModal(false) }} />
                            </p>
                            <div className="myvideos-modal-action-gist">
                                Translate

                                <div className="myvideos-translate-tts"  onClick={(e)=> setshowTTS(true)} >
                                    <input type="text"
                                        placeholder='Select'
                                        value={TTS}
                                        readOnly
                                        className='myvideos-tts-input'
                                    />
                                    <img src={DropDownIcon} alt="Drop Down Icon" />
                                </div>
                                {
                                    showTTS? <>
                                    <div className="myvideos-translate-options">
                                        {
                                            TTSLanguages.map((language, index) => {
                                                return <p className="myvideos-translate-choice"
                                                onClick={(e) => { languageChoosenForTranslation(language) }}
                                                key={index}> {language} </p>
                                            })
                                        }

                                    </div>
                                        </>:<></>

                                }
                            </div>

                            <div className="myvideos-modal-action-buttons">
                                <button className="myvideos-modal-button" onClick={(e) => { displayTranslateModal(false) }} >
                                    Cancel
                                </button>
                                <button className="myvideos-modal-button  myvideos-positive-button ">
                                    Translate
                                </button>
                            </div>
                        </div>
                    </div>
                </> : <></>
            }
        </>
    )
}

export default MyVideos