import React, {useState} from "react";
import PPTStatusContext from "./PPTStatusContext";

export const PPTStatusState = (props) => {

    const [pptStatusState, setPPTStatusState] = useState({
        pptStatus: "",
        ppt_id: null,
    })

    return(
        <PPTStatusContext.Provider value={{pptStatusState, setPPTStatusState}}>

            {props.children}

        </PPTStatusContext.Provider>
    )

}