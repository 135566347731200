import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./PreviewAudioOptions.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import axiosInstance from "../../Axios";
import { UploadVoiceModal } from "../../UploadVoiceModal/UploadVoiceModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const PreviewAudioOptions = ({
  PPTData,
  setCurrentSpeaker,
  setUpdate,
  setSlide_id,
  ppt_id,
  selectedSlide,
  setSelectedSlide,
}) => {
  const [allEngines, setAllEngines] = useState([]);
  const [langCode, setLangCode] = useState({});
  const [currentSpeakerAudio, setCurrentSpeakerAudio] = useState(
    PPTData.speaker
  );
  const [openSpeakerModal, setOpenSpeakerModal] = useState(false);
  const handleOpenSpeakerModal = () => setOpenSpeakerModal(true);
  const handleCloseSpeakerModal = () => setOpenSpeakerModal(false);

  const [openUploadAudioModal, setOpenUploadAudioModal] = useState(false);
  const handleOpenUploadAudioModal = () => setOpenUploadAudioModal(true);
  const handleCloseUploadAudioModal = () => setOpenUploadAudioModal(false);

  const handleSpeakerChange = (e) => {
    setCurrentSpeakerAudio(e.target.value);
    setCurrentSpeaker(e.target.value);
  };

  const getLangCode = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/language/get_language_data_with_language_code_as_key"
      );
      setLangCode(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllEngines = async () => {
    try {
      const res = await axiosInstance.get("/api/miscellaneous/get_all_engines");
      setAllEngines(res.data.engines);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    if (openSpeakerModal) {
      getAllEngines();
      getLangCode();
    }
  }, [openSpeakerModal]);

  return (
    <div className="preview-audio-option-container">
      <button onClick={handleOpenSpeakerModal} className="audio-option-btn">
        Select Artist
      </button>
      {/* <button onClick={handleOpenUploadAudioModal} className="audio-option-btn">Upload Voice</button> */}

      <Modal
        open={openSpeakerModal}
        onClose={handleCloseSpeakerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center", marginBottom: "15px" }}
            sx={{ fontSize: "16px" }}
          >
            Select Artist
          </Typography>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
            className="speaker-options"
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Speaker</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentSpeakerAudio}
                  label="Artist"
                  onChange={handleSpeakerChange}
                >
                  {allEngines[PPTData.engine] &&
                    allEngines[PPTData.engine][langCode[PPTData.ppt_lang]] &&
                    Object.values(
                      allEngines[PPTData.engine][langCode[PPTData.ppt_lang]]
                    ).map((speaker, index) => (
                      <MenuItem key={index} value={speaker}>
                        {speaker}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
