import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ChapterListAccordion.css";
import { ChapterPPT } from "../ChapterPPT/ChapterPPT";
import HomeContext from "../../Context/HomeContext/HomeContext";

export const ChapterListAccordion = ({ chapters, refreshChapterLoader }) => {
  const HomeCtx = useContext(HomeContext);

  const [expanded, setExpanded] = useState(
    HomeCtx.homeState.expanded_chapter_id
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    HomeCtx.setHomeState((prevState) => {
      return {
        ...prevState,
        expanded_chapter_id: isExpanded ? panel : false,
      };
    });
  };

  useEffect(() => {
    setExpanded(HomeCtx.homeState.expanded_chapter_id);
  }, [HomeCtx.homeState.expanded_chapter_id]);

  return (
    <>
      <div className="chapter-list-accordion-container">
        {chapters.map((chapter) => (
          <Accordion
            key={chapter.chapter_id}
            expanded={expanded === `panel${chapter.chapter_id}`}
            onChange={handleChange(`panel${chapter.chapter_id}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${chapter.chapter_id}bh-content`}
              id={`panel${chapter.chapter_id}bh-header`}
              sx={{ backgroundColor: "#f8f9fd" }}
            >
              <Typography
                sx={{
                  width: "33%",
                  flexShrink: 0,
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "black",
                }}
              >
                {chapter.name}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {chapter.description}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded && (
                <ChapterPPT
                  id={chapter.chapter_id}
                  refreshChapterLoader={refreshChapterLoader}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};
