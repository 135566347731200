import React from "react";
import "./PPTMainView.css";
import ReactAudioPlayer from "react-audio-player";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import ReactPlayer from "react-player";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { ReferencePPT } from "../../PPTPage/ReferencePPT/ReferencePPT";
import axiosInstance from "../../Axios";
import Cookies from "js-cookie";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export const PPTMainView = ({
  PPTSlides,
  selectedSlide,
  setSelectedSlide,
  ppt_id,
}) => {
  const [user_type_id, setUser_type_id] = useState(
    parseInt(Cookies.get("user_type_id"))
  );
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(Cookies.get("user_id"));

  const [openRenderModal, setOpenRenderModal] = useState(false);
  const handleOpenRenderModal = () => setOpenRenderModal(true);
  const handleCloseRenderModal = () => {
    setShowRenderErrorMessage(false);
    setOpenRenderModal(false);
  };

  const [rendering, setRendering] = useState(false);
  const [renderErrorMessage, setRenderErrorMessage] = useState("");
  const [showRenderErrorMessage, setShowRenderErrorMessage] = useState(false);

  // backdrop

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 7000);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const renderVideo = async (mode) => {
    try {
      let debug_mode;

      setRendering(true);
      if (mode === "debug") {
        debug_mode = true;
      } else {
        debug_mode = false;
      }
      const res = await axiosInstance.get(
        `/api/queue/generate_video?ppt_id=${ppt_id}&debug_mode=${debug_mode}`
      );
      handleCloseRenderModal();
      handleOpenBackdrop();
    } catch (err) {
      setRendering(false);
      setRenderErrorMessage(err.response.data.message);
      setShowRenderErrorMessage(true);
      setTimeout(() => {
        setShowRenderErrorMessage(false);
      }, 4000);
      console.error(err.response.data.message);
    } finally {
      setRendering(false);
    }
  };

  const handleBulkAudioRender = async () => {
    axiosInstance
      .get(`/api/queue/generate_bulk_audio?ppt_id=${ppt_id}`)
      .then(() => {
        toast.info("Audio rendering started, check back after 10 minutes :)");
      })
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="ppt-main-view-container">
      <div className="ppt-main-view-header">
        {selectedSlide.slide_reference ? (
          <ReferencePPT slide_ref={selectedSlide.slide_reference} />
        ) : (
          <div></div>
        )}

        {user_type_id === 1 || user_type_id === 2 ? (
          <>
            <button
              onClick={handleBulkAudioRender}
              className="render-btn-ppt-main"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <mask
                  id="mask0_159_507"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="20"
                >
                  <rect x="0.290283" width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_159_507)">
                  <path
                    d="M10.2903 13.3333C10.0542 13.3333 9.85624 13.2535 9.69651 13.0938C9.53679 12.934 9.45693 12.7361 9.45693 12.5V4.02083L8.70693 4.77083C8.55415 4.92361 8.36318 5 8.13401 5C7.90485 5 7.70693 4.91667 7.54026 4.75C7.38749 4.58333 7.3111 4.38542 7.3111 4.15625C7.3111 3.92708 7.38749 3.73611 7.54026 3.58333L9.70693 1.41667C9.77637 1.34722 9.86318 1.29167 9.96735 1.25C10.0715 1.20833 10.1792 1.1875 10.2903 1.1875C10.4014 1.1875 10.509 1.20833 10.6132 1.25C10.7173 1.29167 10.8042 1.34722 10.8736 1.41667L13.0403 3.58333C13.2069 3.75 13.2903 3.95139 13.2903 4.1875C13.2903 4.42361 13.2069 4.61806 13.0403 4.77083C12.8736 4.92361 12.6757 5 12.4465 5C12.2173 5 12.0264 4.92361 11.8736 4.77083L11.1236 4.02083V12.5C11.1236 12.7361 11.0437 12.934 10.884 13.0938C10.7243 13.2535 10.5264 13.3333 10.2903 13.3333ZM5.29026 19.1667C4.83193 19.1667 4.43957 19.0035 4.11318 18.6771C3.78679 18.3507 3.6236 17.9583 3.6236 17.5V8.33333C3.6236 7.875 3.78679 7.48264 4.11318 7.15625C4.43957 6.82986 4.83193 6.66667 5.29026 6.66667H6.95693C7.19304 6.66667 7.39096 6.74653 7.55068 6.90625C7.7104 7.06597 7.79026 7.26389 7.79026 7.5C7.79026 7.73611 7.7104 7.93403 7.55068 8.09375C7.39096 8.25347 7.19304 8.33333 6.95693 8.33333H5.29026V17.5H15.2903V8.33333H13.6236C13.3875 8.33333 13.1896 8.25347 13.0298 8.09375C12.8701 7.93403 12.7903 7.73611 12.7903 7.5C12.7903 7.26389 12.8701 7.06597 13.0298 6.90625C13.1896 6.74653 13.3875 6.66667 13.6236 6.66667H15.2903C15.7486 6.66667 16.141 6.82986 16.4673 7.15625C16.7937 7.48264 16.9569 7.875 16.9569 8.33333V17.5C16.9569 17.9583 16.7937 18.3507 16.4673 18.6771C16.141 19.0035 15.7486 19.1667 15.2903 19.1667H5.29026Z"
                    fill="white"
                  />
                </g>
              </svg>
              <p className="render-btn-text-ppt-main">Render Audio</p>
            </button>
            <button
              onClick={handleOpenRenderModal}
              className="render-btn-ppt-main"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <mask
                  id="mask0_159_507"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="20"
                >
                  <rect x="0.290283" width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_159_507)">
                  <path
                    d="M10.2903 13.3333C10.0542 13.3333 9.85624 13.2535 9.69651 13.0938C9.53679 12.934 9.45693 12.7361 9.45693 12.5V4.02083L8.70693 4.77083C8.55415 4.92361 8.36318 5 8.13401 5C7.90485 5 7.70693 4.91667 7.54026 4.75C7.38749 4.58333 7.3111 4.38542 7.3111 4.15625C7.3111 3.92708 7.38749 3.73611 7.54026 3.58333L9.70693 1.41667C9.77637 1.34722 9.86318 1.29167 9.96735 1.25C10.0715 1.20833 10.1792 1.1875 10.2903 1.1875C10.4014 1.1875 10.509 1.20833 10.6132 1.25C10.7173 1.29167 10.8042 1.34722 10.8736 1.41667L13.0403 3.58333C13.2069 3.75 13.2903 3.95139 13.2903 4.1875C13.2903 4.42361 13.2069 4.61806 13.0403 4.77083C12.8736 4.92361 12.6757 5 12.4465 5C12.2173 5 12.0264 4.92361 11.8736 4.77083L11.1236 4.02083V12.5C11.1236 12.7361 11.0437 12.934 10.884 13.0938C10.7243 13.2535 10.5264 13.3333 10.2903 13.3333ZM5.29026 19.1667C4.83193 19.1667 4.43957 19.0035 4.11318 18.6771C3.78679 18.3507 3.6236 17.9583 3.6236 17.5V8.33333C3.6236 7.875 3.78679 7.48264 4.11318 7.15625C4.43957 6.82986 4.83193 6.66667 5.29026 6.66667H6.95693C7.19304 6.66667 7.39096 6.74653 7.55068 6.90625C7.7104 7.06597 7.79026 7.26389 7.79026 7.5C7.79026 7.73611 7.7104 7.93403 7.55068 8.09375C7.39096 8.25347 7.19304 8.33333 6.95693 8.33333H5.29026V17.5H15.2903V8.33333H13.6236C13.3875 8.33333 13.1896 8.25347 13.0298 8.09375C12.8701 7.93403 12.7903 7.73611 12.7903 7.5C12.7903 7.26389 12.8701 7.06597 13.0298 6.90625C13.1896 6.74653 13.3875 6.66667 13.6236 6.66667H15.2903C15.7486 6.66667 16.141 6.82986 16.4673 7.15625C16.7937 7.48264 16.9569 7.875 16.9569 8.33333V17.5C16.9569 17.9583 16.7937 18.3507 16.4673 18.6771C16.141 19.0035 15.7486 19.1667 15.2903 19.1667H5.29026Z"
                    fill="white"
                  />
                </g>
              </svg>
              <p className="render-btn-text-ppt-main">Render</p>
            </button>
          </>
        ) : (
          <div></div>
        )}

        <Modal
          open={openRenderModal}
          onClose={handleCloseRenderModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "15px",
                fontSize: "16px",
              }}
            >
              Choose render mode?
            </Typography>

            {showRenderErrorMessage ? (
              <Typography
                sx={{
                  textAlign: "center",
                  marginBottom: "15px",
                  color: "red",
                  fontSize: "16px",
                }}
              >
                {renderErrorMessage}
              </Typography>
            ) : null}
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <button
                onClick={() => renderVideo("debug")}
                className="render-btn-modal"
              >
                Debug
              </button>
              <button
                onClick={() => renderVideo("production")}
                className="render-cancel-btn"
              >
                Production
              </button>
            </div>
          </Box>
        </Modal>
      </div>

      {selectedSlide.slide_type === "IMAGE" ? (
        <>
          <div className="ppt-main-view-body">
            <img
              src={selectedSlide.slide_image_uri}
              alt="ppt-slide"
              className="ppt-selected-slide"
            />
          </div>
          <div className="ppt-main-view-audio-player-container">
            {selectedSlide.slide_audio_uri && (
              <ReactAudioPlayer
                key={selectedSlide.slide_audio_uri}
                src={selectedSlide.slide_audio_uri}
                controls
                className="ppt-main-audio-player"
              />
            )}
          </div>
        </>
      ) : (
        <ReactPlayer
          url={selectedSlide.slide_image_uri}
          controls={true}
          className="ppt-main-view-video-player"
          height="500px"
          width="868px"
        />
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
        <h1>Render in progress...</h1>
        <h3>{"This process will take time, check back after 10 minutes :)"}</h3>
      </Backdrop>
    </div>
  );
};
