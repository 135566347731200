import React from 'react'
import "./ControlPanel.css"

export const ControlPanel = ({play, isPlaying, duration, currentTime, audioRef}) => {
    const [showVolumeBar, setShowVolumeBar] = React.useState(false);
    const [volume, setVolume] = React.useState(50);

    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);

    const toggleVolumeBar = () => {
        setShowVolumeBar(!showVolumeBar);
    };

    const toggleSettingsMenu = () => {
      setIsSettingsOpen(!isSettingsOpen);
    }

    function secondsToHms(seconds) {
        if (!seconds) return '00:00';
    
        let duration = seconds;
        let hours = duration / 3600;
        duration = duration % 3600;
    
        let min = parseInt(duration / 60);
        duration = duration % 60;
    
        let sec = parseInt(duration);
    
        if (sec < 10) {
          sec = `0${sec}`;
        }
        if (min < 10) {
          min = `0${min}`;
        }
    
        if (parseInt(hours, 10) > 0) {
          return `${parseInt(hours, 10)}:${min}:${sec}`;
        } else if(min == 0) {
            return `00:${sec}`;
        }else{
            return `${min}:${sec}`;
        }
    }

    const handleVolumeChange = (event, newValue) => {
        // Handle volume change here
        // Convert volume to a value between 0 and 1
        setVolume(newValue);
        if (audioRef.current) {
            audioRef.current.volume = newValue / 100;
        }
    };

  return (
    <div className="control-panel">
        <div className="timer">{secondsToHms(currentTime)}</div>
        
        <div className="volume-control">

            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <mask id="mask0_156_421" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_156_421)">
                <path d="M15.85 20.3004C15.5167 20.4337 15.2083 20.3921 14.925 20.1754C14.6417 19.9587 14.5 19.6587 14.5 19.2754C14.5 19.0921 14.5542 18.9296 14.6625 18.7879C14.7708 18.6462 14.9083 18.5421 15.075 18.4754C16.4083 17.9421 17.4792 17.0921 18.2875 15.9254C19.0958 14.7587 19.5 13.4421 19.5 11.9754C19.5 10.5087 19.0958 9.19208 18.2875 8.02541C17.4792 6.85874 16.4083 6.00874 15.075 5.47541C14.8917 5.40874 14.75 5.30041 14.65 5.15041C14.55 5.00041 14.5 4.83374 14.5 4.65041C14.5 4.28374 14.6417 3.99208 14.925 3.77541C15.2083 3.55874 15.5167 3.51708 15.85 3.65041C17.55 4.33374 18.9167 5.42541 19.95 6.92541C20.9833 8.42541 21.5 10.1087 21.5 11.9754C21.5 13.8421 20.9833 15.5254 19.95 17.0254C18.9167 18.5254 17.55 19.6171 15.85 20.3004ZM4.5 15.0004C4.21667 15.0004 3.97917 14.9046 3.7875 14.7129C3.59583 14.5212 3.5 14.2837 3.5 14.0004V10.0004C3.5 9.71708 3.59583 9.47958 3.7875 9.28791C3.97917 9.09624 4.21667 9.00041 4.5 9.00041H7.5L10.8 5.70041C11.1167 5.38374 11.4792 5.31291 11.8875 5.48791C12.2958 5.66291 12.5 5.97541 12.5 6.42541V17.5754C12.5 18.0254 12.2958 18.3379 11.8875 18.5129C11.4792 18.6879 11.1167 18.6171 10.8 18.3004L7.5 15.0004H4.5ZM14.5 16.0004V7.95041C15.25 8.30041 15.8542 8.84208 16.3125 9.57541C16.7708 10.3087 17 11.1171 17 12.0004C17 12.8837 16.7708 13.6837 16.3125 14.4004C15.8542 15.1171 15.25 15.6504 14.5 16.0004ZM10.5 8.85041L8.35 11.0004H5.5V13.0004H8.35L10.5 15.1504V8.85041Z" fill="#212121"/>
              </g>
            </svg>

            <input
            type="range"
            min="0"
            max="100"
            value={volume}
            onChange={(e) => handleVolumeChange(e, e.target.value)}
            />
        </div>

    </div>
  )
}
