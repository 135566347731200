import React, {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TextareaAutosize } from '@mui/base';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup, Menu } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import axiosInstance from '../../Axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: "90vh",
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    overflowY: "auto",
    p: 4,
};

const defaultTheme = createTheme();

const allowedFileTypes = ['audio/mpeg'];

export const AudioOptions = ({selectedButton, handleOpenSpeakerModal, handleButtonClick, handleOutsideClick, ppt_id, slide_id, setReloadUploadAudio, setSelectedSlide}) => {

    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadingAudio, setUploadingAudio] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && allowedFileTypes.includes(file.type)) {
          setUploadedFile(file);
        } else {
          alert('Invalid file format. Please upload a single MP3 audio.');
        }
    }, []);

    const removeFile = () => {
        setUploadedFile(null);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: allowedFileTypes.join(','),
        multiple: false,
    });

    // upload audio modal
    const [openUploadAudioModal, setOpenUploadAudioModal] = useState(false);
    const handleOpenUploadAudioModal = () => setOpenUploadAudioModal(true);
    const handleCloseUploadAudioModal = () => {
        setUploadedFile(null);
        setOpenUploadAudioModal(false);
    };

    // upload auido res message
    const [showResMessageUploadAudio, setShowResMessageUploadAudio] = useState(false);
    const [resMessageUploadAudio, setResMessageUploadAudio] = useState("");

    const handleAddSlideSubmit = async (e) => {
        e.preventDefault();
        setUploadingAudio(true);
        if(!uploadedFile) {
            setResMessageUploadAudio("Please upload an audio file.");
            setShowResMessageUploadAudio(true);
            return;
        }
        let formData = new FormData();
        formData.append("ppt_id", ppt_id);
        formData.append("slide_id", slide_id);
        formData.append("audio", uploadedFile);

        try {
            
            const res = await axiosInstance.post("/api/ppt/add_audio", formData, 'multipart/form-data');
            setSelectedSlide((prevState)=> {
                return {
                    ...prevState,
                    slide_audio_uri: res.data.audio_url
                }
            });
            setResMessageUploadAudio("Audio Uploaded Successfully!");
            setShowResMessageUploadAudio(true);
            setReloadUploadAudio(true);

            setTimeout(() => {
                handleCloseUploadAudioModal();
                setShowResMessageUploadAudio(false);
            }, 2000);
        } catch (err) {
            setResMessageUploadAudio("Error uploading audio. Please try again.");
            setShowResMessageUploadAudio(true);
            console.log(err.message);
        } finally {
            setUploadingAudio(false);
        }
    }

  return (
    <div>
        <div className="audio-options-container">
            <div className="audio-buttons">
                <button
                    className={`audio-btn${selectedButton === "Select Artist" ? "selected" : ""}`}
                    onClick={() => {handleOpenSpeakerModal(); handleButtonClick("Select Artist");}}
                >
                    Select Artist
                </button>

                <button
                    className={`audio-btn${selectedButton === "Upload Voice" ? "selected" : ""}`}
                    onClick={() => {
                        handleOpenUploadAudioModal();
                        handleButtonClick("Upload Voice");
                    }}
                >
                    Upload Voice
                </button>

                {/* <button
                    className={`audio-btn${selectedButton === "No Speech" ? "selected" : ""}`}
                    onClick={() => handleButtonClick("No Speech")}
                >
                    No Speech
                </button> */}
            </div>


            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                
            </OutsideClickHandler>
        </div>

        {/* Upload Audio Modal */}
        <Modal
            open={openUploadAudioModal}
            onClose={handleCloseUploadAudioModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="upload-audio-modal"
        >

            <Box sx={style}>
                <ThemeProvider theme={defaultTheme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LibraryBooksOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Upload Audio
                            </Typography>
                            {
                                showResMessageUploadAudio ? (
                                    resMessageUploadAudio === "Audio Uploaded Successfully!" ? (
                                        <Typography component="span" variant="body2" sx={{color: "green", display: showResMessageUploadAudio ? "block" : "none"}}>
                                            {resMessageUploadAudio}
                                        </Typography>
                                    ) : (
                                        <Typography component="span" variant="body2" sx={{color: "red", display: showResMessageUploadAudio ? "block" : "none"}}>
                                            {resMessageUploadAudio}
                                        </Typography>
                                    )
                                ) : (
                                    null
                                )
                            }
                            
                            <Box component="form" onSubmit={handleAddSlideSubmit} noValidate sx={{ mt: 1 }}>

                                <div className={`file-upload-container ${isDragActive ? 'drag-active' : ''}`}>
                                    <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    <p>Drag & drop a single MP3 Audio here, or click to select a file</p>
                                    </div>
                                    {uploadedFile && (
                                    <div className="file-preview">
                                        <audio controls className="preview-audio">
                                            <source src={URL.createObjectURL(uploadedFile)} type="audio/mpeg" />
                                        </audio>
                                        <button className="remove-button" onClick={removeFile}>
                                            Remove
                                        </button>
                                    </div>
                                    )}
                                </div>

                                {
                                    uploadingAudio ? (
                                        <div className="uploading-audio-loader">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Upload Audio
                                        </Button>
                                    )
                                }

                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Box>

        </Modal>
        
    </div>
  )
}
