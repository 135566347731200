import React, { useEffect, useState } from 'react'
import "./UserPage.css"
import HomeLeftPanel from '../../TestCompo/HomeLeftPanel/HomeLeftPanel'
import UserTable from '../UserTable/UserTable'
import { Avatar, Box, Button, Container, CssBaseline, Fab, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import axiosInstance from '../Axios'
import MainHomeLeftPanel from '../MainHomeLeftPanel/MainHomeLeftPanel'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const defaultTheme = createTheme();

export const UserPage = () => {

    const [users, setUsers] = useState([])
    const [resMessage, setResMessage] = useState("");
    const [showResMessage, setShowResMessage] = useState(false);
    const [reload, setReload] = useState(false);

    const getUserData = async () => {
        try {

            const res = await axiosInstance.get("/api/admin/get_users")
            setUsers(res.data.data)

        } catch (err) {
        
            console.error(err);

        }
    }


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [user, setUser] = useState({
        username: "",
        first_name: "",
        last_name: "",
        password: "",
        user_type_id: null,
    });

    const firstNameHandler = (event) => {
        setShowResMessage(false)
        setUser((prevState) =>{
            return{
                ...prevState,
                first_name: event.target.value,
            }
        })
    }

    const lastNameHandler = (event) => {
        setShowResMessage(false)
        setUser((prevState) =>{
            return{
                ...prevState,
                last_name: event.target.value,
            }
        })
    }

    const usernameHandler = (event) => {
        setShowResMessage(false)
        setUser((prevState) =>{
            return{
                ...prevState,
                username: event.target.value,
            }
        })
    }

    const passwordHandler = (event) => {
        setShowResMessage(false)
        setUser((prevState) =>{
            return{
                ...prevState,
                password: event.target.value,
            }
        })
    }

    const handleUserTypeChange = (event) => {
        setUser((prevState) =>{
            return{
                ...prevState,
                user_type_id: event.target.value,
            }
        })
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if(user.first_name === "" || user.last_name === "" || user.username === "" || user.password === "" || user.user_type_id === null || user.first_name.trim() === "" || user.last_name.trim() === "" || user.username.trim() === "" || user.password.trim() === ""){
            setResMessage("Please fill all the fields");
            setShowResMessage(true);
            return;
        }
        await createUser(user)
        setUser({
            username: "",
            first_name: "",
            last_name: "",
            password: "",
            user_type_id: null,
        });

        setTimeout(() => {
            handleClose();
            setShowResMessage(false);
        }, 2000);
    };

    const createUser = async (user) => {
        try {
            let data = new FormData();
            data.append("first_name", user.first_name);
            data.append("last_name", user.last_name);
            data.append("username", user.username);
            data.append("password", user.password);
            data.append("user_type_id", user.user_type_id);
            const res = await axiosInstance.post("/api/admin/create_user", data)
            setResMessage("User Added Successfully");
            setShowResMessage(true);
            setUsers([...users, res.data.data])
            handleReload();
        } catch (err) {
            setResMessage(err.response.data.message || "Something went wrong");
            setShowResMessage(true);
            console.error(err);
        }
    }

    const handleReload = () => {
        setReload(!reload);
    }

    useEffect(() => {
        getUserData();
    }, [reload])

  return (
    <div className="user-page">
        
        {/* <HomeLeftPanel /> */}

        <MainHomeLeftPanel />
        
        <div className= "user-right-panel">

            <div className="user-right-panel-header">

                <div className="page-logo">

                    <GroupIcon />
                    <p>Users</p>

                </div>

                <div className="add-user-btn">

                    <Fab variant="extended" color="primary" aria-label="add" onClick={handleOpen} sx={{backgroundColor:"#1167E5"}}>
                        <AddIcon />
                        Add User
                    </Fab>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <ThemeProvider theme={defaultTheme}>
                                <Container component="main" maxWidth="xs">
                                    <CssBaseline />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                            <PersonIcon />
                                        </Avatar>
                                        <Typography component="h1" variant="h5">
                                            Add User
                                        </Typography>
                                        {
                                            resMessage === "User Added Successfully" ? (
                                                <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'green' }}>
                                                    {resMessage}
                                                </Typography>
                                            ) : (
                                                <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'red' }}>
                                                    {resMessage}
                                                </Typography>
                                            )
                                        }
                                        
                                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="first-name"
                                                label="First Name"
                                                name="text"
                                                autoComplete="text"
                                                autoFocus
                                                onChange={firstNameHandler}
                                                value={user.first_name}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="last-name"
                                                label="Last Name"
                                                name="text"
                                                autoComplete="text"
                                                autoFocus
                                                onChange={lastNameHandler}
                                                value={user.last_name}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="username"
                                                label="Username"
                                                name="text"
                                                autoComplete="text"
                                                autoFocus
                                                onChange={usernameHandler}
                                                value={user.username}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                                onChange={passwordHandler}
                                                value={user.password}
                                            />
                                            <div className="user-split-btn-container" style={{textAlign:"center", marginTop: "8px"}}>

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={user.user_type_id}
                                                    label="User Type"
                                                    onChange={handleUserTypeChange}
                                                    sx={{textAlign:"left"}}
                                                    >
                                                        <MenuItem value={1}>Admin</MenuItem>
                                                        <MenuItem value={2}>Editor</MenuItem>
                                                        <MenuItem value={3}>Reviewer</MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            >
                                            Add User
                                            </Button>
                                        </Box>
                                    </Box>
                                </Container>
                            </ThemeProvider>

                        </Box>
                    </Modal>

                </div>

            </div>

            <div className="user-table">

                <UserTable users={users} handleReload={handleReload}/>

            </div>

        </div>

    </div>
  )
}
