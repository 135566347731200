import React, { useEffect, useState } from 'react'
import axiosInstance from '../Axios';

export const PPTStatusCompo = ({ppt_id, refresh}) => {

    const [conversionStatus, setConversionStatus] = useState("");

    // useEffect(()=> {
    //   const eventSource = new EventSource("http://aveti.ankitrajmahapatra.co/api/updates");

    //   eventSource.onmessage = (e)=> {
    //     console.log("Message received", e.data);
    //     // setConversionStatus(JSON.parse(e.data));
    //   }

    //   eventSource.onerror = (e)=> {
    //     console.error("Error occurred",e);
    //   }

    //   return ()=> eventSource.close();
    // }, [])

    // useEffect(() => {
    //   let eventSource = new EventSource("http://aveti.ankitrajmahapatra.co/api/updates");
    //   let timeoutId;
    
    //   const startTimeout = () => {
    //     timeoutId = setTimeout(() => {
    //       console.log("No events received in 60 seconds. Reconnecting...");
    //       eventSource.close();
    //       eventSource = new EventSource("http://aveti.ankitrajmahapatra.co/api/updates");
    //       setupEventListeners(eventSource);
    //     }, 60000);
    //   };
    
    //   const setupEventListeners = (source) => {
    //     source.onmessage = (e) => {
    //       console.log("Message received", e.data);
    //       clearTimeout(timeoutId); // Clear the timeout since an event has been received
    //       startTimeout(); // Start the timeout again
    //       // setConversionStatus(JSON.parse(e.data));
    //     };
    
    //     source.onerror = (e) => {
    //       console.error("Error occurred", e);
    //       clearTimeout(timeoutId); // Clear the timeout if there's an error
    //       source.close(); // Close the event source connection
    //       // You can handle the error and reconnect if needed
    //     };
    //   };
    
    //   setupEventListeners(eventSource);
    //   startTimeout(); // Start the initial timeout
    
    //   // Clean up function to close the event source connection when the component unmounts
    //   return () => {
    //     clearTimeout(timeoutId); // Clear the timeout before unmounting
    //     eventSource.close(); // Close the event source connection
    //   };
    // }, []);
    

    // const getConversionStatus = async () => {

    //     try {
    //         const res = await axiosInstance.get(`/api/queue/get_overall_ppt_status?ppt_id=${ppt_id}`);
    //         setConversionStatus(res.data.message);
    //     } catch (err) {
    //         console.error(err.message);
    //     }

    // }

    // useEffect(()=> {
    //   if(ppt_id){
    //       getConversionStatus();
    //   }
    // }, [ppt_id, conversionStatus]);

    // useEffect(()=> {
    //   const pollingInterval = setInterval(()=> {

    //     if(ppt_id){
    //       getConversionStatus();
    //     }


    //   }, 5000);

    //   return ()=> clearInterval(pollingInterval);
    // }, [ppt_id]);

    const getConversionStatus = async () => {
      
      try {
        
        const res = await axiosInstance.get(`/api/queue/get_overall_ppt_status?ppt_id=${ppt_id}`)
        setConversionStatus(res.data.message);

      } catch (err) {

        console.error(err.message);

      }

    }

    useEffect(()=> {
      if(ppt_id){
        getConversionStatus();
      }
    }, [refresh]);

  return (
    <div>{conversionStatus}</div>
  )
}
