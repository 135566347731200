import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CourseDataTable from '../CourseDataTable/CourseDataTable';
import "./CourseAccordian.css"
import { CourseOptionsButton } from './CourseOptionsButton/CourseOptionsButton';

export default function CourseAccordian({courseData, catalogue, setReload, catalogueData, langDict, setCourseData}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  return (
      <div>

        {courseData.map((item, index)=>(
            <Accordion
                key={index}
                expanded={expanded === item["course_id"]}
                onChange={handleChange(item["course_id"])}
            >
            
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item["course_id"]}-content`}
                    id={`panel${item["course_id"]}-header`}
                    style={{ backgroundColor: expanded === item["course_id"] ? '#f8f9fd' : 'initial' }}
                    className='course-accordian-header'
                >
                    <div style={{display:"flex", gap: "0", alignItems: "center"}} className='course-accordian-header-container'>

                        <Typography sx={{ flexShrink: 0, fontWeight:"bold", fontSize:"16" }}>
                            {item["course_name"]}
                        </Typography>

                        <div className="edit-course-button-container">

                            <CourseOptionsButton setReload={setReload} courseData={courseData} setCourseData={setCourseData} item={item} catalogueData={catalogueData} langDict={langDict} catalogue={catalogue}/>

                        </div>

                    </div>

                </AccordionSummary>
                <AccordionDetails>

                    <CourseDataTable id={item["course_id"]} catalogue={catalogue}/>

                </AccordionDetails>

            </Accordion>
        ))}

    </div>
  );
}
