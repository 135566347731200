import React, { useContext } from "react";
import "../Global.css";
import "./Home.css";
import MyPPTLabel from "../../TestCompo/MyPPTLabel";
import { VideoPPTList } from "../VideoPPTList/VideoPPTList";
import MainHomeLeftPanel from "../MainHomeLeftPanel/MainHomeLeftPanel";

const Home = () => {
  // useEffect(()=> {
  //     const eventSource = new EventSource("http://192.168.29.190:5000/updates");

  //     eventSource.onmessage = (e)=> {
  //         PPTLogCtx.setPPTLogState(JSON.parse(e.data));
  //     }

  //     eventSource.onerror = (e)=> {
  //         console.error("Error occurred",e);
  //     }

  //     return ()=> eventSource.close();

  // }, [])

  return (
    <>
      <div className="home-main">
        <MainHomeLeftPanel />

        <div className="home-right-panel">
          <div className="home-right-panel-header">
            <MyPPTLabel />
            {/* <VideoSearchBar /> */}
          </div>

          <div className="home-right-panel-list">
            <VideoPPTList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
