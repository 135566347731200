import React, { useEffect, useState } from 'react'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import "./CoursePage.css"
import { Avatar, Box, Button, Container, CssBaseline, Fab, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CourseAccordian from '../CourseAccordian/CourseAccordian';
import axiosInstance from '../Axios';
import { CreateCatalogueModal } from '../CreateCatalogueModal/CreateCatalogueModal';
import MainHomeLeftPanel from '../MainHomeLeftPanel/MainHomeLeftPanel';
import { EditCatalogue } from './EditCatalogue/EditCatalogue';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"10px"
};

const defaultTheme = createTheme();

export const CoursePage = () => {

    const [reload, setReload] = React.useState(false);
    const [catalogueReload, setCatalogueReload] = React.useState(false);
    const [courseReload, setCourseReload] = React.useState(false);
    const [catalogue, setCatalogue] = useState(null)
    const [catalogueData, setCatalogueData] = React.useState([]);
    const [catalogueCourseData, setCatalogueCourseData] = React.useState([]);

    // lang code as key dict 
    const [langDict, setLangDict] = React.useState({});
    
    const [resMessage, setResMessage] = React.useState("");
    const [showResMessage, setShowResMessage] = React.useState(false);
    const [openCourseModal, setOpenCourseModal] = React.useState(false);
    const handleOpenCourseModal = () => setOpenCourseModal(true);
    const handleCloseCourseModal = () => setOpenCourseModal(false);
    const [openCatalogueModal, setOpenCatalogueModal] = React.useState(false);
    const handleOpenCatalogueModal = () => setOpenCatalogueModal(true);
    const handleCloseCatalogueModal = () => setOpenCatalogueModal(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [course, setCourse] = React.useState({
        course_name: "",
        course_catalogue: null
    });


    const handleChange = (event) => {
        setCatalogue(event.target.value);
        setCourse((prevState)=> {
            return {
                ...prevState,
                course_catalogue: event.target.value
            }
        })
        // setReload(true);
    };

    const getCatalogueData = async () => {
    
        try {
            const res = await axiosInstance.get("/api/catalogue/get_all_catalogue");
            const catalogue_data = res.data.data;
            setCatalogueData(catalogue_data);
            if(catalogue_data.length > 0){
                setCatalogue(()=> {
                    return catalogue_data[0].catalogue_id
                });
                setCourse((prevState)=> {
                    return {
                        ...prevState,
                        course_catalogue: catalogue_data[0].catalogue_id
                    }
                })
            }
        } catch (err) {
            console.error(err.message);
        }

    }

    const getLangDict = async () => {
        try {
            const res = await axiosInstance.get("/api/language/get_language_data_with_language_code_as_key");
            setLangDict(res.data.data);
        } catch (err) {
            console.error(err.message);
        }
    }

    const getCatalogueCourseData = async () =>{
        if(catalogue === null){
            return;
        }
        try {
            const res =  await axiosInstance.get(`/api/course/get_all_course?catalogue_id=${catalogue}`)
            setCatalogueCourseData(res.data.data);
        } catch (err) {
            console.error(err.message);
        }
    }

    const course_nameHandler = (event) => {
        setShowResMessage(false);
        setCourse((prevState)=>{
            return {
                ...prevState,
                course_name: event.target.value
            }
        })
    }

    const handleChangeCourseCatalogue = (event) => {
        setShowResMessage(false);
        setCourse((prevState)=> {
            return {
                ...prevState,
                course_catalogue: event.target.value
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(course.course_name === "" || course.course_catalogue === null || course.course_name.trim() === ""){
            setResMessage("Please fill all the fields");
            setShowResMessage(true);
            return;
        }
        await addNewCourse(course);
        setCourseReload(true);
        setCourse({
            course_name: "",
            language: ""
        })

        setTimeout(()=> {
            handleCloseCourseModal();
            setShowResMessage(false);
        }, 2000)
    };

    const addNewCourse = async (course) => {
    
        try {
            let data = new FormData();
            data.append('course_name', course.course_name);
            data.append('catalogue_id', course.course_catalogue);
            const res = await axiosInstance.post("/api/course/create_course", data, {headers: {'Content-Type': 'multipart/form-data'}});
            setResMessage("Course Added Successfully");
            setShowResMessage(true);
        } catch (err) {
            setResMessage(err.response.data.message);
            setShowResMessage(true);
            console.error(err.message);
        }

    }

    useEffect(() => {
        getCatalogueData();
        getCatalogueCourseData();
        getLangDict();
    }, [])

    useEffect(() => {
        getCatalogueData();
    }, [catalogueReload])

    useEffect(() => {
        getCatalogueData();
        getCatalogueCourseData();

        if(reload){
            setReload(false);
        }

    }, [reload])

    useEffect(() => {
        getCatalogueCourseData();

        if(courseReload){
            setCourseReload(false);
        }
        
    }, [courseReload])

    useEffect(() => {
        getCatalogueCourseData();
    }, [catalogue])

    // useEffect(() => {
    //     getCatalogueData();
    //     getLangDict();

    // }, [])

    // useEffect(() => {
    //     getCatalogueCourseData();
        
    //     if(reload){
    //         setReload(false);
    //     }

    // }, [reload])

    // useEffect(() => {
    //     getCatalogueCourseData();
    // }, [catalogue])

    // console.log("setcatalogue end", catalogue);

  return (
    <div className="course-main-container">

        {/* <HomeLeftPanel /> */}

        <MainHomeLeftPanel />

        <div className="course-right-panel">

            <div className="course-right-panel-header">

                <div className="course-right-header-title">

                    <LibraryBooksOutlinedIcon />
                    <h2 className="course-right-panel-header-text">Courses</h2>

                </div>

                <Modal
                    open={openCourseModal}
                    onClose={handleCloseCourseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        
                        <ThemeProvider theme={defaultTheme}>
                            <Container component="main" maxWidth="xs">

                                <CssBaseline />
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                                >
                                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                        <LibraryBooksOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Add Course
                                    </Typography>

                                    {
                                        resMessage === "Course Added Successfully" ? (
                                            <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'green' }}>
                                                {resMessage}
                                            </Typography>
                                        ) : (
                                            <Typography component="span" variant="body2" style={{ display: showResMessage ? 'block' : 'none', color: 'red' }}>
                                                {resMessage}
                                            </Typography>
                                        )
                                    }

                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="course_name"
                                            label="Course Name"
                                            name="course_name"
                                            autoFocus
                                            onChange={course_nameHandler}
                                            value={course.course_name}
                                        />
                                        
                                        {/* <CourseLangDropdown languageHandler={languageHandler}/> */}
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Catalogue</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={course.course_catalogue ? course.course_catalogue : ""}
                                            label="Catalogue"
                                            onChange={handleChangeCourseCatalogue}
                                            >
                                                {catalogueData.map((item)=>(
                                                    <MenuItem key={item.catalogue_id} value={item.catalogue_id}>{item.catalogue_name} {langDict[item.language]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Add Course
                                        </Button>
                                    </Box>
                                </Box>
                            </Container>
                        </ThemeProvider>

                    </Box>
                </Modal>

            </div>

            <div className="course-catalogue-container">

                <div className="course-catalogue-options">

                {/* select catalogue dropdown */}
                    <Box sx={{ width: "min-content" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Catalogue</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catalogue ? catalogue : ""}
                            label="Catalogue"
                            onChange={handleChange}
                            >
                                {catalogueData.map((item)=>(
                                    <MenuItem key={item.catalogue_id} value={item.catalogue_id}>{item.catalogue_name} {langDict[item.language]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>


                    <CreateCatalogueModal setReload={setReload}/>

                    <div className="add-course-button-container">

                        <button
                            onClick={handleOpenCourseModal}
                            style={{
                                display: "flex",
                                padding: "5px 20px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                background: "var(--primary-01, #1167E5)",
                                height: "55px",
                            }}
                        >Add Course</button>

                    </div>
                    
                    {/* Edit Catalogue */}
                    <EditCatalogue selectedCatalogue={catalogue} catalogueData={catalogueData} setCatalogueReload={setCatalogueReload} setReload={setReload}/>

                </div>

            </div>

            <div className="course-right-panel-body">

                <CourseAccordian courseData={catalogueCourseData} setCourseData={setCatalogueCourseData} catalogueData={catalogueData} catalogue={catalogue} setReload={setCourseReload} langDict={langDict}/>

            </div>

        </div>

    </div>
  )
}
