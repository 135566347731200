import React from 'react'
import FolderIcon from "../../Media/folder.svg"
import "./FolderList.css"
import { useState, useEffect } from 'react'
import DeleteIcon from "../../Media/delete.svg"
import RenameIcon from "../../Media/Rewrite.svg"
import { v4 as uuid } from 'uuid'
import OutsideClickHandler from 'react-outside-click-handler'
import RenameBlueIcon from "../../Media/renameBlue.svg"
import CloseIcon from "../../Media/cancel.svg"
import RedDeleteIcon from '../../Media/deleteRed.svg'
import RightArrow from '../../Media/clicktogotright.svg'

const FolderList = () => {
    // Please add state to the func setEachStateToDefault except the leftpointer and rightpointer
    
    // Folders will be fetched using API, this is a sample for use in development
    const [folderNames, setfolderNames] = useState([])
    const [startingfolderNames, setstartingfolderNames] = useState(["Rahul", "Manoj", "Harshad", "Jetha", "Ranveer", "Virat", "Priyanka", "Eminem", "Einstein", "Newton", "Faraday"])
    
    
    
    const [renameActionError, setrenameActionError] = useState("");
    const [renameActionErrorColor, setrenameActionErrorColor] = useState("red");
    
    // Left and Right points to be used in slice
    
    const [leftPointer, setleftPointer] = useState(0)
    const [rightPointer, setrightPointer] = useState(7)


    // This useEffect will hook will be used to adjust the number of folder that should be displayed according to the device. 
    useEffect(() => {
        
        const DisplayTheseFolders = () => {
            if(rightPointer > startingfolderNames.length){
                return;
            }
            setfolderNames(startingfolderNames.slice(leftPointer, rightPointer));
        }
        DisplayTheseFolders();
    
    
    }, [rightPointer])

    // Function to increment pointers
    const incrementPointers = () => {
        setleftPointer( Number.parseInt(leftPointer) + 1 );
        setrightPointer(Number.parseInt(rightPointer) + 1)

    }

    // This func will set every state to default , when outside click is performed.
    const setEachStateToDefault = () => {
        
        setrenameActionError("");
        setrenameActionErrorColor("red");


    }



    // This func takes an index and use it to display a dialog box (rename,remove) menu with Id.
    const displayFolderDialogMenu = (index) => {
        let selectedFolder = document.getElementById(`eclipse-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'block'
        }
    }
    const hideFolderDialogMenu = (index) => {
        let selectedFolder = document.getElementById(`eclipse-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'none'
        }
    }
    
    
    // These funcs takes an index and use it to display / hide dialog box to rename folder
    const displayRenameDialogMenu = (index) => {
        let selectedFolder = document.getElementById(`rename-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'block';
            hideFolderDialogMenu(index);
        }
    }
    const hideRenameDialogMenu = (index) => {
        let selectedFolder = document.getElementById(`rename-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'none';
        }
    }
    
    // These funcs takes an index and use it to display / hide dialog box to delete folder
    const displayDeleteDialogMenu = (index) => {
        let selectedFolder = document.getElementById(`delete-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'block';
            hideFolderDialogMenu(index);
        }
    }
    const hideDeleteDialogMenu = (index) => {
        hideFolderDialogMenu(index);
        let selectedFolder = document.getElementById(`delete-options-${index}`);
        if (selectedFolder) {
            selectedFolder.style.display = 'none';
        }
    }
    
    
    
    //  API Calls are to be written in this section 
    
    
    
    
    
    
    
    
    
    
    return (
        <div className="folder-list">
            <div className='folder-directory' >

            {
                folderNames.map((folder, index) => {
                    return <>
                     <div className="folder-packet" key= {uuid()}  >
                        <div className="folder-eclipse" onClick={(e) => { displayFolderDialogMenu(index) }}   >
                            <span className="eclipse-dot"></span>
                            <span className="eclipse-dot"></span>
                            <span className="eclipse-dot"></span>
                            <OutsideClickHandler
                                onOutsideClick={(e) => { hideFolderDialogMenu(index) ; hideDeleteDialogMenu(index) }}
                            >

                                <div className="folder-eclipse-options folder-list-close-onclick-outside" id={'eclipse-options-' + index} >

                                    <p className="folder-eclipse-action folder-list-close-onclick-outside" onClick={(e) => { hideFolderDialogMenu(index); displayRenameDialogMenu(index) }} >
                                        <img src={RenameIcon} alt="Rename Folder" />
                                        Rename
                                    </p>
                                    <p className="folder-eclipse-action " onClick={(e) => { hideFolderDialogMenu(index); displayDeleteDialogMenu(index) }}>
                                        <img src={DeleteIcon} alt="Delete Folder" />
                                        Remove
                                    </p>
                                </div>
                            </OutsideClickHandler>
                            <OutsideClickHandler onOutsideClick={(e) => { hideRenameDialogMenu(index) }} >

                                <div className="folder-rename-options" id={"rename-options-" + index} >
                                    <div className="rename-folder-options-menu">

                                        <div className="folder-rename-crossIcon">
                                            <img src={CloseIcon} alt='close dialog box' width={"15px"} height={"15px"} onClick={(e) => { hideRenameDialogMenu(index); hideDeleteDialogMenu(index) }} />
                                        </div>
                                        <div className="folder-rename-header">

                                            <img src={RenameBlueIcon} alt="Rename Icon" />
                                            <p className="folder-rename-head">Edit Folder Name</p>

                                        </div>
                                        <input type="text"
                                            placeholder='New Folder'
                                            className="folder-rename-inputField" />
                                        <span className="rename-action-error-span" style={{ color: renameActionErrorColor }} > {renameActionError} </span>
                                        <div className="rename-options-actions">
                                            <div className="rename-options-buttons" onClick={(e) => { hideRenameDialogMenu(index); hideFolderDialogMenu(index) }} >Cancel</div>
                                            <div className="rename-options-buttons rename-options-done-button ">Done</div>
                                        </div>
                                    </div>

                                </div>
                            </OutsideClickHandler>

                            <div className="folder-delete-options" id={'delete-options-' + index}>
                                <div className="rename-folder-options-menu">

                                    <div className="folder-rename-crossIcon">
                                        <img src={CloseIcon} alt='close dialog box' width={"15px"} height={"15px"} onClick={(e) => { hideDeleteDialogMenu(index) }} />
                                    </div>
                                    <div className="folder-rename-header">

                                        <img src={RedDeleteIcon} alt="Rename Icon" />
                                        <p className="folder-rename-head">Do you want to delete?</p>

                                    </div>
                                 
                                    <span className="rename-action-error-span" style={{ color: renameActionErrorColor }} > {renameActionError} </span>
                                    <div className="rename-options-actions">
                                        <div className="rename-options-buttons" onClick={(e) => { hideDeleteDialogMenu(index) }} >Cancel</div>
                                        <div className="rename-options-buttons rename-options-done-button ">Done</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='folder-main'
                            key={uuid()} >
                            <img src={FolderIcon} alt="Folder Icon" className="folder-Icon" />
                            <p className="folder-name"> {folder} </p>
                        </div>

                    </div>

            </>
                })
            }

            </div>
            <div className="folderlist-right-button" onClick={(e)=> incrementPointers()}> 
            <img src= {RightArrow} width={"40px"}  alt="Show More Folders" />
             </div>
        </div>
    )
}

export default FolderList