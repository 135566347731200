import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../Axios";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export const EditCatalogue = ({
  selectedCatalogue,
  catalogueData,
  setCatalogueReload,
  setReload,
}) => {
  const [catalogue, setCatalogue] = useState({
    catalogue_id: null,
    catalogue_name: "",
    language: "",
  });

  const [allLanguges, setAllLanguages] = useState([]);

  const [editCatalogueResMessage, setEditCatalogueResMessage] = useState("");
  const [showEditCatalogueResMessage, setShowEditCatalogueResMessage] =
    useState(false);

  const [openDeleteCatalogueModal, setOpenDeleteCatalogueModal] =
    useState(false);

  const handleOpenDeleteCatalogueModal = () => {
    setOpenDeleteCatalogueModal(true);
  };

  const handleCloseDeleteCatalogueModal = () => {
    setOpenDeleteCatalogueModal(false);
  };

  const [editCatalogueModal, setEditCatalogueModal] = useState(false);
  const handleOpenEditCatalogueModal = () => {
    setEditCatalogueModal(true);
  };

  const handleCloseEditCatalogueModal = () => {
    setShowEditCatalogueResMessage(false);
    setEditCatalogueModal(false);
  };

  const getAllLanguages = async () => {
    try {
      const res = await axiosInstance.get("/api/language/get_all_languages");
      setAllLanguages(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const catalogue_nameHandler = (event) => {
    setShowEditCatalogueResMessage(false);
    setCatalogue((prevState) => {
      return {
        ...prevState,
        catalogue_name: event.target.value,
      };
    });
  };

  const handleLanguageChange = (event) => {
    setShowEditCatalogueResMessage(false);
    setCatalogue((prevState) => {
      return {
        ...prevState,
        language: event.target.value,
      };
    });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (
      catalogue.catalogue_name === "" ||
      catalogue.catalogue_name.trim() === ""
    ) {
      setEditCatalogueResMessage("Catalogue Name cannot be empty");
      setShowEditCatalogueResMessage(true);
      return;
    }

    let formData = new FormData();
    formData.append("catalogue_id", catalogue.catalogue_id);
    formData.append("catalogue_name", catalogue.catalogue_name);
    formData.append("language", catalogue.language);

    try {
      const res = await axiosInstance.post(
        "/api/catalogue/update_catalogue",
        formData
      );
      console.log("Update Catalogue Response", res);
      setEditCatalogueResMessage("Catalogue Edited Successfully");
      setShowEditCatalogueResMessage(true);
      setCatalogueReload((prevState) => !prevState);

      setCatalogue({
        catalogue_id: null,
        catalogue_name: "",
        language: "",
      });

      setTimeout(() => {
        handleCloseEditCatalogueModal();
        setShowEditCatalogueResMessage(false);
        setEditCatalogueResMessage("");
      }, 2000);
    } catch (err) {
      setEditCatalogueResMessage("Error Editing Catalogue");
      setShowEditCatalogueResMessage(true);
      console.error(err.message);
    }
  };

  const handleDeleteCatalogue = async () => {
    let formData = new FormData();
    formData.append("catalogue_id", catalogue.catalogue_id);

    try {
      const res = await axiosInstance.post(
        "/api/catalogue/delete_catalogue",
        formData
      );
      console.log("Delete Catalogue Response", res);
      setEditCatalogueResMessage("Catalogue Deleted Successfully");
      setShowEditCatalogueResMessage(true);
      setReload(true);

      setCatalogue({
        catalogue_id: null,
        catalogue_name: "",
        language: "",
      });

      setTimeout(() => {
        handleCloseEditCatalogueModal();
        setShowEditCatalogueResMessage(false);
        setEditCatalogueResMessage("");
      }, 2000);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getAllLanguages();
  }, []);

  return (
    <div className="edit-catalogue-container">
      <button
        onClick={() => {
          setCatalogue({
            catalogue_id: selectedCatalogue,
            catalogue_name: catalogueData.find(
              (item) => item.catalogue_id === selectedCatalogue
            ).catalogue_name,
            language: catalogueData.find(
              (item) => item.catalogue_id === selectedCatalogue
            ).language,
          });
          handleOpenEditCatalogueModal();
        }}
        style={{
          display: "flex",
          padding: "5px 20px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          border: "none",
          borderRadius: "5px",
          color: "white",
          background: "var(--primary-01, #1167E5)",
          height: "55px",
        }}
      >
        Catalogue Options
      </button>

      <Modal
        open={editCatalogueModal}
        onClose={handleCloseEditCatalogueModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography component="h1" variant="h5">
            Edit Catalogue
          </Typography>

          {editCatalogueResMessage === "Catalogue Edited Successfully" ||
          "Catalogue Deleted Successfully" ? (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: showEditCatalogueResMessage ? "block" : "none",
                color: "green",
              }}
            >
              {editCatalogueResMessage}
            </Typography>
          ) : (
            <Typography
              component="span"
              variant="body2"
              style={{
                display: showEditCatalogueResMessage ? "block" : "none",
                color: "red",
              }}
            >
              {editCatalogueResMessage}
            </Typography>
          )}

          <Box
            component="form"
            onSubmit={handleEditSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="catalogue_name"
              label="Catalogue Name"
              name="catalogue_name"
              autoFocus
              onChange={catalogue_nameHandler}
              value={catalogue.catalogue_name}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={catalogue.language ? catalogue.language : ""}
                label="Catalogue"
                onChange={handleLanguageChange}
              >
                {allLanguges.map((item) => (
                  <MenuItem key={item.language_id} value={item.language_code}>
                    {item.language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Edit Catalogue
            </Button>

            <Typography variant="h6" style={{ textAlign: "center" }}>
              OR
            </Typography>

            <Button
              onClick={handleOpenDeleteCatalogueModal}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Delete Catalogue
            </Button>

            <Modal
              open={openDeleteCatalogueModal}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onClose={handleCloseDeleteCatalogueModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  sx={{ fontSize: "16px" }}
                  component="h2"
                >
                  Are you sure you want to delete this Catalogue?
                </Typography>
                <div
                  className="delete-modal-btn-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="delete-ppt-modal-btn-delete"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => {
                      handleDeleteCatalogue();
                      handleCloseDeleteCatalogueModal();
                    }}
                    color="error"
                  >
                    Delete
                  </Button>
                  <Button
                    className="delete-ppt-modal-btn-cancel"
                    sx={{ fontWeight: "bold" }}
                    onClick={handleCloseDeleteCatalogueModal}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
