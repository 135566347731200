import React from 'react'
import MyPPTIcon from '../Media/MyPPT.svg'

const MyPPTLabel = () => {
    return (
        <div className="home-right-panel-header-myppt">
            <img src={MyPPTIcon} alt='My PPt Icon' />
            <p>My Content</p>
        </div>

    )
}

export default MyPPTLabel