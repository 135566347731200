import React, { useEffect, useState } from 'react'
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector'
import "./Snackbar.css"
import { useDispatch } from 'react-redux'

const Snackbar = () => {
    const dispatch = useDispatch();

    let ToShowSnackbar = useSelector((state)=> state.snackbarModal.displayOn);
    const MessageOnSnackbar = useSelector((state)=> state.snackbarModal.message);
    const SnackbarStatus = useSelector((state)=> state.snackbarModal.IsSuccessful);


    const closeSnackbar = () => {
        dispatch({type: "snackbarClose"});
     }
    


    


  return (
    ToShowSnackbar ? <> 

        <div className="snackbar-main"  style={SnackbarStatus?{backgroundColor: "rgb(56, 142, 60)"}:{backgroundColor: "#FF1C1C"}}  >

            <div className="snackbar-content"   >
                {MessageOnSnackbar}
            </div>
            <div className="closeSnackBar" onClick={(e)=> {closeSnackbar()}} >X</div>
        </div>
        
     </>:
     <>
     </>


  )
}

export default Snackbar