import React, { useState } from "react";
import HomeContext from "./HomeContext";

export const HomeState = (props) => {
  const [homeState, setHomeState] = useState({
    catalogue_id: "",
    course_id: "",
    chapter_id: "",
    chapters: [],
    type: 0,
  });

  return (
    <HomeContext.Provider value={{ homeState, setHomeState }}>
      {props.children}
    </HomeContext.Provider>
  );
};
