import React, { useEffect, useState } from "react";
import axiosInstance from "../Axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import "./MoveVideoModal.css";
import { toast } from "sonner";
import axios from "axios";
import Cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const MoveVideoModal = ({ id, type }) => {
  const [catalogues, setCatalogues] = useState();
  const [courses, setCourses] = useState();
  const [chapters, setChapters] = useState();
  const [langDict, setLangDict] = useState();

  const [currentCatalogue, setCurrentCatalogue] = useState();
  const [currentCourse, setCurrentCourse] = useState();
  const [currentChapter, setCurrentChapter] = useState();

  // modal logic
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getLangDict = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/language/get_language_data_with_language_code_as_key"
      );
      setLangDict(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllCatalogues = async () => {
    try {
      const res = await axiosInstance.get("/api/catalogue/get_all_catalogue");
      setCatalogues(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllCourses = async (catalogue) => {
    try {
      const res = await axiosInstance.get(
        `/api/course/get_all_course?catalogue_id=${catalogue}`
      );
      setCourses(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getChapters = async (course) => {
    try {
      const res = await axiosInstance.get(
        `/api/chapter/get_all_chapter?course_id=${course}`
      );
      setChapters(res.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const catalogueChangeHandler = (e) => {
    setCurrentCatalogue(e.target.value);
    getAllCourses(e.target.value);
  };

  const courseChangeHandler = (e) => {
    setCurrentCourse(e.target.value);
    getChapters(e.target.value);
  };

  const chapterChangeHandler = (e) => {
    setCurrentChapter(e.target.value);
  };

  const moveVideoHandler = async (e) => {
    e.preventDefault();
    if (type === "video") {
      try {
        if (!currentCatalogue || !currentCourse || !currentChapter) {
          toast.error("Please select all fields");
          return;
        }
        await axios.post(
          "https://translation.avetilearning.com/api/videos/move_video",
          {
            chapter_id: currentChapter,
            video_id: id,
          },
          {
            headers: {
              "auth-token": Cookies.get("Token") ? Cookies.get("Token") : null,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Video moved successfully");
        setOpenModal(false);
      } catch (err) {
        alert(err.message);
      }
    } else {
      try {
        if (!currentCatalogue || !currentCourse || !currentChapter) {
          toast.error("Please select all fields");
          return;
        }
        await axiosInstance.post(
          "api/ppt/move_ppt",
          {
            chapter_id: currentChapter,
            ppt_id: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("PPT moved successfully");
        setOpenModal(false);
      } catch (err) {
        alert(err.message);
      }
    }
  };

  useEffect(() => {
    getAllCatalogues();
    getLangDict();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        title={type === "video" ? "Move Video" : "Move PPT"}
        onClick={handleOpenModal}
      >
        <MoveUpIcon />
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ marginBottom: "10px" }}
            id="modal-modal-title"
            sx={{ fontSize: "16px" }}
            component="h2"
          >
            {type === "video" ? "Move Video" : "Move PPT"}
          </Typography>
          <form className="move-form-container">
            <select
              className="move-form-select-field"
              name="catalogue"
              id="catalogue"
              onChange={catalogueChangeHandler}
            >
              <option value="">Select Catalogue</option>
              {catalogues &&
                langDict &&
                catalogues.map((catalogue) => {
                  return (
                    <option value={catalogue.catalogue_id}>
                      {catalogue.catalogue_name} {langDict[catalogue.language]}
                    </option>
                  );
                })}
            </select>
            <select
              className="move-form-select-field"
              name="course"
              id="course"
              onChange={courseChangeHandler}
            >
              {!currentCatalogue && (
                <option className="option-disabled" value="">
                  Select Catalogue first
                </option>
              )}
              {currentCatalogue && <option value="">Select Course</option>}
              {courses &&
                courses.map((course) => {
                  return (
                    <option value={course.course_id}>
                      {course.course_name}
                    </option>
                  );
                })}
            </select>

            <select
              className="move-form-select-field"
              name="chapter"
              id="chapter"
              onChange={chapterChangeHandler}
            >
              {!currentCourse && (
                <option className="option-disabled" value="">
                  Select Course first
                </option>
              )}
              {currentCourse && <option value="">Select Chapter</option>}
              {chapters &&
                chapters.map((chapter) => {
                  return (
                    <option value={chapter.chapter_id}>{chapter.name}</option>
                  );
                })}
            </select>
            <button
              className="move-video-btn"
              onClick={(e) => moveVideoHandler(e)}
            >
              Move
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
