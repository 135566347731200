import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import "./ReactPlayerSkeleton.css"

export const ReactPlayerSkeleton = () => {
  return (
    <div className="react-player-skeleton-container">
        <Skeleton variant="text" sx={{ fontSize: '6rem', width: "900px" }} />
        <Skeleton variant="rounded" width={899} height={500} />
    </div>
  )
}
