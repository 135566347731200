import Cookies from "js-cookie";
import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

const PrivateLayout = () => {
  const token = Cookies.get("Token");
  if (!token) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateLayout;
