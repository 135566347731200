import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = false;

const axiosInstance = axios.create({
  // baseURL: 'http://aveti.ankitrajmahapatra.co/',
  // baseURL: 'http://192.168.29.190:5000/',
  // baseURL: 'http://35.78.201.117/',
  baseURL: "https://translation.avetilearning.com",
  // baseURL: 'http://127.0.0.1:5000/',

  headers: {
    // 'Content-Type':  'application/json',
    "Content-Type": "multipart/form-data",
    "auth-token": Cookies.get("Token") ? Cookies.get("Token") : null,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get("Token");
  if (token) {
    config.headers["auth-token"] = token;
  } else {
    delete config.headers["auth-token"];
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.data.message === "Missing auth token" ||
      error.response.data.message === "Invalid auth token"
    ) {
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
