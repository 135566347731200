import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DescriptionIcon from "@mui/icons-material/Description";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axiosInstance from "../Axios";
import DragNDrop from "../Home/DragNDrop";
import { useNavigate } from "react-router-dom";
import { TranslateModal } from "../TranslateModal/TranslateModal";
import { AssignUserModal } from "../AssignUserModal/AssignUserModal";
import PPTStatusContext from "../../Context/PPTStatusContext/PPTStatusContext";
import { PPTStatusCompo } from "../PPTStatusCompo/PPTStatusCompo";
import DeletePPTModal from "../DeletePPTModal/DeletePPTModal";
import exportIcon from "../../Media/clipboard-export-svgrepo-com.svg";
import DeleteVideoModal from "../DeleteVideoModal/DeleteVideoModal";
import Cookies from "js-cookie";
import DownloadIcon from "@mui/icons-material/Download";
import FilterIcon from "@mui/icons-material/Filter";
import { AssignUserVideoModal } from "../AssignUserVideoModal/AssignUserVideoModal";
import PPTLogContext from "../../Context/PPTLogContext/PPTLogContext";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { EditVideoTitle } from "./EditVideoTitle";

import AudioFileIcon from "@mui/icons-material/AudioFile";
import { EditPPT } from "./EditPPT/EditPPT";
import "./ChapterPPT.css";
import { UploadVideoForm } from "../UploadVideoForm/UploadVideoForm";
import { MoveVideoModal } from "../MoveVideoModal/MoveVideoModal";
import { Backdrop } from "@mui/material";
import HomeContext from "../../Context/HomeContext/HomeContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ChapterPPT = ({
  allCatalogues,
  allCourses,
  chapters,
  id,
  refreshChapterLoader,
}) => {
  const PPTStatusCtx = useContext(PPTStatusContext);
  const HomeCtx = useContext(HomeContext);
  const navigate = useNavigate();
  const [chapterPPT, setChapterPPT] = useState([]);
  const [chapterVideo, setChapterVideo] = useState([]);

  const userType = parseInt(Cookies.get("user_type_id"));

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openTranslateModal, setOpenTranslateModal] = useState(false);

  // screen loader
  const [showScreenLoader, setShowScreenLoader] = useState(false);
  const [screenLoaderText, setScreenLoaderText] = useState("Loading...");

  // upload tab
  const [uploadTab, setUploadTab] = useState("1");

  // Translate Modal starts here
  const [allTranslationalModels, setAllTranslationalModels] = useState([]);

  const [allEngines, setAllEngines] = useState([]);

  const [langCode, setLangCode] = useState({});
  const [langDict, setLangDict] = useState({});

  const getAllTranslationalModels = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/miscellaneous/get_all_translation_services"
      );
      setAllTranslationalModels(res.data.services);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllLanguages = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/language/get_language_data_with_language_code_as_key"
      );
      const res2 = await axiosInstance.get(
        "/api/language/get_language_data_with_language_as_key"
      );
      setLangCode(res.data.data);
      setLangDict(res2.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllEngines = async () => {
    try {
      const res = await axiosInstance.get("/api/miscellaneous/get_all_engines");
      setAllEngines(res.data.engines);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllTranslationalModels();
    getAllLanguages();
    getAllEngines();
  }, []);

  // ends here

  const getChapterPPT = async () => {
    try {
      setLoading(true);
      const res_ppt = await axiosInstance.get(
        `/api/admin/get_all_ppts_by_chapter_id?chapter_id=${id}`
      );
      const res_video = await axiosInstance.get(
        `/api/admin/get_all_videos_by_chapter_id?chapter_id=${id}`
      );
      setChapterPPT(res_ppt.data.data);
      setChapterVideo(res_video.data.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deletePPT = async (ppt_id) => {
    try {
      const res = await axiosInstance.post(
        "/api/ppt/delete_ppt",
        { ppt_id: ppt_id },
        { headers: { "Content-Type": "application/json" } }
      );
      setReload(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteVideo = async (video_id) => {
    try {
      const res = await axiosInstance.post(
        "/api/videos/delete_video",
        { video_id: video_id },
        { headers: { "Content-Type": "application/json" } }
      );
      setReload(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const exportComments = async (ppt_id, ppt_name) => {
    try {
      setShowScreenLoader(true);
      setScreenLoaderText("Exporting Comments...");
      const res = await axiosInstance.get(
        `api/admin/export_ppt_comments?ppt_id=${ppt_id}`,
        {
          responseType: "blob",
        }
      );

      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      const filename = ppt_name + " " + formattedTime + ".xlsx";

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);

      a.download = filename;

      a.click();
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowScreenLoader(false);
      setScreenLoaderText("Loading...");
    }
  };

  const exportFeedback = async (video_id, video_name) => {
    try {
      setShowScreenLoader(true);
      setScreenLoaderText("Exporting Feedback...");
      const res = await axiosInstance.get(
        `api/admin/export_video_reviews?video_id=${video_id}`,
        {
          responseType: "blob",
        }
      );

      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      const filename = video_name + " " + formattedTime + ".xlsx";

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);

      a.download = filename;

      a.click();
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowScreenLoader(false);
      setScreenLoaderText("Loading...");
    }
  };

  const exportSlideImages = async (ppt_id, ppt_name) => {
    try {
      setShowScreenLoader(true);
      setScreenLoaderText("Exporting Images...");
      const res = await axiosInstance.get(
        `api/ppt/export_ppt_slide_images?ppt_id=${ppt_id}`,
        {
          responseType: "blob",
        }
      );

      const filename = ppt_name + "_images.zip";
      const blob = new Blob([res.data], { type: "application/zip" });

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);

      a.download = filename;

      a.click();
    } catch (err) {
      alert(err.message);
    } finally {
      setShowScreenLoader(false);
      setScreenLoaderText("Loading...");
    }
  };

  const downloadScript = async (ppt_id, ppt_name) => {
    try {
      setShowScreenLoader(true);
      setScreenLoaderText("Exporting Script...");
      const res = await axiosInstance.get(
        `api/ppt/export_ppt_slide_notes?ppt_id=${ppt_id}`,
        {
          responseType: "blob",
        }
      );

      const filename = ppt_name + "_script.zip";
      const blob = new Blob([res.data], { type: "application/zip" });

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);

      a.download = filename;

      a.click();
    } catch (err) {
      alert(err.message);
    } finally {
      setShowScreenLoader(false);
      setScreenLoaderText("Loading...");
    }
  };

  const pptDownload = (url) => {
    return window.open(url);
  };

  const videoDownload = async (videoUrl, name) => {
    return window.open(videoUrl);
  };

  const handleUploadTabChange = (event, newValue) => {
    setUploadTab(newValue);
  };

  const handleAudioExport = async (ppt_id, ppt_name) => {
    try {
      setShowScreenLoader(true);
      setScreenLoaderText("Exporting Audio...");
      const res = await axiosInstance.get(
        `/api/ppt/export_ppt_slide_audio?ppt_id=${ppt_id}`,
        {
          responseType: "blob",
        }
      );

      const filename = ppt_name + `_audio_${ppt_id}.zip`;
      const blob = new Blob([res.data], { type: "application/zip" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    } catch (err) {
      alert(err.message);
    } finally {
      setShowScreenLoader(false);
      setScreenLoaderText("Loading...");
    }
  };

  useEffect(() => {
    getChapterPPT();
    setReload(false);
  }, [id, reload, PPTStatusCtx.pptStatusState.ppt_id, refreshChapterLoader]);

  return (
    <>
      {loading ? (
        <div style={{ marginLeft: "10px" }}>
          <CircularProgress />
          <Typography sx={{ fontSize: "16px" }}>{screenLoaderText}</Typography>
        </div>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: "700px" }} aria-label="customized table">
              {chapterPPT.length === 0 && chapterVideo.length === 0 ? (
                <Typography sx={{ fontSize: "16px" }}>No Data</Typography>
              ) : (
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Type</StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                    {userType === 1 ? (
                      <StyledTableCell align="center">
                        Assign User
                      </StyledTableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
              )}
              <TableBody
                sx={{
                  maxHeight: "500px",
                }}
              >
                {HomeCtx.homeState.type === 0 &&
                  chapterPPT.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" scope="row">
                        <Typography sx={{ fontWeight: "bold" }}>PPT</Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => navigate(`/pptpreview/${row.ppt_id}`)}
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        align="left"
                      >
                        {row.ppt_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <PPTStatusCompo
                          ppt_id={row.ppt_id}
                          refresh={refreshChapterLoader}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="card-icons-tray-container">
                          {userType === 1 ? (
                            <DeletePPTModal
                              deletePPT={deletePPT}
                              ppt_id={row.ppt_id}
                            />
                          ) : null}

                          {userType === 1 ? (
                            <div
                              className="icon-container"
                              title="Translate PPT"
                            >
                              <TranslateModal
                                ppt_name={row.ppt_name}
                                ppt_lang={row.ppt_lang}
                                chapter_id={id}
                                ppt_id={row.ppt_id}
                                openTranslateModal={openTranslateModal}
                                allTranslationalModels={allTranslationalModels}
                                allEngines={allEngines}
                                langCode={langCode}
                                langDict={langDict}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Comments"
                            >
                              <img
                                onClick={() =>
                                  exportComments(row.ppt_id, row.ppt_name)
                                }
                                src={exportIcon}
                                alt="export-img"
                                title="Export Comments"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Download PPT"
                            >
                              <svg
                                style={{ cursor: "pointer" }}
                                onClick={() => pptDownload(row.ppt_uri)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 1.5C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25V7.5H11.25V2.25C11.25 2.05109 11.329 1.86032 11.4697 1.71967C11.6103 1.57902 11.8011 1.5 12 1.5ZM11.25 7.5V13.19L9.53 11.47C9.38783 11.3375 9.19978 11.2654 9.00548 11.2688C8.81118 11.2723 8.62579 11.351 8.48838 11.4884C8.35097 11.6258 8.27225 11.8112 8.26882 12.0055C8.2654 12.1998 8.33752 12.3878 8.47 12.53L11.47 15.53C11.6106 15.6705 11.8012 15.7493 12 15.7493C12.1988 15.7493 12.3894 15.6705 12.53 15.53L15.53 12.53C15.6037 12.4613 15.6628 12.3785 15.7038 12.2865C15.7448 12.1945 15.7668 12.0952 15.7686 11.9945C15.7704 11.8938 15.7518 11.7938 15.7141 11.7004C15.6764 11.607 15.6203 11.5222 15.549 11.451C15.4778 11.3797 15.393 11.3236 15.2996 11.2859C15.2062 11.2482 15.1062 11.2296 15.0055 11.2314C14.9048 11.2332 14.8055 11.2552 14.7135 11.2962C14.6215 11.3372 14.5387 11.3963 14.47 11.47L12.75 13.19V7.5H16.5C17.2956 7.5 18.0587 7.81607 18.6213 8.37868C19.1839 8.94129 19.5 9.70435 19.5 10.5V19.5C19.5 20.2956 19.1839 21.0587 18.6213 21.6213C18.0587 22.1839 17.2956 22.5 16.5 22.5H7.5C6.70435 22.5 5.94129 22.1839 5.37868 21.6213C4.81607 21.0587 4.5 20.2956 4.5 19.5V10.5C4.5 9.70435 4.81607 8.94129 5.37868 8.37868C5.94129 7.81607 6.70435 7.5 7.5 7.5H11.25Z"
                                  fill="#4D4D4D"
                                />
                              </svg>
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Edit PPT"
                              style={{ cursor: "pointer" }}
                            >
                              <EditPPT
                                setReload={setReload}
                                ppt_id={row.ppt_id}
                                ppt_name={row.ppt_name}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Download Script"
                              style={{ cursor: "pointer" }}
                            >
                              <DescriptionIcon
                                onClick={() =>
                                  downloadScript(row.ppt_id, row.ppt_name)
                                }
                              />
                              {/* <EditPPT setReload={setReload} ppt_id={row.ppt_id} ppt_name={row.ppt_name}/> */}
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Images"
                              style={{ cursor: "pointer" }}
                            >
                              <FilterIcon
                                onClick={() =>
                                  exportSlideImages(row.ppt_id, row.ppt_name)
                                }
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Audio"
                              style={{ cursor: "pointer" }}
                            >
                              <AudioFileIcon
                                onClick={() =>
                                  handleAudioExport(row.ppt_id, row.ppt_name)
                                }
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <MoveVideoModal id={row.ppt_id} type="ppt" />
                          ) : null}
                        </div>
                      </StyledTableCell>
                      {userType === 1 ? (
                        <StyledTableCell align="center">
                          <AssignUserModal ppt_id={row.ppt_id} />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
                {HomeCtx.homeState.type === 1 &&
                  chapterPPT.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" scope="row">
                        <Typography sx={{ fontWeight: "bold" }}>PPT</Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => navigate(`/pptpreview/${row.ppt_id}`)}
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        align="left"
                      >
                        {row.ppt_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <PPTStatusCompo
                          ppt_id={row.ppt_id}
                          refresh={refreshChapterLoader}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="card-icons-tray-container">
                          {userType === 1 ? (
                            <DeletePPTModal
                              deletePPT={deletePPT}
                              ppt_id={row.ppt_id}
                            />
                          ) : null}

                          {userType === 1 ? (
                            <div
                              className="icon-container"
                              title="Translate PPT"
                            >
                              <TranslateModal
                                ppt_name={row.ppt_name}
                                ppt_lang={row.ppt_lang}
                                chapter_id={id}
                                ppt_id={row.ppt_id}
                                openTranslateModal={openTranslateModal}
                                allTranslationalModels={allTranslationalModels}
                                allEngines={allEngines}
                                langCode={langCode}
                                langDict={langDict}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Comments"
                            >
                              <img
                                onClick={() =>
                                  exportComments(row.ppt_id, row.ppt_name)
                                }
                                src={exportIcon}
                                alt="export-img"
                                title="Export Comments"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Download PPT"
                            >
                              <svg
                                style={{ cursor: "pointer" }}
                                onClick={() => pptDownload(row.ppt_uri)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 1.5C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25V7.5H11.25V2.25C11.25 2.05109 11.329 1.86032 11.4697 1.71967C11.6103 1.57902 11.8011 1.5 12 1.5ZM11.25 7.5V13.19L9.53 11.47C9.38783 11.3375 9.19978 11.2654 9.00548 11.2688C8.81118 11.2723 8.62579 11.351 8.48838 11.4884C8.35097 11.6258 8.27225 11.8112 8.26882 12.0055C8.2654 12.1998 8.33752 12.3878 8.47 12.53L11.47 15.53C11.6106 15.6705 11.8012 15.7493 12 15.7493C12.1988 15.7493 12.3894 15.6705 12.53 15.53L15.53 12.53C15.6037 12.4613 15.6628 12.3785 15.7038 12.2865C15.7448 12.1945 15.7668 12.0952 15.7686 11.9945C15.7704 11.8938 15.7518 11.7938 15.7141 11.7004C15.6764 11.607 15.6203 11.5222 15.549 11.451C15.4778 11.3797 15.393 11.3236 15.2996 11.2859C15.2062 11.2482 15.1062 11.2296 15.0055 11.2314C14.9048 11.2332 14.8055 11.2552 14.7135 11.2962C14.6215 11.3372 14.5387 11.3963 14.47 11.47L12.75 13.19V7.5H16.5C17.2956 7.5 18.0587 7.81607 18.6213 8.37868C19.1839 8.94129 19.5 9.70435 19.5 10.5V19.5C19.5 20.2956 19.1839 21.0587 18.6213 21.6213C18.0587 22.1839 17.2956 22.5 16.5 22.5H7.5C6.70435 22.5 5.94129 22.1839 5.37868 21.6213C4.81607 21.0587 4.5 20.2956 4.5 19.5V10.5C4.5 9.70435 4.81607 8.94129 5.37868 8.37868C5.94129 7.81607 6.70435 7.5 7.5 7.5H11.25Z"
                                  fill="#4D4D4D"
                                />
                              </svg>
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Edit PPT"
                              style={{ cursor: "pointer" }}
                            >
                              <EditPPT
                                setReload={setReload}
                                ppt_id={row.ppt_id}
                                ppt_name={row.ppt_name}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Download Script"
                              style={{ cursor: "pointer" }}
                            >
                              <DescriptionIcon
                                onClick={() =>
                                  downloadScript(row.ppt_id, row.ppt_name)
                                }
                              />
                              {/* <EditPPT setReload={setReload} ppt_id={row.ppt_id} ppt_name={row.ppt_name}/> */}
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Images"
                              style={{ cursor: "pointer" }}
                            >
                              <FilterIcon
                                onClick={() =>
                                  exportSlideImages(row.ppt_id, row.ppt_name)
                                }
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export PPT Audio"
                              style={{ cursor: "pointer" }}
                            >
                              <AudioFileIcon
                                onClick={() =>
                                  handleAudioExport(row.ppt_id, row.ppt_name)
                                }
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <MoveVideoModal id={row.ppt_id} type="ppt" />
                          ) : null}
                        </div>
                      </StyledTableCell>
                      {userType === 1 ? (
                        <StyledTableCell align="center">
                          <AssignUserModal ppt_id={row.ppt_id} />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
                {HomeCtx.homeState.type === 0 &&
                  chapterVideo.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell scope="row">
                        <Typography sx={{ fontWeight: "bold" }}>
                          VIDEO
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        align="left"
                        onClick={() =>
                          navigate(`/video/${row.video_id}?chap_id=${id}`)
                        }
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* <PPTStatusCompo ppt_id={row.ppt_id}/> */}
                        <div></div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="card-icons-tray-container">
                          {userType === 1 ? (
                            <DeleteVideoModal
                              deleteVideo={deleteVideo}
                              video_id={row.video_id}
                            />
                          ) : null}

                          <div className="icon-container" title="Play Video">
                            <svg
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/video/${row.video_id}?chap_id=${id}`)
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM16.274 11.017C16.4492 11.1145 16.5951 11.257 16.6967 11.4299C16.7983 11.6027 16.8519 11.7995 16.8519 12C16.8519 12.2005 16.7983 12.3973 16.6967 12.5701C16.5951 12.743 16.4492 12.8855 16.274 12.983L10.671 16.096C10.4998 16.191 10.3067 16.2397 10.1109 16.2373C9.91513 16.2348 9.72336 16.1813 9.55458 16.082C9.3858 15.9828 9.24586 15.8411 9.14859 15.6712C9.05133 15.5012 9.00011 15.3088 9 15.113V8.887C9 8.03 9.921 7.487 10.671 7.904L16.274 11.017Z"
                                fill="#1167E5"
                              />
                            </svg>
                          </div>

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export Video Feedback"
                            >
                              <img
                                onClick={() =>
                                  exportFeedback(row.video_id, row.name)
                                }
                                src={exportIcon}
                                alt="export-img"
                                title="Export Video Feedback"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Edit title"
                              style={{ cursor: "pointer" }}
                            >
                              <EditVideoTitle
                                setReload={setReload}
                                video_id={row.video_id}
                                video_name={row.name}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <DownloadIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                videoDownload(row.mp4_url, row.name);
                              }}
                            />
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <MoveVideoModal id={row.video_id} type="video" />
                          ) : null}
                        </div>
                      </StyledTableCell>
                      {userType === 1 ? (
                        <StyledTableCell align="center">
                          <AssignUserVideoModal video_id={row.video_id} />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
                {HomeCtx.homeState.type === 2 &&
                  chapterVideo.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell scope="row">
                        <Typography sx={{ fontWeight: "bold" }}>
                          VIDEO
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        align="left"
                        onClick={() =>
                          navigate(`/video/${row.video_id}?chap_id=${id}`)
                        }
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* <PPTStatusCompo ppt_id={row.ppt_id}/> */}
                        <div></div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="card-icons-tray-container">
                          {userType === 1 ? (
                            <DeleteVideoModal
                              deleteVideo={deleteVideo}
                              video_id={row.video_id}
                            />
                          ) : null}

                          <div className="icon-container" title="Play Video">
                            <svg
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/video/${row.video_id}?chap_id=${id}`)
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM16.274 11.017C16.4492 11.1145 16.5951 11.257 16.6967 11.4299C16.7983 11.6027 16.8519 11.7995 16.8519 12C16.8519 12.2005 16.7983 12.3973 16.6967 12.5701C16.5951 12.743 16.4492 12.8855 16.274 12.983L10.671 16.096C10.4998 16.191 10.3067 16.2397 10.1109 16.2373C9.91513 16.2348 9.72336 16.1813 9.55458 16.082C9.3858 15.9828 9.24586 15.8411 9.14859 15.6712C9.05133 15.5012 9.00011 15.3088 9 15.113V8.887C9 8.03 9.921 7.487 10.671 7.904L16.274 11.017Z"
                                fill="#1167E5"
                              />
                            </svg>
                          </div>

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Export Video Feedback"
                            >
                              <img
                                onClick={() =>
                                  exportFeedback(row.video_id, row.name)
                                }
                                src={exportIcon}
                                alt="export-img"
                                title="Export Video Feedback"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <div
                              className="icon-container"
                              title="Edit title"
                              style={{ cursor: "pointer" }}
                            >
                              <EditVideoTitle
                                setReload={setReload}
                                video_id={row.video_id}
                                video_name={row.name}
                              />
                            </div>
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <DownloadIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                videoDownload(row.mp4_url, row.name);
                              }}
                            />
                          ) : null}

                          {userType === 1 || userType === 2 ? (
                            <MoveVideoModal id={row.video_id} type="video" />
                          ) : null}
                        </div>
                      </StyledTableCell>
                      {userType === 1 ? (
                        <StyledTableCell align="center">
                          <AssignUserVideoModal video_id={row.video_id} />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <div className="add-new-item-btn" style={{ marginTop: "10px" }}>
        {userType === 1 ? (
          <button onClick={handleOpenModal} className="assign-user-btn">
            Add New Item
          </button>
        ) : null}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TabContext value={uploadTab}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="boxContainer"
              >
                <TabList onChange={handleUploadTabChange} className="tabList">
                  <Tab
                    style={{ color: uploadTab === "1" ? "white" : "black" }}
                    label="PPT"
                    value="1"
                    className={`tab ${uploadTab === "1" ? "active" : ""}`}
                  />
                  <Tab
                    style={{ color: uploadTab === "2" ? "white" : "black" }}
                    label="Video"
                    value="2"
                    className={`tab ${uploadTab === "2" ? "active" : ""}`}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" className="tabPanel">
                <DragNDrop
                  handleCloseModal={handleCloseModal}
                  chapter_id={id}
                />
              </TabPanel>
              <TabPanel value="2" className="tabPanel">
                <UploadVideoForm
                  handleCloseModal={handleCloseModal}
                  chapter_id={id}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            cursor: "not-allowed",
          }}
          open={showScreenLoader}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" />
            <Typography sx={{ fontSize: "16px" }}>
              {screenLoaderText}
            </Typography>
          </div>
        </Backdrop>
      </div>
    </>
  );
};
