import React, { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Typography } from "@mui/material";
import ReactPlayer from "react-player";
import "./PPTSlidesSidebar.css";
import { useNavigate } from "react-router-dom";
import { AddSlide } from "../../PPTPage/AddSlide/AddSlide";
import { DeleteSlideModal } from "../../DeleteSlideModal/DeleteSlideModal";
import Cookies from "js-cookie";

export const PPTSlidesSidebar = ({
  PPTData,
  PPTSlides,
  selectedSlide,
  setSelectedSlide,
  ppt_id,
  setUpdate,
  setSlide_id,
}) => {
  const navigate = useNavigate();
  const [user_type_id, setUser_type_id] = useState(Cookies.get("user_type_id"));

  const handleSlideClick = (slide_id) => {
    const slide = PPTSlides.find((slide) => slide.slide_id === slide_id);
    setSelectedSlide(slide);
  };

  return (
    <>
      <div className="ppt-slides-sidebar-container">
        <div className="ppt-slides-sidebar-header">
          <ArrowBackIosNewIcon
            onClick={() => navigate("/home")}
            sx={{ height: "20px", cursor: "pointer" }}
          />
          <Typography
            sx={{ fontSize: "16px" }}
            sx={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {PPTData.ppt_name}
          </Typography>
        </div>
        <div className="ppt-slides-sidebar-body">
          {PPTSlides.map((slide, index) =>
            slide.slide_type === "IMAGE" ? (
              <div
                className="slide-preview-img-container-wrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {(user_type_id == 1 || user_type_id == 2) && (
                  <AddSlide
                    index={index}
                    ppt_id={ppt_id}
                    PPTSlides={PPTSlides}
                    selectedSlide={selectedSlide}
                    setSelectedSlide={setSelectedSlide}
                    setUpdate={setUpdate}
                  />
                )}

                <div
                  className="slide-preview-img-container"
                  key={slide.slide_id}
                  onClick={() => handleSlideClick(slide.slide_id)}
                  style={{
                    position: "relative",
                    border:
                      selectedSlide && selectedSlide.slide_id === slide.slide_id
                        ? "3px solid #3F51B5"
                        : "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {(user_type_id == 1 || user_type_id == 2) && (
                    <DeleteSlideModal
                      ppt_id={ppt_id}
                      setSlide_id={setSlide_id}
                      setUpdate={setUpdate}
                      PPTSlides={PPTSlides}
                      slide={slide}
                      selectedSlide={selectedSlide}
                    />
                  )}
                  <img
                    className="slide-preview-img"
                    src={slide.slide_image_uri}
                    alt="ppt-slide"
                  />
                </div>
              </div>
            ) : (
              <div className="slide-preview-vid-container-wrapper">
                {(user_type_id == 1 || user_type_id == 2) && (
                  <AddSlide
                    index={index}
                    ppt_id={ppt_id}
                    PPTSlides={PPTSlides}
                    selectedSlide={selectedSlide}
                    setSelectedSlide={setSelectedSlide}
                    setUpdate={setUpdate}
                  />
                )}

                <div
                  className="slide-preview-vid-container"
                  key={slide.slide_id}
                  onClick={() => handleSlideClick(slide.slide_id)}
                  style={{
                    position: "relative",
                    border:
                      selectedSlide && selectedSlide.slide_id === slide.slide_id
                        ? "3px solid #3F51B5"
                        : "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {(user_type_id == 1 || user_type_id == 2) && (
                    <DeleteSlideModal
                      ppt_id={ppt_id}
                      setSlide_id={setSlide_id}
                      setUpdate={setUpdate}
                      PPTSlides={PPTSlides}
                      slide={slide}
                      selectedSlide={selectedSlide}
                    />
                  )}

                  <ReactPlayer
                    url={slide.slide_image_uri}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="500px"
                    height="200px"
                    className="slide-preview-img"
                    style={{ zIndex: "-1" }}
                  />
                </div>
              </div>
            )
          )}
          {/* {
                    PPTSlides.map((slide,index)=> (
                        <div className="slide-preview-img-container-wrapper"
                            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                            <AddSlide index={index} ppt_id={ppt_id} PPTSlides={PPTSlides} selectedSlide={selectedSlide} setSelectedSlide={setSelectedSlide} setUpdate={setUpdate}/>                        
                            <div
                                className="slide-preview-img-container"
                                key={slide.slide_id}
                                onClick={()=> handleSlideClick(slide.slide_id)}
                                style={{ position: "relative", border:  selectedSlide && selectedSlide.slide_id === slide.slide_id ? "3px solid #3F51B5" : "none", borderRadius: "5px", cursor: "pointer"}}
                            >
                                <DeleteSlideModal ppt_id={ppt_id} setSlide_id={setSlide_id} setUpdate={setUpdate} PPTSlides={PPTSlides} slide={slide} selectedSlide={selectedSlide}/>
                                <img className="slide-preview-img" src={slide.slide_image_uri} alt="ppt-slide" />
                            </div>
                        </div>
                    ))
                } */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="ppt-preview-add-slide-btn-container"
          >
            {(user_type_id == 1 || user_type_id == 2) && (
              <AddSlide
                index={PPTSlides.length}
                ppt_id={ppt_id}
                PPTSlides={PPTSlides}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                setUpdate={setUpdate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
