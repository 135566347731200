// Components Import
import SignIn from "./Components/SignIn/SignIn";
import Home from "./Components/Home/Home";
import ManageUsers from "./Components/Admin/ManageUsers/ManageUsers";
import Testing from "./Components/Testing";
import { UserPage } from "./Components/UserPage/UserPage";
import PPT from "./Components/Admin/PPT/PPT";
import { HashRouter } from "react-router-dom";

// Packages Import
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CoursePage } from "./Components/CoursePage/CoursePage";
import { HomeState } from "./Context/HomeContext/HomeState";
import { PPTStatusState } from "./Context/PPTStatusContext/PPTStatusState";
import { PPTPage } from "./Components/PPTPage/PPTPage";
import { ReactPlayerMPD } from "./Components/ReactPlayer/ReactPlayer";
import { PPTLogState } from "./Context/PPTLogContext/PPTLogState";
import { TrashBin } from "./Components/TrashBin/TrashBin";
import { PPTPreviewPage } from "./Components/PPTPreviewPage/PPTPreviewPage";
import PrivateLayout from "./Components/PrivateLayout";

function App() {
  console.log("Build Version:- 14.08.2024 - Updated hyperlink functionality.");

  return (
    <HashRouter basename="/">
      {/* <Router> */}
      <HomeState>
        <PPTLogState>
          <PPTStatusState>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route element={<PrivateLayout />}>
                <Route path="/home" element={<Home />} />
                {/* <Route path = "/ppt/:id" element= { <PPTPage />} /> */}
                <Route path="/video/:videoid" element={<ReactPlayerMPD />} />
                {/* <Route path = "/list" element= { <List />} /> */}
                <Route path="/courses" element={<CoursePage />} />
                <Route path="/users" element={<UserPage />} />
                {/* <Route path = "/home" element= { <DhruvHome/> } /> */}
                <Route path="/test" element={<Testing />} />
                <Route path="/admin/manageUser" element={<ManageUsers />} />
                {/* <Route path = "/admin/videos" element= { <Videos/> } /> */}
                <Route path="/admin/ppt" element={<PPT />} />
                <Route path="/admin/trashbin" element={<TrashBin />} />
                <Route path="/pptpreview/:id" element={<PPTPreviewPage />} />
              </Route>
            </Routes>
          </PPTStatusState>
        </PPTLogState>
      </HomeState>
      {/* </Router> */}
    </HashRouter>
  );
}

export default App;
