import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axiosInstance from '../Axios';
import AddChapterFab from '../AddChapterFab/AddChapterFab';
import { ChapterOptions } from './ChapterOptions/ChapterOptions';
import "./CourseDataTable.css"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CourseDataTable({id, catalogue}) {

  const [chapterData, setChapterData] = useState([]);
  const [chapterReload, setChapterReload] = useState(false);

  const getChapterData = async () => {

    try {
      
      const res = await axiosInstance.get(`/api/chapter/get_all_chapter?course_id=${id}`);
      setChapterData(res.data.data);

    } catch (err) {
      console.log(err.message);
    }

  }

  useEffect(()=>{
    getChapterData();

    if (chapterReload) {
      setChapterReload(false);
    }

  },[catalogue, id, chapterReload])

  return (
    <TableContainer sx={{width:"max-content"}}>
      {/* min width: 770 - removed */}
      <Table aria-label="customized table"> 
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{width: "15vw"}}>Chapter No.</StyledTableCell>
            <StyledTableCell align="right" sx={{width: "15vw", padding: "0px"}}>Chapter Name</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chapterData.map((row,index) => (
            <StyledTableRow key={index} className='styled-table-row'>
              <StyledTableCell component="th" scope="row">
                {index+1}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{padding: "0px"}}>{row["name"]}</StyledTableCell>
              <StyledTableCell align="left" sx={{padding: "0px"}}>
              <div className='chapter-options-container'>
                <ChapterOptions setChapterReload={setChapterReload} course_id={id} chapterData={chapterData} setChapterData={setChapterData} chapter_id={row["chapter_id"]} chapter_name={row["name"]}/>
              </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <div className="add-chapter-fab-container">

        <AddChapterFab courseId={id} setChapterReload={setChapterReload}/>

      </div>

    </TableContainer>
  );
}
