import React from "react";
import { useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../DeletePPTModal/DeletePPTModal.css";
import axiosInstance from "../../Axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

export const RestoreVideo = ({ video_id, setDeletedReload }) => {
  const [openRestoreVideoModal, setOpenRestoreVideoModal] = useState(false);
  const handleOpenRestoreVideoModal = () => setOpenRestoreVideoModal(true);
  const handleCloseRestoreVideoModal = () => setOpenRestoreVideoModal(false);

  const restoreVideo = async (video_id) => {
    try {
      let formData = new FormData();
      formData.append("video_id", video_id);
      const res = await axiosInstance.post(
        "/api/admin/restore_video",
        formData
      );
      console.log("restore video res", res.data.message);
      setDeletedReload(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div>
      <RestoreIcon
        sx={{ cursor: "pointer" }}
        onClick={handleOpenRestoreVideoModal}
      />

      <Modal
        open={openRestoreVideoModal}
        onClose={handleCloseRestoreVideoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ fontSize: "16px" }}
            component="h2"
          >
            Are you sure you want to restore this Video?
          </Typography>
          <div
            className="delete-modal-btn-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              className="delete-ppt-modal-btn-delete"
              sx={{ fontWeight: "bold" }}
              onClick={() => {
                restoreVideo(video_id);
                handleCloseRestoreVideoModal();
              }}
              color="error"
            >
              Restore
            </Button>
            <Button
              className="delete-ppt-modal-btn-cancel"
              sx={{ fontWeight: "bold" }}
              onClick={handleCloseRestoreVideoModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
