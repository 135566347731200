import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../Axios';
import { TextareaAutosize } from '@mui/base';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

export const VideoCommentSection = ({video_id, time_stamp, setIsPlaying}) => {
    const [user_id, setUser_id] = useState(Cookies.get("user_id"));
    const [videoComments, setVideoComments] = useState();
    const [comment, setComment] = useState("");

    const commentInputHandler = (event) => {
        setIsPlaying(false);
        setComment(event.target.value);
    }

    const getVideoComments = async () => {
        try {
            const res = await axiosInstance.get(`api/videos/get_video_review?video_id=${video_id}`);
            setVideoComments(res.data.data.filter((comment) => comment.user_id == user_id));
        } catch (err) {
            alert(err.message)
        }
    }

    const addCommentHandler = async () => {
        if (comment === "") {
            alert("Comment cannot be empty");
            return;
        }
        try {
            const res = await axiosInstance.post("/api/videos/add_video_review", {
                video_id,
                comment,
                time_stamp
            }, {headers: {"Content-Type": "application/json"}})
            setComment("");
            getVideoComments();
        } catch (err) {
            alert(err.message)
        }
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    useEffect(() => {
        getVideoComments();
    },[])

  return (
    <div>
        <div style={{display: "flex", gap: "10px"}} className="add-comment-ppt-review">
            <Avatar src="/broken-image.jpg" />

            <TextareaAutosize
                onChange={commentInputHandler}
                className="comment-textarea"
                placeholder="Add Comment..."
                value={comment}
                cols={88}
                maxRows={10}
            />
        </div>
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}} className="btn-container">

            <button className="add-comment-btn" onClick={addCommentHandler}>Add Comment at {formatTime(time_stamp)}</button>

        </div>

        <div className="comment-section-header">
            <Typography variant='h6'>Video Comments</Typography>
        </div>

        <div className="comment-section-body">
            {
                videoComments && videoComments.length > 0 ? (videoComments.map((comment) => {
                    return (
                        <div key={comment.id} className="user-comment">
                            <div className="comment-total">
                            {/* <Avatar sx={{ bgcolor: deepOrange[500] }}>{comment.username.slice(0,1).toUpperCase()}</Avatar> */}
                            <div className='comment-right'>
                                <Typography variant='body1' sx={{fontWeight: "bold"}}>Timestamp: {formatTime(comment.timestamp)}</Typography>
                                <Typography variant='body1'>{comment.video_comment}</Typography>
                            </div>
                            </div>
                            {/* <Avatar src="/broken-image.jpg" />
                            <div>
                                <p>{comment.video_comment}</p>
                                <p>{comment.timestamp}</p>
                            </div> */}
                        </div>
                    )
                })) : (<Typography variant='body1'>No Comments</Typography>)
            }
        </div>
    </div>
  )
}
