import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosInstance from "../Axios";
import { ChapterListAccordion } from "../ChapterListAccordion/ChapterListAccordion";
import HomeContext from "../../Context/HomeContext/HomeContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./VideoPPTList.css";
import { Home } from "@mui/icons-material";

export const VideoPPTList = () => {
  const HomeCtx = useContext(HomeContext);
  const [enableRefresh, setEnableRefresh] = useState(true);

  // language code as key
  const [langDict, setLangDict] = useState({});

  const [allCatalogues, setAllCatalogues] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [refreshChapterLoader, setRefreshChapterLoader] = useState(false);

  const [chapters, setChapters] = useState([]);

  const getLangDict = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/language/get_language_data_with_language_code_as_key"
      );
      setLangDict(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleChangeCatalogue = (event) => {
    HomeCtx.setHomeState((prevState) => {
      return {
        ...prevState,
        catalogue_id: event.target.value,
      };
    });
  };

  const handleChangeCourse = (event) => {
    HomeCtx.setHomeState((prevState) => {
      return {
        ...prevState,
        course_id: event.target.value,
      };
    });
  };

  const handleChapterFilter = (event) => {
    if (event.target.value === -1) {
      HomeCtx.setHomeState((prevState) => {
        return {
          ...prevState,
          chapter_id: event.target.value,
          chapters: chapters,
        };
      });
      return;
    }
    HomeCtx.setHomeState((prevState) => {
      return {
        ...prevState,
        chapter_id: event.target.value,
        chapters: chapters.filter(
          (chapter) => chapter.chapter_id === event.target.value
        ),
      };
    });
    console.log(HomeCtx.homeState.chapters);
  };

  const handleTypeFilter = (event) => {
    HomeCtx.setHomeState((prevState) => {
      return {
        ...prevState,
        type: event.target.value,
      };
    });
  };

  const getAllCatalogues = async () => {
    try {
      const res = await axiosInstance.get("/api/catalogue/get_all_catalogue");
      setAllCatalogues(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllCourses = async () => {
    if (!HomeCtx.homeState.catalogue_id) return;

    try {
      const res = await axiosInstance.get(
        `/api/course/get_all_course?catalogue_id=${HomeCtx.homeState.catalogue_id}`
      );
      setAllCourses(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getChapters = async () => {
    if (!HomeCtx.homeState.catalogue_id || !HomeCtx.homeState.course_id) return;

    try {
      const res = await axiosInstance.get(
        `/api/chapter/get_all_chapter?course_id=${HomeCtx.homeState.course_id}`
      );
      setChapters(res.data.data);
      HomeCtx.setHomeState((prevState) => {
        return {
          ...prevState,
          chapters: res.data.data,
        };
      });
      setLoadingData(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const refreshChapter = () => {
    setRefreshChapterLoader((prevState) => !prevState);
    setEnableRefresh(false);
    setTimeout(() => {
      setEnableRefresh(true);
    }, 5000);
  };

  useEffect(() => {
    getAllCatalogues();
    getAllCourses();
  }, []);

  useEffect(() => {
    setLoadingData(true);
    getAllCatalogues();
    getAllCourses();
    getChapters();
  }, [HomeCtx.homeState.catalogue_id, HomeCtx.homeState.course_id]);

  useEffect(() => {
    getLangDict();
  }, []);

  return (
    <>
      <div className="video-ppt-list-container">
        <div className="video-ppt-list-container-header">
          <div className="dropdown-container">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Catalogue</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={HomeCtx.homeState.catalogue_id}
                  label="Catalogue"
                  onChange={handleChangeCatalogue}
                >
                  {allCatalogues.map((item, index) => (
                    <MenuItem value={item.catalogue_id} key={index}>
                      {item.catalogue_name} {langDict[item.language]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="dropdown-container">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Course</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={HomeCtx.homeState.course_id}
                  label="Course"
                  onChange={handleChangeCourse}
                >
                  {HomeCtx.homeState.catalogue_id ? (
                    allCourses.map((course, idx) => (
                      <MenuItem value={course.course_id} key={idx}>
                        {course.course_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem style={{ color: "red" }} value={""}>
                      Please select catalogue
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="dropdown-container">
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filter by chapter
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={HomeCtx.homeState.chapter_id}
                  label="Filter by chapter"
                  onChange={handleChapterFilter}
                >
                  {HomeCtx.homeState.course_id && (
                    <MenuItem value={-1}>All Chapters</MenuItem>
                  )}
                  {HomeCtx.homeState.course_id ? (
                    chapters.map((chapter, idx) => (
                      <MenuItem value={chapter.chapter_id} key={idx}>
                        {chapter.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem style={{ color: "red" }} value={""}>
                      Please select a course
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="dropdown-container">
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filter by type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filter by chapter"
                  value={HomeCtx.homeState.type}
                  onChange={handleTypeFilter}
                >
                  <MenuItem value={0}>PPT & Video</MenuItem>
                  <MenuItem value={1}>PPT</MenuItem>
                  <MenuItem value={2}>Video</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="refresh-button-container">
            {enableRefresh ? (
              <button className="refresh-button" onClick={refreshChapter}>
                <RefreshIcon />
                <p>Refresh</p>
              </button>
            ) : (
              <button
                className="refresh-button"
                disabled
                style={{ backgroundColor: "gray", cursor: "not-allowed" }}
              >
                <RefreshIcon />
                <p>Please Wait...</p>
              </button>
            )}
          </div>
        </div>

        <div className="video-ppt-list-body">
          {HomeCtx.homeState.catalogue_id && HomeCtx.homeState.course_id ? (
            <ChapterListAccordion
              chapters={HomeCtx.homeState.chapters}
              refreshChapterLoader={refreshChapterLoader}
            />
          ) : (
            "Please select catalogue and course"
          )}
        </div>
      </div>
    </>
  );
};
