import React from 'react'
import MyPPTLabel from '../TestCompo/MyPPTLabel'
import VideoSearchBar from '../TestCompo/MyVideoSearch'
import NewFolder from '../TestCompo/NewFolder'
import CustomFolder from '../TestCompo/CustomFolder/CustomFolder'
import FolderList from '../TestCompo/FolderList/FolderList'
import Snackbar from '../Modals/Snackbar/Snackbar'
import { VideoPage } from './VideoPage/VideoPage'
import { AudioPlayer } from '../TestCompo/AudioPlayer/AudioPlayer'
import MyVideos from '../TestCompo/MyVideos/MyVideos'

const Testing = () => {
  return (
    <>
    {/* <MyPPTLabel />
    <VideoSearchBar />
    <NewFolder />
    <CustomFolder />
    <FolderList /> */}
    {/* <ListCard /> */}
    <VideoPage />
    {/* <AudioPlayer /> */}

    <FolderList />
    <MyVideos />
    </>
  )
}

export default Testing