import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import axiosInstance from '../Axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "5px"
};

const defaultTheme = createTheme();

export default function AddChapterFab({courseId, setChapterReload}) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [resMessage, setResMessage] = React.useState("");
    const [showResMessage, setShowResMessage] = React.useState(false);
    const [fabChapterData, setFabChapterData] = React.useState([]);
    const [chapter, setChapter] = React.useState({
        chapter_name: ""
    });

    const chapterNameHandler = (event) => {
        setShowResMessage(false);
        setChapter((prevState)=>{
            return {
                ...prevState,
                chapter_name: event.target.value
            }
        })
    }

    const getChapterData = async () => {
        
        try {
            const res = await axiosInstance.get(`/api/chapter/get_all_chapter?course_id=${courseId}`);
            setFabChapterData(res.data.data);
        } catch (err) {
            console.log(err.message);
        }
    
    }
    
    React.useEffect(()=>{
        getChapterData();
    },[courseId])

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (chapter.chapter_name === "" || chapter.chapter_name.trim() === "") {
        setResMessage("Please enter chapter name!");
        setShowResMessage(true);
        return;
    }
    await setChapterData(chapter);
    setChapterReload(true);
    setChapter({
        chapter_name: ""
    });

    setTimeout(()=>{
        setShowResMessage(false);
        handleClose();
    }, 2000);

  };

  const setChapterData = async (chapter) => {

    try {
        let data = new FormData();
        data.append("course_id", courseId);
        data.append("chapter_name", chapter.chapter_name);
        const res = await axiosInstance.post("/api/chapter/create_chapter", data, {headers: {'Content-Type': 'multipart/form-data'}});
        setResMessage("Chapter Added Successfully!");
        setShowResMessage(true);
        setFabChapterData([...fabChapterData, res.data.data])
    } catch (err) {
        setResMessage(err.response.data.message);
        setShowResMessage(true);
        console.error(err.message);
    }

  }

  return (
    <div>
        <button 
            onClick={handleOpen}
            style={{
                display: "flex",
                padding: "13px 28px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                border: "none",
                borderRadius: "5px",
                color: "white",
                background: "var(--primary-01, #1167E5)",
            }}
        >
            Add Chapter
        </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LibraryBooksOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Add Chapter
                        </Typography>
                        {
                            showResMessage ? (
                                resMessage === "Chapter Added Successfully!" ? (
                                    <Typography component="span" variant="body2" sx={{color: "green", display: showResMessage ? "block" : "none"}}>
                                        {resMessage}
                                    </Typography>
                                ) : (
                                    <Typography component="span" variant="body2" sx={{color: "red", display: showResMessage ? "block" : "none"}}>
                                        {resMessage}
                                    </Typography>
                                )
                            ) : (
                                null
                            )
                        }
                        
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="chapter_name"
                                label="Chapter Name"
                                type="text"
                                id="chapter_name"
                                autoComplete="text"
                                value={chapter.chapter_name}
                                onChange={chapterNameHandler}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                            Add Chapter
                            </Button>
                        </Box>
                    </Box>
                </Container>
        </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
}
