import React, {useState} from "react";
import PPTLogContext from "./PPTLogContext";

export const PPTLogState = (props) => {

    const [pptLogState, setPPTLogState] = useState(null);

    return(
        <PPTLogContext.Provider value={{pptLogState, setPPTLogState}}>
            {props.children}
        </PPTLogContext.Provider>
    )

}