import React from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import axiosInstance from '../../Axios';
import "./PPT.css"
import axios from 'axios';

const PPT = () => {



    const [pptSet, setpptSet] = useState({ "new": [], "active": [], "transcription_complete": [], "complete": [] });

    const [newPPT, setnewPPT] = useState([])
    const [activePPT, setactivePPT] = useState([])
    const [transcription_completePPT, settranscription_completePPT] = useState([])
    const [completePPT, setcompletePPT] = useState([])


    const [showNewPPT, setshowNewPPT] = useState(true)
    const [showActivePPT, setshowActivePPT] = useState(true)
    const [showTransciptionCompletePPT, setshowTransciptionCompletePPT] = useState(true)
    const [showCompletePPT, setshowCompletePPT] = useState(true)
    const [usernameloadingComplete, setusernameloadingComplete] = useState(false);



    useEffect(() => {
        axiosInstance.get('/api/ppt/get_details').then((res) => {
            setpptSet(res.data.data);
        }).catch((err) => {
            console.log(err)
        })

    }, [])

    useEffect(() => {

        setnewPPT(pptSet.new);
        setactivePPT(pptSet.active);
        settranscription_completePPT(pptSet.transcription_complete);
        setcompletePPT(pptSet.complete);

    }, [pptSet])












    const [selectedUsers, setselectedUsers] = useState([])
    const [gotUsers, setgotUsers] = useState([]);
    const [usernameToSearch, setusernameToSearch] = useState(gotUsers)
    const [usernameQuery, setusernameQuery] = useState("")

    const isUserSelected = (id) => {
        if (selectedUsers.includes(id)) {
            return true;
        }
        return false;
    }


    const searchUsername = (query) => {
        setusernameQuery(query);
        setusernameToSearch(gotUsers.filter((user) => user.username.toLowerCase().includes(query)));
    }


    const handleUserNameBoxDisplay = (e) => {
        let userPanel = document.getElementById('admin-users-main-panel');
        if (userPanel) {
            let userStyle = window.getComputedStyle(userPanel);
            if (userStyle) {

                if (userStyle.display == 'flex') {
                    userPanel.style.display = 'none';
                }
                else if (userStyle.display == 'none') {
                    userPanel.style.display = 'flex';



                    setselectedUsers([])
                }
            }
        }



    }



    const [pptTobeTransferred, setpptTobeTransferred] = useState(0);

    const assignUsers = (e, pptId) => {
        e.preventDefault();
        setusernameloadingComplete(false);
        setpptTobeTransferred(pptId);
        handleUserNameBoxDisplay(e);
        axiosInstance.get('/api/ppt/get_users_not_assigned_to_ppt?ppt_id=' + pptId).then((res) => {
            setgotUsers(res.data.data);
            setusernameToSearch(res.data.data);
            setusernameloadingComplete(true);

        }).catch((err) => console.log(err));

    }





    const assignSelectedUsersToPPt = (e) => {
        e.preventDefault();

        let pptUploadData = {
            ppt_id : pptTobeTransferred,
            user_ids : selectedUsers
        }

        axiosInstance.post('/api/admin/assign_users_to_ppt', pptUploadData).then((res)=>{

            console.log(res.data.message);
            
        }).catch((err)=> console.log(err));

    setselectedUsers([])        ;
    setpptTobeTransferred(0);

    }

    return (
        <>
            <div className="admin-PPTTable">
                <h2 style={{ textAlign: "center" }} >Assign PPT To Users</h2>
                <table>
                    <thead>

                        <tr className='admin-video'>
                            <td className="admin-videoname" style={{ fontSize: '20px', fontWeight: '700' }} >
                                PPT Name</td>
                            <td style={{ fontSize: '20px', fontWeight: '700' }}>PPT Cover</td>
                            <td style={{ fontSize: '20px', fontWeight: '700' }}>PPT Status</td>
                            <button className="assignUser" >Action</button>

                        </tr>
                    </thead>
                    <tbody>


                        {

                            showNewPPT ? <>
                                {newPPT.map((ppt) => {
                                    return <tr className='admin-video' key={uuid()}>
                                        <td className="admin-videoname">
                                            <a href={ppt.ppt_uri} target='_blank' >{ppt.ppt_name} </a> </td>
                                        {
                                            ppt.thumbnail_uri != null ? <>
                                                <td>

                                                    <img src={ppt.thumbnail_uri} width="100px" height="100px" alt="PPt Cover" />
                                                </td>
                                            </> :
                                                <td>N/A</td>
                                        }
                                        <td>New PPT</td>
                                        <button className="assignUser" onClick={(e) => { assignUsers(e, ppt.ppt_id) }} >Assign User</button>

                                    </tr>
                                })
                                }

                            </> : <>
                            </>

                        }

                        {

                            showActivePPT ? <>
                                {activePPT.map((ppt) => {
                                    return <tr className='admin-video' key={uuid()}>
                                        <td className="admin-videoname">
                                            <a href={ppt.ppt_uri} target='_blank' >{ppt.ppt_name} </a> </td>
                                        {
                                            ppt.thumbnail_uri != null ? <>
                                                <td>

                                                    <img src={ppt.thumbnail_uri} width="100px" height="100px" alt="PPt Cover" />
                                                </td>
                                            </> :
                                                <td>N/A</td>
                                        }
                                        <td>Active PPT</td>
                                        <button className="assignUser" onClick={(e) => { assignUsers(e, ppt.ppt_id) }} >Assign User</button>

                                    </tr>
                                })
                                }

                            </> : <>
                            </>

                        }

                        {

                            showTransciptionCompletePPT ? <>
                                {transcription_completePPT.map((ppt) => {
                                    return <tr className='admin-video' key={uuid()}>
                                        <td className="admin-videoname">
                                            <a href={ppt.ppt_uri} target='_blank' >{ppt.ppt_name} </a> </td>
                                        {
                                            ppt.thumbnail_uri != null ? <>
                                                <td>

                                                    <img src={ppt.thumbnail_uri} width="100px" height="100px" alt="PPt Cover" />
                                                </td>
                                            </> :
                                                <td>N/A</td>
                                        }
                                        <td>Transcription Complete PPT</td>
                                        <button className="assignUser" onClick={(e) => { assignUsers(e, ppt.ppt_id) }} >Assign User</button>

                                    </tr>
                                })
                                }

                            </> : <>
                            </>

                        }

                        {

                            showCompletePPT ? <>
                                {completePPT.map((ppt) => {
                                    return <tr className='admin-video' key={uuid()}>
                                        <td className="admin-videoname">
                                            <a href={ppt.ppt_uri} target='_blank' >{ppt.ppt_name} </a> </td>
                                        {
                                            ppt.thumbnail_uri != null ? <>
                                                <td>

                                                    <img src={ppt.thumbnail_uri} width="100px" height="100px" alt="PPt Cover" />
                                                </td>
                                            </> :
                                                <td>N/A</td>
                                        }
                                        <td>Complete PPT</td>
                                        <button className="assignUser" onClick={(e) => { assignUsers(e, ppt.ppt_id) }} >Assign User</button>

                                    </tr>
                                })
                                }

                            </> : <>
                            </>

                        }



                    </tbody>

                </table>


                {
                    <div className="admin-users" id='admin-users-main-panel'>
                        <p className="admin-users-heading" onClick={(e) => { handleUserNameBoxDisplay(e) }} >CLOSE</p>
                        No of selected users: {selectedUsers.length}
                        <div className="admin-users-userlist">
                            <input type="text" placeholder='search username' className="searchUsername" value={usernameQuery} onChange={(e) => searchUsername(e.target.value)} />
                            {
                                usernameloadingComplete && usernameToSearch.map((user) => {
                                    return <>
                                        {
                                            isUserSelected(user.user_id) ? <>
                                                <p className='admin-users-username  selected-true' onClick={(e) => { setselectedUsers((userIds) => selectedUsers.filter((userIds) => userIds != user.user_id)); }}  > {user.username} </p>
                                            </> : <>
                                                <p className='admin-users-username' onClick={(e) => { setselectedUsers((userIds) => [...userIds, user.user_id]); console.log(selectedUsers) }}  > {user.username} </p>

                                            </>

                                        }
                                    </>
                                })
                            }
                        </div>
                        <button className="assignUser" onClick={(e) => { assignSelectedUsersToPPt(e); handleUserNameBoxDisplay(e) }} >Assign</button>
                    </div>
                }

            </div>
        </>
    )
}

export default PPT