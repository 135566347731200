import React, { useEffect, useState } from 'react'
import MainHomeLeftPanel from '../MainHomeLeftPanel/MainHomeLeftPanel'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axiosInstance from '../Axios'
import RestoreIcon from '@mui/icons-material/Restore';
import './TrashBin.css'
import { RestorePPT } from './RestorePPT/RestorePPT';
import { RestoreVideo } from './RestoreVideo/RestoreVideo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TrashBin = () => {

  const [deletedPPTs, setDeletedPPTs] = useState([]);
  const [deletedVideos, setDeletedVideos] = useState([]);
  const [deletedReload, setDeletedReload] = useState(false);

  const getDeletedData = async () => {

    // api call

    try {
      
      const res = await axiosInstance.get(`/api/admin/get_all_deleted_items`);
      setDeletedPPTs(res.data.data["ppt"]);
      setDeletedVideos(res.data.data["video"]);

    } catch (err) {
      console.log(err.message);
    }

  }

  useEffect(()=>{
    getDeletedData();

    if (deletedReload) {
      setDeletedReload(false);
    }

  },[deletedReload])

  return (
    <div className="trashbin-container">

        <div className='trashbin-left-panel'>
            <MainHomeLeftPanel />
        </div>

        <div className='trashbin-right-panel'>

            <div className='trashbin-right-panel-header'>

              <Typography variant="h5" gutterBottom component="div" style={{marginLeft: "20px", marginTop: "20px"}}>
                  Trash Bin
              </Typography>

            </div>

            <div className='trashbin-right-panel-body'>

              <TableContainer sx={{width:"max-content", minWidth: 770}}>
                {/* min width: 770 - removed */}
                <Table aria-label="customized table"> 
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" sx={{width: "15vw"}}>Type</StyledTableCell>
                      {/* <StyledTableCell align="left" sx={{width: "15vw"}}>Item ID</StyledTableCell> */}
                      <StyledTableCell align="right" sx={{width: "15vw", padding: "0px"}}>Item Name</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* PPTs */}

                    {deletedPPTs.map((row,index) => (
                      <StyledTableRow key={index} className='styled-table-row'>
                      <StyledTableCell component="th" scope="row">
                          PPT
                        </StyledTableCell>
                        {/* <StyledTableCell component="th" scope="row">
                          {row["item_id"]}
                        </StyledTableCell> */}
                        <StyledTableCell align="right" sx={{padding: "0px"}}>{row["item_name"]}</StyledTableCell>
                        <StyledTableCell align="center" sx={{padding: "0px"}}>
                          <div className='trashbin-restore-container' >
                            <RestorePPT ppt_id={row["item_id"]} setDeletedReload={setDeletedReload}/>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}

                    {/* Videos */}

                    {deletedVideos.map((row,index) => (
                      <StyledTableRow key={index} className='styled-table-row'>
                      <StyledTableCell component="th" scope="row">
                          Video
                        </StyledTableCell>
                        {/* <StyledTableCell component="th" scope="row">
                          {row["item_id"]}
                        </StyledTableCell> */}
                        <StyledTableCell align="right" sx={{padding: "0px"}}>{row["item_name"]}</StyledTableCell>
                        <StyledTableCell align="center" sx={{padding: "0px"}}>
                          <div className='trashbin-restore-container' >
                            <RestoreVideo video_id={row["item_id"]} setDeletedReload={setDeletedReload}/>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>

            </div>

        </div>

    </div>
  )
}
