import React, { useCallback, useContext } from 'react';
import { useState, useEffect} from 'react';
import axiosInstance from '../Axios';
import backupIcon from '../../Media/backup.svg'
import formDropDownArrow from '../../Media/arrow_drop_down.svg'
import LoadingButton from '../LoadingButton/LoadingButton';
import CancelIcon from '../../Media/CancelCross.svg'
import {v4 as uuid} from 'uuid'
import "./DragNDrop.css"
import OutsideClickHandler from 'react-outside-click-handler';
import MuiAlert from '@mui/material/Alert';
import PPTStatusContext from '../../Context/PPTStatusContext/PPTStatusContext';

const MAX_FILE_SIZE_MB = 200;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const acceptedTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];


const DragNDrop = ({chapter_id, handleCloseModal}) => {

  const PPTStatusCtx = useContext(PPTStatusContext);
  
  const [ppt_id, setPpt_id] = useState(null);
  
  const [name, setname] = useState("")
  const [tts, setTTS] = useState("");
  const [speaker, setSpeaker] = useState('');
  const [language, setLanguage] = useState('');
  const [reference, setReference] = useState('');
  const [comment, setComment] = useState('');
  const [languageAsKey, setLanguageAsKey] = useState([]);
  const [allEngines, setAllEngines] = useState([]);

  const [selectedTTS, setselectedTTS] = useState("");
  
  const [pdfToUpload, setpdfToUpload] = useState([])
  const [pptToUpload, setpptToUpload] = useState([])

  // displayMenu 
  const [showTTS, setshowTTS] = useState(false)
  const [showSpeaker, setshowSpeaker] = useState(false)
  const [showLang, setshowLang] = useState(false)


  // Function To Handle Display of Menu

  const handleMenuDisplay = useCallback((val) => {
    let menuId = `home-right-panel-form-data-input-menu-${val}`;
    let menu = document.getElementById(menuId);

    if (menu !== undefined) {
      let showFun ;
      if (val === 'TTS'){
        showFun = showTTS;
      } 
      else if(val === 'Speaker'){
        showFun = showSpeaker;
      }
      else if(val === 'Lang'){
        showFun = showLang
      }

        if (!showFun) {
          menu.style.display = "none";
        }
        else {
          menu.style.display = "block";
        }


    }
  }, [showTTS, showLang, showSpeaker]);


  useEffect(() => {
    handleMenuDisplay('TTS');
  }, [showTTS])

  useEffect(() => {
    handleMenuDisplay('Speaker');
  }, [showSpeaker])

  useEffect(() => {
    handleMenuDisplay('Lang');
  }, [showLang])



  // Drag and Drop Code 
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [dragNdropInputError, setdragNdropInputError] = useState("")
  const [dragNdropErrorColor, setdragNdropErrorColor] = useState("red");
  const [pptCount, setpptCount] = useState(0)
  const [pdfCount, setpdfCount] = useState(0)
  const [uploadingFile, setuploadingFile] = useState(false)
  const [sendingDataToBackend, setsendingDataToBackend] = useState(false)

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };



const trackUploadedFileType = (fileType) => {
  if(fileType === 'application/pdf'){
    setpdfCount((pdfCount)=> pdfCount + 1);
  }
  else if(fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
    setpptCount((pptCount) => pptCount + 1);
  }

}

const IsFileAllowed  = (fileType) => {
  if(fileType === 'application/pdf'){
    if(pdfCount >0){
      return false ;
    }
  }
  else if(fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
    if(pptCount>0){
      return false;
    }
  }
  return true;
}



const uploadingSelectedFile = (receivedfile) => {

  if(receivedfile.size > MAX_FILE_SIZE_BYTES){
    setdragNdropInputError(`File size exceeds ${MAX_FILE_SIZE_MB}MB limit.`);
    setdragNdropErrorColor("red");
    return;
  }

  if(acceptedTypes.includes(receivedfile.type)){
    if(IsFileAllowed(receivedfile.type)){
      files.push(receivedfile)
      if(receivedfile.type === 'application/pdf'){
          pdfToUpload.push(receivedfile)
        }
        else{
          pptToUpload.push(receivedfile)

        }
      trackUploadedFileType(receivedfile.type);
    }
    else{
      if(receivedfile.type === 'application/pdf'){
        setdragNdropInputError("Only one pdf file is allowed");
        setdragNdropErrorColor("red");
        
      }
      else{
        setdragNdropInputError("Only one ppt file is allowed");
        setdragNdropErrorColor("red");
      }
    }

  }
  else{
    setdragNdropInputError("Invalid format: only pdf, ppt and pptx formats are allowed")
    setdragNdropErrorColor("red");
  }
  
}




const handleFileChange = (e) => {
  // e.preventDefault();
  // This will handle change event or click event whereas handleDrop function will handle the event of dragging the file. 
  setdragNdropInputError("");
  setdragNdropErrorColor("red");
  const receivedfile = e.target.files[0];

  if(receivedfile){
    uploadingSelectedFile(receivedfile);    
  }
  else{
    return;
  }
  e.target.value = ""; //This line resets the input field, so that same file can be uploaded again  
}

  const handleDrop = (e) => {
    e.preventDefault();
    setdragNdropInputError("")
    setdragNdropErrorColor("red");
    setDragging(false);
    // const droppedFiles = e.target.files[0]; //This is used in change event (event in which we click on the file rather than dragging it) , hence don't use it in drag and drop. 
    const droppedFiles = e.dataTransfer.files[0];
    
    if(droppedFiles){
      uploadingSelectedFile(droppedFiles);
     
    }
  };


  const removeFilefromFiles = (inputFile) => {
    let filteredfiles = files.filter((file)=> file.name !== inputFile.name);
    setFiles(filteredfiles);
    if(inputFile.type === 'application/pdf'){
      setpdfCount((pdfCount)=> pdfCount - 1);
    }
    else{
      setpptCount((pptCount) => pptCount - 1);
    }
  }



 



  








  const handlePPTUploadFormSubmit = (e) => {
    e.preventDefault();

    if(sendingDataToBackend) return;
    
    
    if(language === "" || speaker === "" || tts === "" ){
        setdragNdropInputError("Please choose menu options");
        setdragNdropErrorColor("red");
        return;
      }
      if(files.length <2){
        setdragNdropInputError("One PPT and One PDF is mandatory to upload");
        setdragNdropErrorColor("red");
        return;
      }
      if(name === "" || name.trim() === ""){
        setdragNdropInputError("Please enter ppt name");
        setdragNdropErrorColor("red");
        return;
      }

      if(reference === "" || reference.trim() === ""){
        setdragNdropInputError("Please enter ppt reference");
        setdragNdropErrorColor("red");
        return;
      }

      if(comment === "" || comment.trim() === ""){
        setdragNdropInputError("Please enter ppt comment");
        setdragNdropErrorColor("red");
        return;
      }
      

      setsendingDataToBackend(true)
      document.body.classList.add("disable-cursor");
      
  }

  // Since pdfToUpload and pptToUpload states can be null , we will use a useEffect hook to send api
  useEffect(() => {

    

    if(sendingDataToBackend && pdfToUpload.length == 1 && pptToUpload.length == 1){
      try{
        setuploadingFile(true);
        let uploadedFilesData = new FormData();
        uploadedFilesData.append('ppt_name', name);        
        uploadedFilesData.append('ppt_lang', languageAsKey[language]);        
        uploadedFilesData.append('speaker', speaker);        
        uploadedFilesData.append('ppt_file', pptToUpload[0]);        
        uploadedFilesData.append('pdf_file', pdfToUpload[0]);
        uploadedFilesData.append('engine', tts)
        uploadedFilesData.append('chapter_id', chapter_id)  
        uploadedFilesData.append('reference', reference)
        uploadedFilesData.append('comment', comment)    
        
        
        axiosInstance.post("/api/ppt/ppt_pdf_receiver", uploadedFilesData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res)=> {
          
          // setting ppt_id from response
          setPpt_id(res.data.ppt_id);
          PPTStatusCtx.setPPTStatusState((prevState)=> {
            return {
              ...prevState,
              ppt_id: res.data.ppt_id
            }
          })

          setdragNdropInputError(res.data.message);
          if(res.data.status) setdragNdropErrorColor("green");
          else setdragNdropErrorColor("red");
          setsendingDataToBackend(false);

          setTimeout(()=> {
            handleCloseModal();
          }, 3000);
        
      }).catch((err)=>{
        setdragNdropInputError(err.response.message);
        setdragNdropErrorColor("red");
        setsendingDataToBackend(false);
      })
      
      
    }catch(err){
      console.log(err);
      setsendingDataToBackend(false);
    }finally{
      setuploadingFile(false);
    }

  } else {
    document.body.classList.remove("disable-cursor");
  }

  }, [sendingDataToBackend, pptToUpload, pdfToUpload])


  // get all languages api call

  const getLanguageAsKey = async () => {

    try {
      const res = await axiosInstance.get("/api/language/get_language_data_with_language_as_key");
      setLanguageAsKey(res.data.data);
    } catch (err) {
      console.error(err.message);
    }

  }

  // get all engines api call

  const getAllEngines = async () => {

    try {
      const res = await axiosInstance.get("/api/miscellaneous/get_all_engines");
      setAllEngines(res.data.engines);

    } catch (err) {
      console.log(err.message);
    }

  }

  useEffect(()=>{
    getLanguageAsKey();
    getAllEngines();
  }, [])

  useEffect(()=> {
    if(tts !== ""){
      setSpeaker("");
      setLanguage("");
    }
  }, [tts])

  useEffect(()=> {
    if(language !== ""){
      setSpeaker("");
    }
  }, [language])


  return (
    <>
      <div className='home-right-dragNdrop-main-container'>

        <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        style={{
          width: '450px',
          height: '300px',
          border: `0.45px dashed ${dragging ? '#007bff' : '#ccc'}`,
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '18px',
          color: '#666',
          cursor: 'pointer',
          flexDirection: 'column',
          borderRadius: "2px",
          position: "relative",
        }}
      >
        <input
          onChange = {(e)=> handleFileChange(e)}
          type="file"
          style={{ display: 'block' }}
          onDrop={(e)=> handleDrop(e)} // Handle file selection from input
          multiple
          accept={acceptedTypes.join(',')}
          id='home-right-dragNdrop-main-input'
        />
        {/* Rest of the content */}
          <div className='home-right-dragNdrop-content' >
            <img src={backupIcon} alt='back up Icon' />
            <p id='dragNdrop-ppt-conversion' >PPT Conversion</p>
            <p id='dragNdrop-max-file-size'>Max File Size 200MB</p>
            {
              (files.length === 0 || files.length === 1) ? (
                <>
                  {files.map((file) => (
                    <div className='uploaded-file-container' key={uuid()}>
                      <p className='uploaded-file-name'> {file.name} </p>
                      <img
                        src={CancelIcon}
                        className='upload-file-cancel-icon'
                        alt='Cancel File'
                        onClick={(e) => removeFilefromFiles(file)}
                      />
                    </div>
                  ))}
                  <p id='dragNdrop-drag-drop-ppt' >Drag & drop the ppt and pdf files</p>
                  <p id='dragNdrop-drag-drop-or' > or</p>
                  <button id='dragNdrop-button' type='button'>Browse</button>
                </>
              ) : (
                // Render the files when files.length > 1
                <>
                  {files.map((file) => (
                    <div className='uploaded-file-container' key={uuid()}>
                      <p className='uploaded-file-name'> {file.name} </p>
                      <img
                        src={CancelIcon}
                        className='upload-file-cancel-icon'
                        alt='Cancel File'
                        onClick={(e) => removeFilefromFiles(file)}
                      />
                    </div>
                  ))}
                </>
              )
            }

            
          </div>

      </div>
        <span id="home-right-dragNdrop-form-error" style={{color: dragNdropErrorColor}} >{dragNdropInputError} </span>

        <form action="" className="home-right-form-data" onSubmit={handlePPTUploadFormSubmit}>

          <label htmlFor="name" className='home-right-panel-form-data-labels' >Name</label>
          <input type="text" className='home-right-panel-form-data-input' value={name} onChange={(e) => setname(e.target.value)} id="name" name="name" placeholder='PPT Name' />


          {/* TTS */}
          <label htmlFor="tts" className='home-right-panel-form-data-labels'>Text to Speech</label>


          <div className="home-right-panel-form-data-input-container" >

            <input type="text"
              placeholder='Select'
              value={tts}
              className="home-right-panel-form-data-input-field"
              onClick={(e) => setshowTTS(true)}
              style={{cursor:"pointer"}}
              readOnly />
            <div >
              <img src={formDropDownArrow} alt="Drop Down Arrow"
                style={{ cursor: "pointer" }}
                onClick={(e) => setshowTTS(true)} />


              <OutsideClickHandler onOutsideClick={(e)=> {setshowTTS(false)}} >

              <div className="home-right-panel-form-data-input-menu-TTS"
                id="home-right-panel-form-data-input-menu-TTS"
                >

                {
                  Object.keys(allEngines).map((engine,index)=>(
                    <p
                      key={index}
                      value={engine}
                      onClick={(e) => { setTTS(engine); setshowTTS(false); }}
                      className="home-right-panel-form-data-input-menu-options"
                    >
                      {engine}
                    </p>
                  ))
                }

              </div>
                  </OutsideClickHandler>
            </div>
          </div>



          {/* Language */}
          <label htmlFor="language" className='home-right-panel-form-data-labels'>Language</label>
          <div className="home-right-panel-form-data-input-container" >

            <input type="text"
              placeholder='Select'
              value={language}
              className="home-right-panel-form-data-input-field"
              style={{cursor: "pointer"}}
              onClick={(e) => setshowLang(true)}
              readOnly />
            <div >
              <img src={formDropDownArrow} alt="Drop Down Arrow"
                style={{ cursor: "pointer" }}
                onClick={(e) => setshowLang(true)} />


              <OutsideClickHandler onOutsideClick={(e)=> {setshowLang(false)}} >

                <div className="wrapper">

                  <div className="home-right-panel-form-data-input-menu-Lang" id="home-right-panel-form-data-input-menu-Lang">

                    {
                      allEngines[tts] && Object.keys(allEngines[tts]).map((lang, index)=>(
                        <p
                          key={index}
                          value={lang}
                          onClick={() => { setLanguage(lang); setshowLang(false); }}
                          className="home-right-panel-form-data-input-menu-options"
                        >
                          {lang}
                        </p>
                      ))
                    }

                  </div>

                </div>

              </OutsideClickHandler>


            </div>
          </div>




          {/* Speaker  */}
          <label htmlFor="speaker" className='home-right-panel-form-data-labels'>Speaker</label>
          <div className="home-right-panel-form-data-input-container" >

            <input type="text"
              placeholder='Select'
              value={speaker}
              className="home-right-panel-form-data-input-field" 
              onClick={(e) => setshowSpeaker(true)}
              style={{cursor:"pointer"}}
              readOnly />
            <div >
              <img src={formDropDownArrow} alt="Drop Down Arrow"
                style={{ cursor: "pointer" }}
                onClick={(e) => setshowSpeaker(true)} />


              <OutsideClickHandler onOutsideClick={(e)=> {setshowSpeaker(false)}} >

              <div className="home-right-panel-form-data-input-menu-Speaker"
                id="home-right-panel-form-data-input-menu-Speaker"
                style={{marginTop: "-100px"}}
                >

                {
                  allEngines[tts] && allEngines[tts][language] && Object.values(allEngines[tts][language]).map((speaker, index)=> (
                    <p
                      key={index}
                      value={speaker}
                      onClick={(e) => { setSpeaker(speaker); setshowSpeaker(false) }}
                      className="home-right-panel-form-data-input-menu-options"
                    >
                      {speaker}
                    </p>
                  ))
                }

                {/* {allEngines[tts] && allEngines[tts].map((speaker, index)=>(
                  <p 
                    key={index} 
                    onClick={(e) => { setSpeaker(speaker); setshowSpeaker(false) }}
                    className="home-right-panel-form-data-input-menu-options" 
                  >
                    {speaker}
                  </p>
                ))} */}

              </div>
                  </OutsideClickHandler>

            </div>
          </div>

          <label htmlFor="name" className='home-right-panel-form-data-labels' >Reference</label>
          <input type="text" className='home-right-panel-form-data-input' value={reference} onChange={(e) => setReference(e.target.value)} id="name" name="name" placeholder='PPT Reference link' />

          <label htmlFor="name" className='home-right-panel-form-data-labels' >Comment</label>
          <input type="text" className='home-right-panel-form-data-input' value={comment} onChange={(e) => setComment(e.target.value)} id="name" name="name" placeholder='PPT Comment' />

          
          {
            !sendingDataToBackend ?<>
            
            <button type='submit' className='home-right-form-data-button' 
            onClick={(e)=> (handlePPTUploadFormSubmit(e))}
            >Submit</button>
            </>:<>
          <div id="dragNdrop-form-loadingButton"  aria-disabled >
          <LoadingButton />
          </div>
            </>
          }



        </form>

      </div>

    </>
  );
};

export default DragNDrop;
