import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import './SignIn.css'
import "../Global.css"
// import AvetiLogo from '../../Media/AvetiLogo.png'
import axiosInstance from '../Axios'
import { useNavigate } from 'react-router-dom'
import arrow_right_alt from '../../Media/arrow_right_alt.svg'
import AvetiLogo from '../../Media/AvetiLogoBlue.svg'
import Cookies from 'js-cookie';

const SignIn = () => {

  const navigate  = useNavigate();

  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  const [loading, setloading] = useState(false)
  const [usernameInputError, setusernameInputError] = useState("")
  const [passwordInputError, setpasswordInputError] = useState("")




  const signInFormSubmission = (e) => {
    e.preventDefault();


    if(loading){
      return 
    }


    
    if(!username){
      setusernameInputError("Please enter an username")
    }
    if(!password){
      setpasswordInputError("Please enter password")
    }


    if(username && password){
      setloading(true)

      const loginData = new FormData();
      loginData.append('username', username)
      loginData.append('password', password)

    

      axiosInstance.post('/api/auth/login', loginData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res)=>{
        if(res.data.status){

          Cookies.set("Token", res.data.data.auth_token)
          Cookies.set("username", res.data.data.username)
          Cookies.set("user_type_id", res.data.data.user_type_id)
          Cookies.set("user_id", res.data.data.user_id)
          
          navigate("/home")
          
        }
        else{
          setpasswordInputError("Some problem occured. Please try again.")
        }

      }).catch((error)=> {

        console.log("This is response",  error);
        if(error.response){
          console.log("error", error)
          setpasswordInputError(error.response.data.message);

        }
        else {
          setpasswordInputError("Something is broken")
        }
        setloading(false)
      })

    }
      
    

    // Resetting states
    
    
    


  }


  useEffect(() => {
    if (username) {
      setusernameInputError('');
    }
  }, [username]);
  useEffect(() => {
    if(password){
      setpasswordInputError("");
    }
  
  }, [password])
  













  return (
    <>
    <div className="signin-main">
        <div className="signin-left-panel">


          <img id='avetilogo' src={AvetiLogo} alt='Aveti Logo' width="200px" height="auto"  />
          {/* <div className="avetiBranding">

            <img id='avetilogo' src={AvetiLogo} alt='Aveti Logo' width="200px" height="auto"  />
          
          </div> */}

        </div>
        <div className="signin-right-panel">
            <form className="signin-form" 
            onSubmit={(e)=> signInFormSubmission(e)}
            > 
                {/* heading */}
                <p id="signin-form-heading">Sign In</p>


                {/* username field */}
                <input type="text" id="signin-username"  className='signin-form-fields' placeholder='Username' 
                value={username}
                onChange={(e) => setusername(e.target.value)}
                />
                <span className="signin-form-input-error" id="signin-form-username-error"> {usernameInputError} </span>



                {/* password field */}
                <input type="password" id="signin-password" className = 'signin-form-fields' placeholder='Password'
                value={password}
                onChange={(e) => {setpassword(e.target.value)}}
                />
                <span className="signin-form-input-error" id="signin-form-username-error"> {passwordInputError} </span>


            
                  
                {
                    loading?
                    <>
                      <button className="signin-form-fields" id="signin-form-loading"> 
                        <span className="loadingdots"></span>
                        <span className="loadingdots"></span>
                        <span className="loadingdots"></span>
                      </button>
                  </>:
                  <>
                  <button id="signin-form-submit" className='signin-form-fields' >Sign In <span className="signin-form-arrow-right">
                   <img src={arrow_right_alt} alt='arrow_right_alt' /> </span> </button>
                  </>

                }




            </form>

    
        </div>
    </div>
    </>
  )
}

export default SignIn