import React from "react";
import axiosInstance from "../Axios";
import { useState } from "react";
import { useEffect } from "react";
import { PPTSlidesSidebar } from "./PPTSlidesSidebar/PPTSlidesSidebar";
import { PPTMainView } from "./PPTMainView/PPTMainView";
import { PPTSlideNoteTextEditor } from "./PPTSlideNoteTextEditor/PPTSlideNoteTextEditor";
import { PPTPreviewCommentSection } from "./PPTPreviewCommentSection/PPTPreviewCommentSection";
import { VideoSkeleton } from "../VideoSkeleton/VideoSkeleton";
import { useParams } from "react-router-dom";
import "./PPTPreviewPage.css";

export const PPTPreviewPage = () => {
  const { id } = useParams();
  const [PPTData, setPPTData] = useState({});
  const [PPTSlides, setPPTSlides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slide_id, setSlide_id] = useState(null);
  const [selectedSlide, setSelectedSlide] = useState();
  const [reviewID, setReviewID] = useState(null);
  const [update, setUpdate] = useState(false);

  const getPPTSlides = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/ppt/get_details_by_id?ppt_id=${id}`
      );
      setPPTData(res.data.data.ppt_data);
      setPPTSlides(res.data.data.slide_details);
      setReviewID(res.data.data.ppt_review_details.review_id);

      if (slide_id === null) {
        setSelectedSlide(res.data.data.slide_details[0]);
      } else {
        const slide = res.data.data.slide_details.find(
          (slide) => slide.slide_id === slide_id
        );
        setSelectedSlide(slide);
      }
    } catch (err) {
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPPTSlides();
  }, [update]);

  return (
    <div className="ppt-preview-page-container">
      {loading ? (
        <>
          <VideoSkeleton />
        </>
      ) : (
        <>
          <div className="ppt-preview-left-container">
            <PPTSlidesSidebar
              setSlide_id={setSlide_id}
              PPTData={PPTData}
              PPTSlides={PPTSlides}
              selectedSlide={selectedSlide}
              setSelectedSlide={setSelectedSlide}
              ppt_id={id}
              setUpdate={setUpdate}
            />
          </div>
          <div className="ppt-preview-right-container">
            {selectedSlide && (
              <>
                <PPTMainView
                  ppt_id={id}
                  PPTSlides={PPTSlides}
                  selectedSlide={selectedSlide}
                  setSelectedSlide={setSelectedSlide}
                />
                <PPTSlideNoteTextEditor
                  ppt_id={id}
                  setSlide_id={setSlide_id}
                  setUpdate={setUpdate}
                  PPTData={PPTData}
                  PPTSlides={PPTSlides}
                  selectedSlide={selectedSlide}
                  setSelectedSlide={setSelectedSlide}
                />
                <PPTPreviewCommentSection
                  ppt_id={id}
                  selectedSlide={selectedSlide}
                  PPTData={PPTData}
                  reviewID={reviewID}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
