import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    searchQuery :''
}

const videoSearchReducer = createReducer(initialState, ((builder)=> {
    builder.addCase("changeString" , (state, action) => {
        
        state = action.payload; 
        return state
    })
}))

export default videoSearchReducer;