import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    modalDisplayOn : false,
    message: "This is a modal"
}

const primeModalReducer = createReducer(initialState, ((builder)=> {
    builder
    .addCase("showPrimeModal", (state, action) => {
        state.modalDisplayOn = true
        state.message = action.message 
        return state
    })
    .addCase("hidePrimeModal", (state) => {
        state.modalDisplayOn = false
        state.message = "This is a modal"
        return state
    })


}))

export default primeModalReducer;