import { Box, Modal, Typography } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import "./ReactPlayer.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../Axios";
import HomeContext from "../../Context/HomeContext/HomeContext";
import { ReactPlayerSkeleton } from "../ReactPlayerSkeleton/ReactPlayerSkeleton";
import MainHomeLeftPanel from "../MainHomeLeftPanel/MainHomeLeftPanel";
import { VideoCommentSection } from "../VideoCommentSection/VideoCommentSection";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const ReactPlayerMPD = () => {
  const playerRef = useRef(null);

  const navigate = useNavigate();

  const HomeCtx = useContext(HomeContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { videoid } = useParams();
  const [chapterVideo, setChapterVideo] = useState([]);
  const [videoEntry, setVideoEntry] = useState({});

  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [duration, setDuration] = useState(0);
  const [currQuestion, setCurrQuestion] = useState();
  const [showQuestion, setShowQuestion] = useState(false);

  const chap_id =
    searchParams.get("chap_id") ||
    HomeCtx.homeState.expanded_chapter_id.slice(5);

  // feedback dialog
  const [correctDialogOpen, setCorrectDialogOpen] = useState(false);
  const [wrongDialogOpen, setWrongDialogOpen] = useState(false);

  const handleCorrectDialogOpen = () => setCorrectDialogOpen(true);
  const handleCorrectDialogClose = () => {
    setCorrectDialogOpen(false);
  };

  const handleWrongDialogOpen = () => setWrongDialogOpen(true);
  const handleWrongDialogClose = () => {
    setWrongDialogOpen(false);
  };

  const getChapterVideo = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/admin/get_all_videos_by_chapter_id?chapter_id=${chap_id}`
      );
      setChapterVideo(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getVideoEntry = () => {
    setVideoEntry(chapterVideo.filter((video) => video.video_id == videoid));
  };

  function decodeHTMLEntities(text) {
    const entities = {
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#39;": "'",
      "&#x2F;": "/",
      "&#x60;": "`",
      "&#x3D;": "=",
    };

    return text.replace(/&[a-z0-9]+;/gi, (entity) => {
      return entities[entity] || entity;
    });
  }

  const handleProgress = (state) => {
    setDuration(Math.floor(state.playedSeconds));
    const question_json = JSON.parse(videoEntry[0].question_json);
    if (question_json === null) {
      return;
    }
    const question = question_json.filter(
      (ques) => ques.time_in_seconds == Math.floor(state.playedSeconds)
    );
    if (question.length > 0) {
      setCurrQuestion(question);
      setShowQuestion(true);
      setIsPlaying(false);
    }
  };

  const handleQuestionSubmit = (event) => {
    event.preventDefault();
    const checkboxes = document.querySelectorAll(
      'input[name="answer_option"]:checked'
    );
    const values = Array.from(checkboxes).map((checkbox) => checkbox.value);

    const correctAnswersObjArray = currQuestion[0].options.filter(
      (ans) => ans.is_correct == 1
    );
    const correctAnswerIDs = correctAnswersObjArray.map((ans) => ans.answer_id);

    values.sort();
    correctAnswerIDs.sort();

    const isCorrect =
      JSON.stringify(values) === JSON.stringify(correctAnswerIDs);

    if (isCorrect) {
      handleCorrectDialogOpen();
      console.log("Correct");
    } else {
      handleWrongDialogOpen();
      console.log("incorrect");
    }

    setShowQuestion(false);
    seeking(duration + 1);
    setIsPlaying(true);
    // setIsReady(false);
  };

  const handleQuestionSkip = (event) => {
    event.preventDefault();
    setShowQuestion(false);
    // setIsReady(false);
    seeking(duration + 1);
    setIsPlaying(true);
    setCurrQuestion();
  };

  const seeking = (time) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time, "seconds");
    }
  };

  // const onReady = useCallback(() => {
  //     if(!isReady){
  //         if(playerRef.current){
  //             if(duration > 0){
  //                 playerRef.current.seekTo(duration + 1, "seconds");
  //                 setIsPlaying(true);
  //             }else{
  //                 playerRef.current.seekTo(0, "seconds");
  //             }
  //             setIsReady(true);
  //         }
  //     }
  // }, [isReady])

  const onPlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    getChapterVideo();
    getVideoEntry();
  }, []);

  useEffect(() => {
    getVideoEntry();
  }, [chapterVideo]);

  console.log("isReady", isReady);

  return (
    <div className="react-player-container">
      {videoEntry.length > 0 ? (
        <>
          <div className="react-player-left">
            <MainHomeLeftPanel />
          </div>

          <div className="react-player-right">
            <div className="react-player-header">
              <div
                onClick={() => navigate("/home")}
                style={{ cursor: "pointer" }}
                className="icon-container"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_159_481"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_159_481)">
                    <path
                      d="M9.07499 21.0734L0.749988 12.7484C0.633321 12.6318 0.549988 12.5109 0.499988 12.3859C0.449988 12.2609 0.424988 12.1318 0.424988 11.9984C0.424988 11.8651 0.449988 11.7359 0.499988 11.6109C0.549988 11.4859 0.633321 11.3651 0.749988 11.2484L9.09999 2.89844C9.34999 2.64844 9.65415 2.52344 10.0125 2.52344C10.3708 2.52344 10.675 2.65677 10.925 2.92344C11.1917 3.17344 11.3167 3.48177 11.3 3.84844C11.2833 4.2151 11.15 4.52344 10.9 4.77344L3.67499 11.9984L10.925 19.2484C11.1583 19.4818 11.275 19.7859 11.275 20.1609C11.275 20.5359 11.1583 20.8401 10.925 21.0734C10.675 21.3234 10.3667 21.4484 9.99999 21.4484C9.63332 21.4484 9.32499 21.3234 9.07499 21.0734Z"
                      fill="#212121"
                    />
                  </g>
                </svg>
              </div>

              <Typography variant="h4" sx={{ color: "black" }}>
                {videoEntry[0].name}
              </Typography>
            </div>

            <div className="react-player-body">
              {/* {
                            showQuestion ? (
                                <div>
                                    <div className="dynamic-question" dangerouslySetInnerHTML={{__html: decodeHTMLEntities(currQuestion[0]?.question_html)}} />
                                    <hr />
                                    <div className="question-button-container">
                                        <button className="skip-button" onClick={handleQuestionSkip}>Skip</button>
                                        <button  className="submit-button" onClick={handleQuestionSubmit}>Submit</button>
                                    </div>
                                </div>
                            ) : (
                                <div style={{display: "flex", gap: "20px"}}>
                                    <ReactPlayer
                                        ref={playerRef}
                                        controls={true}
                                        url={videoEntry[0].mpd_url}
                                        height="80vh"
                                        width="80vw"
                                        onProgress={handleProgress}
                                        onReady={onReady}
                                        onPlay={onPlay}
                                        playing={isPlaying}
                                    />
                                </div>
                            )
                        } */}
              <div style={{ position: "relative" }}>
                {showQuestion && currQuestion.length > 0 && (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        zIndex: 1,
                      }}
                    >
                      <div
                        className="dynamic-question"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(
                            currQuestion[0]?.question_html
                          ),
                        }}
                      />
                      <hr />
                      <div className="question-button-container">
                        <button
                          className="skip-button"
                          onClick={handleQuestionSkip}
                        >
                          Skip
                        </button>
                        <button
                          className="submit-button"
                          onClick={handleQuestionSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ display: "flex", gap: "20px" }}>
                  <ReactPlayer
                    ref={playerRef}
                    controls={true}
                    url={videoEntry[0].mpd_url}
                    height="80vh"
                    width="80vw"
                    onProgress={handleProgress}
                    // onReady={onReady}
                    onPlay={onPlay}
                    playing={isPlaying}
                  />
                </div>
              </div>
            </div>

            {showQuestion ? null : (
              <div className="video-comment-section-body">
                <VideoCommentSection
                  video_id={videoid}
                  time_stamp={duration}
                  setIsPlaying={setIsPlaying}
                />
              </div>
            )}
          </div>
          {currQuestion && (
            <>
              <Modal
                open={correctDialogOpen}
                onClose={handleCorrectDialogClose}
              >
                <Box sx={style}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currQuestion[0]?.correct_feedback,
                    }}
                  />
                </Box>
              </Modal>

              <Modal open={wrongDialogOpen} onClose={handleWrongDialogClose}>
                <Box sx={style}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currQuestion[0]?.wrong_feedback,
                    }}
                  />
                </Box>
              </Modal>
            </>
          )}
        </>
      ) : (
        <>
          <ReactPlayerSkeleton />
        </>
      )}
    </div>
  );
};
