import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    dragDropPPT : {
        name: "",
        PPTInString: ""
    },
    newPDF : {
        name : "",
        PdfInString: ""
    } 
}

const dragNdropFileReducer = createReducer(initialState, ((builder) => {
    builder 
    .addCase("setdragDropPPt", ((state, action)=>{
        state.dragDropPPT.PPTInString = action.base64String
        state.dragDropPPT.name = action.name

        return state 
    }))
    .addCase("setnewPDF", ((state, action)=>{
        state.newPDF.PdfInString = action.base64String
        state.newPDF.name = action.name
        return state 
    }))

}))

export default dragNdropFileReducer;