import React from 'react'
import "./LoadingButton.css"



const LoadingButton = () => {

    

    return (
        <button className="loading-button-container"  >
            <span className="loadingdots"></span>
            <span className="loadingdots"></span>
            <span className="loadingdots"></span>
        </button>
    )
}

export default LoadingButton