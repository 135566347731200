import React, { useEffect, useState } from "react";
import "./UploadVideoForm.css";
import axiosInstance from "../Axios";
import axios from "axios";
import { toast } from "sonner";

export const UploadVideoForm = ({ chapter_id, handleCloseModal }) => {
  const api_key = process.env.REACT_APP_API_KEY;

  // upload file states
  const [videoFile, setVideoFile] = useState(null);
  const [videoFileName, setVideoFileName] = useState("");
  const [videoLanguage, setVideoLanguage] = useState();
  const [uploadType, setUploadType] = useState("file");
  const [languages, setLanguages] = useState();

  //upload url states
  const [videoCategory, setVideoCategory] = useState();
  const [videoCourse, setVideoCourse] = useState();
  const [videoChapter, setVideoChapter] = useState();
  const [videoUrl, setVideoUrl] = useState("");
  const [videoQuestions, setVideoQuestions] = useState();
  const [uploading, setUploading] = useState(false);

  const [categories, setCategories] = useState();
  const [courses, setCourses] = useState();
  const [chapters, setChapters] = useState();
  const [videos, setVideos] = useState();

  const handleFileUploadChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const getAllLanguages = async () => {
    try {
      const res = await axiosInstance.get("/api/language/get_all_languages");
      setLanguages(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleUpload = async () => {
    if (uploadType === "file") {
      setUploading(true);
      try {
        await axiosInstance.post("/api/videos/save_video_for_review", {
          name: videoFileName,
          mp4_file: videoFile,
          language_id: videoLanguage,
          chapter_id: chapter_id,
        });
        toast.success("Video uploaded successfully");
      } catch (err) {
        alert("Error occurred while uploading file");
      } finally {
        setUploading(false);
        handleCloseModal();
      }
      console.log("Uploading file");
      setVideoFileName("");
      setVideoLanguage("");
      setVideoFile(null);
    } else {
      let question_json;

      if (videoQuestions.length === 0) {
        question_json = null;
      } else {
        question_json = JSON.stringify(videoQuestions);
      }

      try {
        const res = await axiosInstance.post(
          "/api/videos/save_video_for_review",
          {
            name: videoFileName,
            mp4_file_url: videoUrl,
            language_id: videoLanguage,
            chapter_id: chapter_id,
            question_json,
          }
        );
        alert("Video uploaded successfully");
        handleCloseModal();
      } catch (err) {
        alert("Error occurred while uploading file");
      }
      console.log("Uploading url");
      setVideoFileName("");
      setVideoCategory("");
      setVideoCourse("");
      setVideoChapter("");
      setVideoUrl("");
    }
  };

  // upload url form requirements functions

  const getCategories = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/miscellaneous/odia_shikhya/categories`
      );
      setCategories(res.data);
      getCourses(res.data[0].cat_id);
    } catch (err) {
      alert(err.message);
    }
  };

  const getCourses = async (category_id) => {
    try {
      const res = await axiosInstance.get(
        `/api/miscellaneous/odia_shikhya/courses?category_id=${category_id}`
      );
      setCourses(res.data);
      getChapters(res.data[0].crs_id);
    } catch (err) {
      alert(err.message);
    }
  };

  const getChapters = async (course_id) => {
    try {
      const res = await axiosInstance.get(
        `/api/miscellaneous/odia_shikhya/chapters?course_id=${course_id}`
      );
      setChapters(res.data);
      getVideos(res.data[0].chapter_id);
    } catch (err) {
      alert(err.message);
    }
  };

  const getVideos = async (chapter_id) => {
    try {
      const res = await axiosInstance.get(
        `/api/miscellaneous/odia_shikhya/videos?chapter_id=${chapter_id}`
      );
      setVideos(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const getVideoQuestions = async (video_id) => {
    try {
      const res = await axiosInstance.get(
        `/api/miscellaneous/odia_shikhya/videoQuestions?video_id=${video_id}`
      );
      setVideoQuestions(res.data);
      console.log("videoQuestions", res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  // onChange functions for upload url form
  const videoCategoryChangeHandler = (e) => {
    setVideoCategory(e.target.value);
    getCourses(e.target.value);
  };

  const videoCourseChangeHandler = (e) => {
    setVideoCourse(e.target.value);
    getChapters(e.target.value);
  };

  const videoChapterChangeHandler = (e) => {
    setVideoChapter(e.target.value);
    getVideos(e.target.value);
  };

  const videoUrlChangeHandler = (e, selectedVideo) => {
    setVideoUrl(e.target.value);
    getVideoQuestions(selectedVideo.id);
  };

  useEffect(() => {
    getAllLanguages();
    getCategories();
  }, []);

  return (
    <div className="upload-video-form-container">
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <button
          type="button"
          className={`upload-button ${uploadType === "file" ? "active" : ""}`}
          onClick={() => {
            setUploadType("file");
            setVideoFileName("");
            setVideoLanguage("");
          }}
        >
          Upload File
        </button>
        <button
          type="button"
          className={`upload-button ${uploadType === "url" ? "active" : ""}`}
          onClick={() => {
            setUploadType("url");
            setVideoFileName("");
            setVideoLanguage("");
          }}
        >
          Upload URL
        </button>
      </div>
      {uploadType === "file" ? (
        <form>
          <div>
            {videoFile ? (
              <video width="320" height="240" controls>
                <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
              </video>
            ) : (
              <input
                type="file"
                accept="video/mp4"
                onChange={handleFileUploadChange}
              />
            )}
          </div>
          <div>
            {videoFile && (
              <button
                type="button"
                className="remove-video-btn"
                onClick={() => setVideoFile(null)}
              >
                Remove
              </button>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter video name"
              value={videoFileName}
              onChange={(e) => setVideoFileName(e.target.value)}
            />
          </div>
          <div>
            <select
              value={videoLanguage}
              onChange={(e) => setVideoLanguage(e.target.value)}
              placeholder="Select a language"
            >
              <option value="">Select a language</option>
              {languages &&
                languages.map((language) => {
                  return (
                    <option
                      key={language.language_id}
                      value={language.language_id}
                    >
                      {language.language}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <button
              type="button"
              className="upload-video-btn"
              onClick={handleUpload}
              disabled={!videoFile || !videoFileName || uploading}
            >
              {uploading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </form>
      ) : (
        <form>
          <div>
            <input
              type="text"
              placeholder="Enter video name"
              value={videoFileName}
              onChange={(e) => setVideoFileName(e.target.value)}
            />
          </div>
          <div>
            <select
              value={videoCategory}
              onChange={videoCategoryChangeHandler}
              placeholder="Select a category"
            >
              <option value="">Select a category</option>
              {categories &&
                categories.map((cat) => {
                  return (
                    <option key={cat.cat_id} value={cat.cat_id}>
                      {cat.cat_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              value={videoCourse}
              onChange={videoCourseChangeHandler}
              placeholder="Select a course"
            >
              <option value="">Select a course</option>
              {courses &&
                courses.map((course) => {
                  return (
                    <option key={course.crs_id} value={course.crs_id}>
                      {course.crs_title}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              value={videoChapter}
              onChange={videoChapterChangeHandler}
              placeholder="Select a chapter"
            >
              <option value="">Select a chapter</option>
              {chapters &&
                chapters.map((chapter) => {
                  return (
                    <option key={chapter.chapter_id} value={chapter.chapter_id}>
                      {chapter.chapter_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              value={videoUrl}
              onChange={(e) =>
                videoUrlChangeHandler(
                  e,
                  videos.find((video) => video.url === e.target.value)
                )
              }
              placeholder="Select a video"
            >
              <option value="">Select a video</option>
              {videos &&
                videos.map((video) => {
                  return (
                    <option key={video.id} value={video.url}>
                      {video.title}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              value={videoLanguage}
              onChange={(e) => setVideoLanguage(e.target.value)}
              placeholder="Select a language"
            >
              <option value="">Select a language</option>
              {languages &&
                languages.map((language) => {
                  return (
                    <option
                      key={language.language_id}
                      value={language.language_id}
                    >
                      {language.language}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <button
              type="button"
              className="upload-video-btn"
              onClick={handleUpload}
              disabled={
                !videoFileName ||
                !videoCategory ||
                !videoChapter ||
                !videoCourse ||
                !videoUrl
              }
            >
              Upload
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
