import EditIcon from "@mui/icons-material/Edit";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import axiosInstance from "../Axios";
import { toast } from "sonner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

export const EditVideoTitle = ({ video_id, video_name, setReload }) => {
  const [openEditPPTModal, setOpenEditPPTModal] = useState(false);
  const handleOpenEditPPTModal = () => setOpenEditPPTModal(true);
  const handleCloseEditPPTModal = () => setOpenEditPPTModal(false);

  const [resMessageEditPPT, setResMessageEditPPT] = useState("");
  const [showResMessageEditPPT, setShowResMessageEditPPT] = useState(false);

  const [newTitle, setNewTitle] = useState(video_name);

  const handleEditPPTSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axiosInstance.post(
        "/api/videos/update_video_name",
        { video_id: video_id, video_name: newTitle },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(res.data.message);
      setReload(true);
      setTimeout(() => {
        handleCloseEditPPTModal();
      }, 1500);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <EditIcon onClick={handleOpenEditPPTModal} />
      <Modal
        open={openEditPPTModal}
        onClose={handleCloseEditPPTModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LibraryBooksOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Edit Video Title
                </Typography>
                {showResMessageEditPPT ? (
                  resMessageEditPPT === "Chapter Updated Successfully!" ? (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: "green",
                        display: showResMessageEditPPT ? "block" : "none",
                      }}
                    >
                      {resMessageEditPPT}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: "red",
                        display: showResMessageEditPPT ? "block" : "none",
                      }}
                    >
                      {resMessageEditPPT}
                    </Typography>
                  )
                ) : null}

                <Box
                  component="form"
                  onSubmit={handleEditPPTSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="video_name"
                    label="Video Name"
                    type="text"
                    id="video_name"
                    autoComplete="text"
                    defaultValue={video_name}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
};
